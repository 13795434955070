import { useStoreActions, useStoreState } from "easy-peasy";
import SearchBar from "../../presentational/SearchBar";
import Icon from "../../presentational/Icon";

function SubchapterSearchBar(props) {
  const { subchapterSearchInput } = useStoreState((state) => state.nonRoutine);

  const { setSubchapterSearchInputAction } = useStoreActions(
    (actions) => actions
  );

  return (
    <SearchBar
      value={subchapterSearchInput}
      onChangeText={(input) => setSubchapterSearchInputAction(input)}
      clearIcon={
        <Icon.Clear onPress={() => setSubchapterSearchInputAction("")} />
      }
      placeholder="Filter Sub-Chapters"
      {...props}
    />
  );
}

export default SubchapterSearchBar;
