import { useStoreActions, useStoreState } from "easy-peasy";
import Environment from "../../presentational/Environment";
import { setAuthToken } from "../../../utilities/api";

function EnvironmentUkList(props) {
  const { isTest } = useStoreState((state) => state.employee);

  const {
    setUserAction,
    setTenantAction,
    setLogoAction,
    setFaviconAction,
    setSpinnerAction,
  } = useStoreActions((actions) => actions);

  function setGeography(geo) {
    let token = "";
    let logo = "";
    let tenant = "sts";
    let email = "";

    if (isTest) {
      if (geo === "uk") {
        token =
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6InVrMjFSMlRlc3RAY2Zicy11cy5jb20iLCJpYXQiOjE2NDkxNjM5MjV9.atIAJffp-Ib-ch05Jj8Q82nAbsmcp81EbmXDPNDHki0";
        logo = "logoModCenterUKTest21R2.png";
        email = "uk21R2Test@cfbs-us.com";
      } else if (geo === "ukQA") {
        tenant = "STSGBP";
        token =
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6InN0c1VLUUFAY2Zicy11cy5jb20iLCJpYXQiOjE2NDkxNjM4NDV9.Ms2RaAcqrFOh6BvwAnEm7s3GvVhNgNx_X16Um1rQMro";
        logo = "logoModCenterUKQA.png";
        email = "stsUKQA@cfbs-us.com";
      } else if (geo === "ukSB") {
        tenant = "STSGBP";
        token =
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6InN0c1VLU2FuZGJveEBjZmJzLXVzLmNvbSIsImlhdCI6MTY0OTE2Mzg0NX0.SIsSWR8cgxDnm54nrAbFeef1YiRnvVvj_cbT7Zg3WkU";
        logo = "logoModCenterUKSandbox.png";
        email = "stsUKSandbox@cfbs-us.com";
      } else if (geo === "ukAL") {
        tenant = "STSGBP";
        token =
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6InN0c1VLQWxwaGFAY2Zicy11cy5jb20ifQ.VE5gENE1c3FZ6RjSa3H3vEKe7la_wV55sDT68Kk-Nww";
        logo = "logoModCenterUKAlpha.png";
        email = "stsUKQA@cfbs-us.com";
      }
    } else {
      token =
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6InN0c1VLUHJvZHVjdGlvbkBjZmJzLXVzLmNvbSIsImlhdCI6MTYzMDI4NTMxMH0.3yksEu2N3JNvj4tCRP8ac4h_zTscMNo898Rw21saql8";
      logo = "logoModCenterUK.png";
      email = "stsUKProduction@cfbs-us.com";
    }

    setUserAction({ email });
    setTenantAction(tenant);
    setLogoAction(logo);
    setAuthToken(token);
    setFaviconAction("favicon_sts.ico");
    setSpinnerAction("rotate_sts.gif");
  }

  return (
    <Environment.List
      list={(isTest
        ? [
            {
              environment: "TEST",
              geographyLabel: "UK - STS Aviation Services",
              geographyValue: "uk",
            },
            {
              environment: "QA",
              geographyLabel: "UK - STS Aviation Services",
              geographyValue: "ukQA",
            },
            {
              environment: "SANDBOX",
              geographyLabel: "UK - STS Aviation Services",
              geographyValue: "ukSB",
            },
            {
              environment: "ALPHA",
              geographyLabel: "UK - STS Aviation Services",
              geographyValue: "ukAL",
            },
          ]
        : [
            {
              environment: "",
              geographyLabel: "UK - STS Aviation Services",
              geographyValue: "",
            },
          ]
      ).map(({ geographyLabel, geographyValue, ...environment }) => ({
        geography: geographyLabel,
        onPress: () => setGeography(geographyValue),
        ...environment,
      }))}
      {...props}
    />
  );
}

export default EnvironmentUkList;
