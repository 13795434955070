import { Center, Input } from "@cfbs/cfbsstrap-native";
import Gap from "../../presentational/Gap";
import Button from "../../presentational/Button";
import {
  useSearchTasksServerSideForm,
  useSearchTasksServerSideModal,
} from "../../../hooks/task";
import Modal from "./Modal";

function SearchTasksServerSideModal(props) {
  const modalProps = useSearchTasksServerSideModal();
  const formProps = useSearchTasksServerSideForm();

  return (
    <Modal {...modalProps} {...props}>
      <Modal.Header onRequestClose={modalProps.onRequestClose}>
        Search Project Tasks
      </Modal.Header>

      <Modal.Body>
        <>
          <Input {...formProps} />

          <Gap />

          <Center>
            <Button onPress={formProps.onSubmitEditing}>Search</Button>
          </Center>
        </>
      </Modal.Body>
    </Modal>
  );
}

export default SearchTasksServerSideModal;
