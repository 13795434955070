import { useStoreActions, useStoreState } from "easy-peasy";
import SearchBar from "../../presentational/SearchBar";
import Icon from "../../presentational/Icon";

function StsZoneSearchBar(props) {
  const { stsZoneSearchInput } = useStoreState((state) => state.nonRoutine);

  const { setStsZoneSearchInputAction } = useStoreActions((actions) => actions);

  return (
    <SearchBar
      value={stsZoneSearchInput}
      onChangeText={(input) => setStsZoneSearchInputAction(input)}
      clearIcon={<Icon.Clear onPress={() => setStsZoneSearchInputAction("")} />}
      placeholder="Filter STS Zones"
      {...props}
    />
  );
}

export default StsZoneSearchBar;
