import NotFoundText from "./NotFoundText";
import Text from "./Text";
import EmphasizedText from "./TextEmphasized";
import TextError from "./TextError";
import TextPrimary from "./TextPrimary";
import TextSuccess from "./TextSuccess";
import TextTaskStatus from "./TextTaskStatus";
import TextWarning from "./TextWarning";

Text.Success = TextSuccess;
Text.Error = TextError;
Text.Warning = TextWarning;
Text.Primary = TextPrimary;
Text.TaskStatus = TextTaskStatus;
Text.NotFound = NotFoundText;
Text.Emphasized = EmphasizedText;

export default Text;
