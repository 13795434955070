import { useStoreActions, useStoreState } from "easy-peasy";
import { caseInsensitiveFilter } from "../utilities/string";
import { checkQualifications } from "../utilities/task";
import { useState } from "react";

function useCheckTaskQualification() {
  const { scannedEmployee, qualifications } = useStoreState(
    (state) => state.employee
  );

  return (pt) => {
    const statusToCheckTypes = ["Inspection", "Supervisor"];

    if (!scannedEmployee.BypassQuals) {
      pt.qualified = true;

      if ((scannedEmployee.qualificationChecks || []).includes("customer")) {
        pt = checkQualifications(
          pt,
          qualifications[t.Customer],
          "customer",
          []
        );
      }

      if (
        pt.qualified &&
        (scannedEmployee.qualificationChecks || []).includes("personal")
      ) {
        pt = checkQualifications(
          pt,
          qualifications["personal"],
          "personal",
          []
        );
      }

      if (
        pt.qualified &&
        (scannedEmployee.qualificationChecks || []).includes("type") &&
        statusToCheckTypes.includes(pt.TaskStatus)
      ) {
        pt.qualified = false;
        pt = checkQualifications(
          pt,
          qualifications["type"],
          "type",
          qualifications.inspectorQualified
        );
      }
    } else {
      pt.qualified = true;
    }

    return pt;
  };
}

function useProjectTaskList() {
  const {
    project: { projectTaskList },
    task: { list, searchInput, taskLimitReached },
    employee: { scannedEmployee },
  } = useStoreState((state) => state);

  const { setChosenProjectTaskAction, setShowEquipmentDetailsModalAction } =
    useStoreActions((actions) => actions);

  const checkTaskQualification = useCheckTaskQualification();

  return {
    list: projectTaskList
      .filter((task) =>
        caseInsensitiveFilter(
          [
            task.TaskCD,
            task.ProjectCD,
            task.ProjectDescription,
            task.ProjectTaskDescription,
          ],
          searchInput
        )
      )
      .map((pt) => ({
        ...checkTaskQualification(pt),
        isCheckedOut: list.find(
          (et) => et.ProjectTaskID.value.trim() === pt.TaskCD.trim()
        ),
        taskLimitReached,
        onPressDetails: scannedEmployee.equipmentSearch
          ? () => {
              setChosenProjectTaskAction(pt);
              setShowEquipmentDetailsModalAction(true);
            }
          : null,
      })),
    message:
      scannedEmployee.customLabels.taskList.None || "No Project Tasks Found",
  };
}

function useTaskSearchBar() {
  const {
    task: { searchInput },
    employee: { scannedEmployee },
  } = useStoreState((state) => state);

  const { setTaskSearchInputAction } = useStoreActions((actions) => actions);

  return {
    value: searchInput,
    onChangeText: (searchInput) => setTaskSearchInputAction(searchInput),
    onClearInput: () => setTaskSearchInputAction(""),
    placeholder: scannedEmployee.customLabels.taskList
      ? scannedEmployee.customLabels.taskList.Filter
      : "",
  };
}

function useSearchTasksServerSideModal() {
  const { showProjectTaskServerSearchModal } = useStoreState(
    (state) => state.project
  );

  const { showProjectTaskServerSearchModalAction } = useStoreActions(
    (actions) => actions
  );

  return {
    visible: showProjectTaskServerSearchModal,
    onRequestClose: () => showProjectTaskServerSearchModalAction(false),
  };
}

function useSearchTasksServerSideForm() {
  const { projectTaskServerSearchInput } = useStoreState(
    (state) => state.project
  );

  const { setProjectTaskServerSearchInputAction, getProjectTasksThunk } =
    useStoreActions((actions) => actions);

  return {
    placeholder: "Enter search phrase",
    value: projectTaskServerSearchInput,
    onChangeText: (projectTaskServerSearchInput) =>
      setProjectTaskServerSearchInputAction(projectTaskServerSearchInput),
    onSubmitEditing: () => getProjectTasksThunk(),
    autoFocus: true,
  };
}

function useProjectTaskInformationModal() {
  const {
    project: { chosenProjectTask },
    employee: { scannedEmployee },
    boat: { showEquipmentDetailsModal },
  } = useStoreState((state) => state);

  const [activeKey, setActiveKey] = useState(scannedEmployee.taskTabs[0]);

  const { setChosenProjectTaskAction, setShowEquipmentDetailsModalAction } =
    useStoreActions((actions) => actions);

  return {
    visible: showEquipmentDetailsModal,
    onRequestClose: () => {
      setShowEquipmentDetailsModalAction(false);
      setChosenProjectTaskAction(null);
    },
    taskCD: chosenProjectTask?.TaskCD,
    activeTabKey: activeKey,
    onSelectTab: (key) => setActiveKey(key),
    tabs: scannedEmployee.taskTabs || [],
  };
}

export {
  useCheckTaskQualification,
  useProjectTaskList,
  useTaskSearchBar,
  useSearchTasksServerSideModal,
  useSearchTasksServerSideForm,
  useProjectTaskInformationModal,
};
