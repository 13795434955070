import { useStoreActions, useStoreState } from "easy-peasy";
import Modal from "../../presentational/Modal";
import Button from "../Button";
import Form from "../Form";

function useOnClose() {
  const {
    showAddUnserviceableStockItemModalAction,
    showPartSearchResultsModalAction,
  } = useStoreActions((actions) => actions);
  return () => {
    showAddUnserviceableStockItemModalAction(false);
    showPartSearchResultsModalAction(false);
  };
}

function AddUnserviceableStockItemModal(props) {
  const {
    part: { showAddUnserviceableStockItemModal },
    employee: { scannedEmployee },
  } = useStoreState((state) => state);

  const onClose = useOnClose();

  return (
    <Modal
      visible={showAddUnserviceableStockItemModal}
      onRequestClose={onClose}
      {...props}
    >
      <Modal.Header onRequestClose={onClose}>
        {scannedEmployee.customLabels.addUnserviceablePart.Title}
      </Modal.Header>

      <Modal.Body>
        <Form.AddUnserviceableStockItem />
      </Modal.Body>

      <Modal.Footer>
        <Button.AddUnserviceableStockItem />
      </Modal.Footer>
    </Modal>
  );
}

export default AddUnserviceableStockItemModal;
