import GetPartRequestsIcon from "./GetPartRequestsIcon";
import Icon from "./Icon";
import InspectionCompleteIcon from "./InspectionCompleteIcon";
import InspectionRejectIcon from "./InspectionRejectIcon";
import MarkCompleteIcon from "./MarkCompleteIcon";
import GoToNonRoutinePageIcon from "./GoToNonRoutinePageIcon";
import GoToPartsRequestPageIcon from "./GoToPartsRequestPageIcon";
import PrintItemRequestIcon from "./PrintItemRequestIcon";
import GoToSearchTasksPageIcon from "./GoToSearchTasksPageIcon";
import SignOutIcon from "./SignOutIcon";
import StopAllWorkIcon from "./StopAllWorkIcon";
import StopWorkIcon from "./StopWorkIcon";
import GoToTaskHomePageIcon from "./GoToTaskHomePageIcon";
import GoToEmployeeTasksPageIcon from "./GoToEmployeeTasksPageIcon";
import ShowScanTaskBarcodeModalIcon from "./ShowScanTaskBarcodeModalIcon";
import ShowScanBadgeBarcodeModalIcon from "./ShowScanBadgeBarcodeModalIcon";
import AddProjectTaskIcon from "./AddProjectTaskIcon";
import ShowSidebarIcon from "./ShowSidebarIcon";

Icon.GoToNonRoutinePage = GoToNonRoutinePageIcon;
Icon.GoToPartsRequestPage = GoToPartsRequestPageIcon;
Icon.InspectionComplete = InspectionCompleteIcon;
Icon.InspectionReject = InspectionRejectIcon;
Icon.MarkComplete = MarkCompleteIcon;
Icon.GoToSearchTasksPage = GoToSearchTasksPageIcon;
Icon.SignOut = SignOutIcon;
Icon.StopAllWork = StopAllWorkIcon;
Icon.StopWork = StopWorkIcon;
Icon.GoToEmployeeTasksPage = GoToEmployeeTasksPageIcon;
Icon.GoToTaskHomePage = GoToTaskHomePageIcon;
Icon.GetPartRequests = GetPartRequestsIcon;
Icon.PrintItemRequest = PrintItemRequestIcon;
Icon.ShowScanTaskBarcodeModal = ShowScanTaskBarcodeModalIcon;
Icon.ShowScanBadgeBarcodeModal = ShowScanBadgeBarcodeModalIcon;
Icon.AddProjectTask = AddProjectTaskIcon;
Icon.ShowSidebar = ShowSidebarIcon;

export default Icon;
