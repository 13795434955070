import axios from "axios";

const weatherAxios = axios.create({
  baseURL: "https://weatherapi-com.p.rapidapi.com",
  headers: {
    "X-RapidAPI-Key": "d196d5c02fmsh68c494d949f1c4ap1ca1d8jsn0772d108fb43",
    "X-RapidAPI-Host": "weatherapi-com.p.rapidapi.com",
  },
});

async function getWeatherApiCall() {
  const response = await weatherAxios({
    method: "get",
    url: "/current.json",
    params: { q: "auto:ip" },
  });
  return response.data;
}

export { weatherAxios, getWeatherApiCall };
