import Modal from "./Modal";
import Tabs from "../Tabs";
import Tab from "../Tab";
import styled from "styled-components/native";
import { useScreenDimensions } from "../../../utilities/responsive";
import { useProjectTaskInformationModal } from "../../../hooks/task";

const ScrollView = styled.ScrollView`
  max-height: ${(props) => props.maxHeight}px;
  width: 100%;
`;

function ProjectTaskInformationModal(props) {
  const { taskCD, activeTabKey, onSelectTab, tabs, ...modalProps } =
    useProjectTaskInformationModal();

  const { vhToDp, vwToDp } = useScreenDimensions();

  return (
    <Modal maxWidth={vwToDp(65)} {...modalProps} {...props}>
      <ScrollView maxHeight={vhToDp(85)}>
        <Modal.Header onRequestClose={modalProps.onRequestClose}>
          Task Information For {taskCD}
        </Modal.Header>

        <Modal.Body>
          <Tabs activeKey={activeTabKey} onSelect={onSelectTab}>
            {tabs.map((tab) => {
              const tabs = {
                "Work Instructions": <Tab.WorkInstructions />,
                "Item Requirements": <Tab.ItemRequirements />,
                SubTypes: <Tab.Subtypes />,
              };

              return (
                <Tab key={tab} eventKey={tab} title={tab}>
                  {tabs[tab] || tab}
                </Tab>
              );
            })}
          </Tabs>
        </Modal.Body>
      </ScrollView>
    </Modal>
  );
}

export default ProjectTaskInformationModal;
