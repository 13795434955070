import Modal from "../../presentational/Modal";
import styled from "styled-components/native";
import Button from "../Button";
import { useStoreActions, useStoreState } from "easy-peasy";
import { remToDp } from "../../../utilities/responsive";
import Input from "../../presentational/Input";

const Header = styled.Text`
  font-size: ${remToDp(1.75)}px;
  margin-bottom: 14px;
  text-align: center;
`;

function useOnClose() {
  const { showFaceScanAlternativeModalAction } = useStoreActions(
    (actions) => actions
  );
  return () => showFaceScanAlternativeModalAction(false);
}

function FaceScanAlternativeModal(props) {
  const { showFaceScanAlternativeModal, biometricAlternativeCodeInput } =
    useStoreState((state) => state.facialRecog);

  const { setBiometricAlternativeCodeInputAction, clockInAlternativeThunk } =
    useStoreActions((actions) => actions);

  const onClose = useOnClose();

  return (
    <Modal
      visible={showFaceScanAlternativeModal}
      onRequestClose={onClose}
      {...props}
    >
      <Modal.Header onRequestClose={onClose} />

      <Modal.Body>
        <Header>Alternate ID</Header>
        <Input
          onChangeText={(input) =>
            setBiometricAlternativeCodeInputAction(input)
          }
          value={biometricAlternativeCodeInput}
          placeholder="Enter your alternate ID"
          inputStyle={{ textAlign: "center" }}
          onSubmitEditing={() => clockInAlternativeThunk()}
        />
      </Modal.Body>

      <Modal.Footer>
        <Button.ClockInAlternative />
      </Modal.Footer>
    </Modal>
  );
}

export default FaceScanAlternativeModal;
