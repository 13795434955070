import { Button, Center, Div } from "@cfbs/cfbsstrap-native";
import { useStoreActions, useStoreState } from "easy-peasy";
import styled from "styled-components/native";
import Text from "../../presentational/Text";

const SButton = styled(Button)`
  opacity: ${(props) => props.opacity || 1};
`;

const Money = styled(Div)`
  align-items: center;
`;
Money.Sign = styled(Div)`
  border-radius: 50%;
  padding: 8px 12px;
  background-color: ${(props) => props.backgroundColor};
`;
Money.Text = styled(Text)`
  color: #ffffff;
`;

function ToggleBillableTaskButton({ children, ...props }) {
  const { currentTask } = useStoreState((state) => state.task);

  const { toggleBillableTaskThunk } = useStoreActions((actions) => actions);

  const disableButton =
    currentTask.NonBillable && currentTask.NonBillable.value;

  return (
    <SButton
      onPress={() => toggleBillableTaskThunk()}
      type="transparent"
      disabled={disableButton}
      opacity={disableButton ? 0.5 : 1}
      {...props}
    >
      <Center>
        <Money>
          <Money.Sign backgroundColor={disableButton ? "#dc3545" : "#00ad7e"}>
            <Money.Text>$</Money.Text>
          </Money.Sign>
        </Money>

        <Text>{`Mark ${disableButton ? "" : "Un-"}Billable`}</Text>
      </Center>
    </SButton>
  );
}

export default ToggleBillableTaskButton;
