import { thunk } from "easy-peasy";
import uuid from "react-native-uuid";
import {
  addInspectionRejectionApiCall,
  removeTasksFromEmployeeTasksApiCall,
  toggleBillableTaskApiCall,
} from "../../api/task";
import {
  OPERATION_ADD_NONROUTINE_TASK,
  OPERATION_ADD_PART_REQUEST_NAME,
  OPERATION_ADD_STOCK_ITEM_REQUEST_NAME,
  OPERATION_ADD_UNSERVICEABLE_PART_REQUEST_NAME,
  OPERATION_ADD_UNSERVICEABLE_STOCK_ITEM_NAME,
  OPERATION_INSPECTION_COMPLETE_NAME,
  OPERATION_INSPECTION_REJECT_NAME,
  OPERATION_MARK_COMPLETE_NAME,
  OPERATION_STOP_ALL_TASKS_NAME,
  OPERATION_STOP_TASK_NAME,
  OPERATION_TOGGLE_BILLABLE_TASK,
  OPERATION_UPDATE_SUBTYPE_ATTRIBUTE_VALUE,
} from "../../utilities/variables";
import {
  addPartRequestApiCall,
  addStockItemRequestApiCall,
  addUnserviceablePartRequestApiCall,
  addUnserviceableStockItemApiCall,
} from "../../api/part";
import { addNonroutineTaskApiCall } from "../../api/nonroutine";
import { handleAcumaticaError } from "../../utilities/error";
import { editSubtypeApiCall } from "../../api/boat";

const thunks = {
  setAlertThunk: thunk(async (actions, alert) => {
    const delay = (ms) => new Promise((res) => setTimeout(res, ms));
    const id = uuid.v4();
    actions.addAlertAction({ ...alert, id });
    await delay(5000);
    actions.removeAlertAction(id);
  }),
  setLockedAlertThunk: thunk(async (actions, alert) => {
    actions.addAlertAction({ ...alert, id: uuid.v4() });
  }),
  syncAppThunk: thunk(async (actions, payload, helpers) => {
    actions.setLoadingAction(true);

    try {
      for (let operation of helpers.getState().status.pendingOperations) {
        try {
          if (
            operation.operationName === OPERATION_MARK_COMPLETE_NAME ||
            operation.operationName === OPERATION_STOP_ALL_TASKS_NAME ||
            operation.operationName === OPERATION_STOP_TASK_NAME ||
            operation.operationName === OPERATION_INSPECTION_COMPLETE_NAME
          ) {
            await removeTasksFromEmployeeTasksApiCall(operation);
          } else if (
            operation.operationName === OPERATION_INSPECTION_REJECT_NAME
          ) {
            await addInspectionRejectionApiCall(operation);
          } else if (
            operation.operationName ===
            OPERATION_ADD_UNSERVICEABLE_PART_REQUEST_NAME
          ) {
            await addUnserviceablePartRequestApiCall(operation);
          } else if (
            operation.operationName === OPERATION_ADD_PART_REQUEST_NAME
          ) {
            await addPartRequestApiCall(operation);
          } else if (
            operation.operationName === OPERATION_ADD_NONROUTINE_TASK
          ) {
            await addNonroutineTaskApiCall(operation);
          } else if (
            operation.operationName ===
            OPERATION_ADD_UNSERVICEABLE_STOCK_ITEM_NAME
          ) {
            await addUnserviceableStockItemApiCall(operation);
          } else if (
            operation.operationName === OPERATION_ADD_STOCK_ITEM_REQUEST_NAME
          ) {
            await addStockItemRequestApiCall(operation);
          } else if (
            operation.operationName === OPERATION_UPDATE_SUBTYPE_ATTRIBUTE_VALUE
          ) {
            await editSubtypeApiCall(operation);
          } else if (
            operation.operationName === OPERATION_TOGGLE_BILLABLE_TASK
          ) {
            await toggleBillableTaskApiCall(operation);
          }

          actions.removePendingOperationAction(operation.operationId);
        } catch (err) {
          console.error(err);

          actions.setAlertThunk({
            type: "error",
            message: handleAcumaticaError(err),
          });
        }
      }

      await actions.reloadAppThunk();

      if (helpers.getState().status.pendingOperations.length) {
        throw new Error("App could not be fully synced");
      } else {
        actions.setAlertThunk({
          type: "success",
          message: "App is now synced",
        });
        actions.showSyncAppAlertAction(false);
        actions.setSyncFailedAction(false);
        actions.showSyncAppModalAction(false);
      }
    } catch (err) {
      console.error(err);
      actions.setSyncFailedAction(true);
      actions.setAlertThunk({
        type: "error",
        message: handleAcumaticaError(err),
      });
    }

    actions.setLoadingAction(false);
  }),
  finishAppSyncThunk: thunk(async (actions, payload, helpers) => {
    actions.setLoadingAction(true);

    try {
      actions.setSyncFailedAction(false);
      actions.showSyncAppAlertAction(false);
      actions.setPendingOperationsAction([]);
      await actions.reloadAppThunk();
      actions.setAlertThunk({
        type: "success",
        message: "App is now synced",
      });
    } catch (err) {
      console.error(err);
      actions.setAlertThunk({
        type: "error",
        message: handleAcumaticaError(err),
      });
    }

    actions.setLoadingAction(false);
  }),
  reloadAppThunk: thunk(async (actions, payload, helpers) => {
    actions.setLoadingAction(true);

    const { task } = helpers.getState();

    try {
      const operations = [actions.getEmployeeTasksThunk()];

      task.currentTask &&
        task.currentTask.ProjectTaskIDInt &&
        operations.push(actions.getPartRequestsThunk());

      await Promise.all(operations);

      task.currentTask &&
        task.currentTask.ProjectTaskIDInt &&
        actions.reloadCurrentTaskAction();
    } catch (err) {
      console.error(err);
      actions.setAlertThunk({
        type: "error",
        message: handleAcumaticaError(err),
      });
    }

    actions.setLoadingAction(false);
  }),
};

export default thunks;
