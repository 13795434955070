import styled from "styled-components/native";
import { remToDp } from "../../../utilities/responsive";

const Environment = styled.View`
  background-color: #e9ecee;
  border-radius: 10px;
  padding: ${remToDp(2)}px;
`;

export default Environment;
