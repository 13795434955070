import { action } from "easy-peasy";
import { dateToString } from "../../utilities/date";
import { defaultState } from "./state";

const actions = {
  setTaskListAction: action((state, list) => {
    state.task.list = list;
  }),
  setTaskSearchInputAction: action((state, searchInput) => {
    state.task.searchInput = searchInput;
  }),
  setTaskIdScanInputAction: action((state, taskIdScanInput) => {
    state.task.taskIdScanInput = taskIdScanInput;
  }),
  setQueueIsLockedAction: action((state, isLocked) => {
    state.task.queue.isLocked = isLocked;
  }),
  setQueueLockSecondsAction: action((state, seconds) => {
    state.task.queue.lockSeconds = seconds;
  }),
  setQueueIsCountDownActiveAction: action((state, active) => {
    state.task.queue.isCountDownActive = active;
  }),
  setCorrectiveActionInputAction: action((state, input) => {
    state.task.correctiveActionInput = input;
  }),
  setCurrentTaskAction: action((state, task) => {
    state.task.currentTask = task;
  }),
  reloadCurrentTaskAction: action((state) => {
    state.task.currentTask = state.task.list.find(
      (task) =>
        task.ProjectTaskID.value === state.task.currentTask.ProjectTaskID.value
    );
  }),
  showInspectionRejectModalAction: action((state, show) => {
    state.task.showInspectionRejectModal = show;
  }),
  setInspectionRejectReasonAction: action((state, input) => {
    state.task.inspectionRejectReasonInput = input;
  }),
  setStartTimeAction: action((state, time) => {
    state.task.startTime = time;
  }),
  updateStartTimeAction: action((state) => {
    state.task.startTime =
      state.task.list.length || !state.task.startTime
        ? dateToString(new Date())
        : null;
  }),
  syncTaskAction: action((state, ProjectTaskID) => {
    state.task.list = state.task.list.map((task) =>
      task.ProjectTaskID.value === ProjectTaskID
        ? { ...task, needsToBeSynced: true }
        : task
    );
  }),
  syncAllTasksAction: action((state) => {
    state.task.list = state.task.list.map((task) => ({
      ...task,
      needsToBeSynced: true,
    }));
  }),
  showScanTaskBarcodeModalAction: action((state, show) => {
    state.task.showScanTaskBarcodeModal = show;
  }),
  resetTaskStateAction: action((state) => {
    state.task = { ...defaultState, currentTask: state.task.currentTask };
  }),
  checkTaskLimitReachedAction: action((state) => {
    state.task.taskLimitReached =
      state.task.list.length === state.employee.scannedEmployee.taskLimit;
  }),
};

export default actions;
