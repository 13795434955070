import styled from "styled-components/native";
import { remToDp } from "../../../utilities/responsive";
import Text from "../Text";

const EnvironmentText = styled(Text)`
  font-family: Helvetica Neue;
  font-size: ${remToDp(1.5)}px;
`;

export default EnvironmentText;
