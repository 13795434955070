const OPERATION_MARK_COMPLETE_NAME = "Mark Complete";
const OPERATION_STOP_ALL_TASKS_NAME = "Stop All Tasks";
const OPERATION_STOP_TASK_NAME = "Stop Task";
const OPERATION_INSPECTION_COMPLETE_NAME = "Inspection Complete";
const OPERATION_INSPECTION_REJECT_NAME = "Inspection Reject";

const OPERATION_ADD_UNSERVICEABLE_PART_REQUEST_NAME =
  "Add Unserviceable Part Request";
const OPERATION_ADD_PART_REQUEST_NAME = "Add Part Request";
const OPERATION_ADD_UNSERVICEABLE_STOCK_ITEM_NAME =
  "Add Unserviceable Stock Item";
const OPERATION_ADD_STOCK_ITEM_REQUEST_NAME = "Add Stock Item Request";

const OPERATION_ADD_NONROUTINE_TASK = "Add Non-Routine Task";

const OPERATION_UPDATE_SUBTYPE_ATTRIBUTE_VALUE =
  "Update Subtype's Attribute Value";

const OPERATION_TOGGLE_BILLABLE_TASK = "Toggle Billable Task";

const PAGE_HOME = "Home";
const PAGE_PROJECT_SEARCH = "Project Search";
const PAGE_TASK = "Task";
const PAGE_PARTS_REQUEST = "Parts Request";
const PAGE_NONROUTINE = "Non-Routine";

const QUALIFICATION_ERROR_MESSAGE =
  "Your mechanical qualifications do not meet the minimum requirements to continue";

export {
  OPERATION_MARK_COMPLETE_NAME,
  OPERATION_STOP_ALL_TASKS_NAME,
  OPERATION_STOP_TASK_NAME,
  OPERATION_INSPECTION_COMPLETE_NAME,
  OPERATION_INSPECTION_REJECT_NAME,
  OPERATION_ADD_UNSERVICEABLE_PART_REQUEST_NAME,
  OPERATION_ADD_PART_REQUEST_NAME,
  OPERATION_ADD_UNSERVICEABLE_STOCK_ITEM_NAME,
  OPERATION_ADD_NONROUTINE_TASK,
  OPERATION_UPDATE_SUBTYPE_ATTRIBUTE_VALUE,
  PAGE_HOME,
  PAGE_PROJECT_SEARCH,
  PAGE_TASK,
  PAGE_PARTS_REQUEST,
  PAGE_NONROUTINE,
  QUALIFICATION_ERROR_MESSAGE,
  OPERATION_ADD_STOCK_ITEM_REQUEST_NAME,
  OPERATION_TOGGLE_BILLABLE_TASK,
};
