import WarningMessage from "./WarningMessage";

function AllowCameraUseMessage({ children, ...props }) {
  return (
    <WarningMessage {...props}>
      {children || "Please allow access to camera from the settings"}
    </WarningMessage>
  );
}

export default AllowCameraUseMessage;
