import { useWorkInstructionsTab } from "../../../hooks/boat";
import { remToDp, useScreenDimensions } from "../../../utilities/responsive";
import Input from "../../presentational/Input";
import Text from "../../presentational/Text";
import Div from "../Div";
import styled from "styled-components/native";

const Subheader = styled(Text.Primary)`
  font-size: ${remToDp(1.5)}px;
  margin: 8px 0px;
  font-weight: 500;
`;

function WorkInstructionsTab({ showCorrectiveAction, ...props }) {
  const { instructions, correctiveActionLabel, correctiveActionValue, input } =
    useWorkInstructionsTab();
  const { vhToDp } = useScreenDimensions();

  return (
    <Div {...props}>
      <Div>{instructions}</Div>

      {showCorrectiveAction && (
        <>
          <Subheader>{correctiveActionLabel}</Subheader>
          <Text numberOfLines={1}>{correctiveActionValue}</Text>

          <Input
            multiline
            style={{
              marginTop: 16,
              backgroundColor: "#ffffff",
              height: vhToDp(10),
            }}
            {...input}
          />
        </>
      )}
    </Div>
  );
}

export default WorkInstructionsTab;
