import React, { useEffect } from 'react';
import { Platform } from 'react-native';
import * as ImagePicker from 'expo-image-picker';
import { manipulateAsync, SaveFormat } from 'expo-image-manipulator';
import { useStoreActions, useStoreState } from 'easy-peasy';

import Icon from '../Icon';
import Row from '../Row';
import Col from '../Col';

export default function ImagePickerUpload({ onSelect, currentImages }) {
  const { setLoadingAction } = useStoreActions((actions) => actions);
  const {
    employee: { scannedEmployee },
  } = useStoreState((state) => state);
  useEffect(() => {
    (async () => {
      if (Platform.OS !== 'web') {
        const cameraRollStatus =
          await ImagePicker.requestMediaLibraryPermissionsAsync();
        const cameraStatus = await ImagePicker.requestCameraPermissionsAsync();
        if (
          cameraRollStatus.status !== 'granted' ||
          cameraStatus.status !== 'granted'
        ) {
          alert('Sorry, we need these permissions to make this work!');
        }
      }
    })();
  }, []);

  const takePhoto = async () => {
    let result = await ImagePicker.launchCameraAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      aspect: [4, 3],
    });

    await uploadFile(result);
  };

  const pickImage = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      allowsMultipleSelection: true,
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: false,
      aspect: [4, 3],
      quality: 1,
      selectionLimit: scannedEmployee?.imageUploadLimit || 0,
    });
    await uploadFile(result);
  };

  const uploadFile = async (result) => {
    if (result && !result.canceled) {
      setLoadingAction(true);
      let newImageList = [];

      const images = [...currentImages, ...result.assets];

      const imageMap = images.reduce((map, image) => {
        const fileName = image.fileName
          ? image.fileName
          : image.uri.split('/').pop();
        if (!map[fileName]) {
          map[fileName] = { fileName: fileName, uri: image.uri };
        }

        return map;
      }, {});
      const list = Object.values(imageMap);
      for (const uploadedImage of list) {
        const newImage = await manipulateAsync(
          uploadedImage.uri,
          [
            {
              resize: {
                width: scannedEmployee?.defaultImageWidth || 1024,
              },
            },
          ],
          { compress: 1, format: SaveFormat.PNG }
        );

        newImageList.push({
          fileName: uploadedImage.fileName,
          uri: newImage.uri,
        });
      }
      onSelect([...newImageList]);
    }
    setLoadingAction(false);
  };

  return (
    <>
      <Row>
        <Col xs={12} md={6} lg={6}>
          <Icon.Image onPress={pickImage} />
        </Col>
        {Platform.OS !== 'web' && (
          <Col xs={12} md={6} lg={6}>
            <Icon.Camera onPress={takePhoto} />
          </Col>
        )}
      </Row>
    </>
  );
}
