import StockItemItem from "./StockItemItem";
import StockItem from "./StockItem";
import Text from "../Text";
import Center from "../Center";

function StockItemList({ list, message, ...props }) {
  return (
    <StockItem {...props}>
      {(list || []).length ? (
        list.map((part) => <StockItemItem key={part.id} {...part} />)
      ) : (
        <Center>
          <Text.Error>{message || "No Inventory Items Found"}</Text.Error>
        </Center>
      )}
    </StockItem>
  );
}

export default StockItemList;
