import { useStoreActions } from "easy-peasy";
import Icon from "../../presentational/Icon";
import { Feather } from "@expo/vector-icons";
import { OPERATION_INSPECTION_REJECT_NAME } from "../../../utilities/variables";

function InspectionRejectIcon(props) {
  const { showInspectionRejectModalAction } = useStoreActions(
    (actions) => actions
  );
  return (
    <Icon.WithText
      icon={<Icon.Red Component={Feather} name="x-circle" />}
      text={OPERATION_INSPECTION_REJECT_NAME}
      onPress={() => showInspectionRejectModalAction(true)}
      {...props}
    />
  );
}

export default InspectionRejectIcon;
