import { MaterialCommunityIcons } from '@expo/vector-icons';
import Icon from './Icon';

function ImageIcon(props) {
  return (
    <Icon
      Component={MaterialCommunityIcons}
      name='file-image-plus-outline'
      {...props}
    />
  );
}

export default ImageIcon;
