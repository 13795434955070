import { action } from "easy-peasy";
import { defaultState } from "./state";

const actions = {
  setBadgeIdInputAction: action((state, badgeId) => {
    state.employee.badgeIdInput = badgeId;
  }),
  setScannedEmployeeAction: action((state, employee) => {
    state.employee.scannedEmployee = {
      ...state.employee.scannedEmployee,
      ...employee,
    };
  }),
  resetScannedEmployeeAction: action((state) => {
    state.employee.scannedEmployee = defaultState.scannedEmployee;
  }),
  setUsingCentrikAction: action((state, usingCentrik) => {
    state.employee.usingCentrik = usingCentrik;
  }),
  setValiditesAction: action((state, validities) => {
    state.employee.qualifications.validities = validities;
  }),
  setQualificationsPersonalAction: action((state, qualifications) => {
    state.employee.qualifications.personal = qualifications;
  }),
  setQualificationsTypeAction: action((state, qualifications) => {
    state.employee.qualifications.type = qualifications;
  }),
  setQualificationsCustomerAction: action((state, qualifications) => {
    state.employee.qualifications.customer = qualifications;
  }),
  setQualificationsMechanicAction: action((state, mechanicQualified) => {
    state.employee.qualifications.mechanicQualified = mechanicQualified;
  }),
  setQualificationsInspectorAction: action((state, inspectorQualified) => {
    state.employee.qualifications.inspectorQualified = inspectorQualified;
  }),
  setQualificationsBoroscopeAction: action((state, boroscopeQualified) => {
    state.employee.qualifications.boroscopeQualified = boroscopeQualified;
  }),
  setQualificationsFailedAction: action((state, failedQualification) => {
    state.employee.qualifications.failedQualification = failedQualification;
  }),
  showScanBadgeBarcodeModalAction: action((state, show) => {
    state.employee.showScanBadgeBarcodeModal = show;
  }),
  resetEmployeeStateAction: action((state) => {
    state.employee = {
      ...defaultState,
      scannedEmployee: {
        ...defaultState.scannedEmployee,
        customLabels: state.employee.scannedEmployee.customLabels,
      },
    };
  }),
};

export default actions;
