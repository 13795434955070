import { Modal as RNModal } from "react-native";
import styled from "styled-components/native";
import { useScreenDimensions } from "../../../utilities/responsive";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import Center from "../Center";

const Container = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: #00000080;
`;
Container.ScrollView = styled(KeyboardAwareScrollView)`
  max-width: ${(props) => props.maxWidth}px;
  width: 100%;
`;
Container.Content = styled.View`
  background-color: #fff;
  border-radius: 10px;
  align-items: center;
`;

function Modal({ children, style, maxWidth, ...props }) {
  const { vwToDp } = useScreenDimensions();
  return (
    <RNModal animationType="slide" transparent={true} {...props}>
      <Container>
        <Center>
          <Container.ScrollView maxWidth={maxWidth || vwToDp(50)}>
            <Container.Content style={style}>{children}</Container.Content>
          </Container.ScrollView>
        </Center>
      </Container>
    </RNModal>
  );
}

export default Modal;
