import SearchBarView from "../presentational/SearchBar";
import Icon from "../presentational/Icon";

function SearchBar({ onClearInput, ...props }) {
  return (
    <SearchBarView
      clearIcon={<Icon.Clear onPress={onClearInput} />}
      {...props}
    />
  );
}

export default SearchBar;
