import styled from "styled-components/native";
import Center from "./Center";
import { useScreenDimensions } from "../../../utilities/responsive";

const Container = styled(Center)`
  height: ${(props) => props.fullHeight}px;
`;

function CenterScreen({ children, ...props }) {
  const { vhToDp } = useScreenDimensions();
  return (
    <Container fullHeight={vhToDp(100)} {...props}>
      {children}
    </Container>
  );
}

export default CenterScreen;
