import { useStoreActions, useStoreState } from "easy-peasy";
import Icon from "../Icon";
import Input from "../../presentational/Input";
import { Platform } from "react-native";
import { QUALIFICATION_ERROR_MESSAGE } from "../../../utilities/variables";

function useOnChange() {
  const {
    employee: {
      scannedEmployee: { preventTypingInTaskCard, preventTypingTimeoutMS },
      usingCentrik,
      qualifications: { mechanicQualified },
    },
    status: { hasWifi },
  } = useStoreState((state) => state);

  const { setTaskIdScanInputAction, setAlertThunk } = useStoreActions(
    (actions) => actions
  );

  return (taskId) => {
    if (!usingCentrik || mechanicQualified) {
      if (hasWifi) {
        setTaskIdScanInputAction(taskId);
        preventTypingInTaskCard &&
          setTimeout(
            () => setTaskIdScanInputAction(""),
            preventTypingTimeoutMS
          );
      }
    } else {
      setAlertThunk({
        type: "warning",
        message: QUALIFICATION_ERROR_MESSAGE,
      });
    }
  };
}

function TaskInput(props) {
  const {
    task: { taskIdScanInput },
    status: { hasWifi },
    employee: { scannedEmployee },
  } = useStoreState((state) => state);

  const { scanTaskThunk } = useStoreActions((actions) => actions);

  const onChange = useOnChange();

  return (
    <Input
      inputStyle={{ textAlign: "center" }}
      placeholder={
        hasWifi ? scannedEmployee.customLabels.taskList.Scan : "Must be online"
      }
      onChangeText={onChange}
      onSubmitEditing={() => scanTaskThunk()}
      value={taskIdScanInput}
      rightIcon={
        Platform.OS === "web" ? null : <Icon.ShowScanTaskBarcodeModal />
      }
      {...props}
    />
  );
}

export default TaskInput;
