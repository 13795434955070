import styled from "styled-components/native";
import Text from "../presentational/Text/Text";
import { useStoreActions, useStoreState } from "easy-peasy";
import { remToDp } from "../../utilities/responsive";
import { useEffect } from "react";

const TimeText = styled(Text)`
  color: #dc3545;
  font-size: ${remToDp(2)}px;
`;

function QueueLockTimer(props) {
  const { isLocked, lockSeconds, isCountDownActive } = useStoreState(
    (state) => state.task.queue
  );

  const {
    setQueueIsLockedAction,
    setQueueLockSecondsAction,
    setQueueIsCountDownActiveAction,
  } = useStoreActions((actions) => actions);

  useEffect(() => {
    let interval = null;

    if (isCountDownActive) {
      interval = setInterval(
        () => setQueueLockSecondsAction(lockSeconds - 1),
        1000
      );
    } else if (!isCountDownActive && lockSeconds !== null) {
      clearInterval(interval);
    }

    if (lockSeconds !== 0) {
      return () => clearInterval(interval);
    } else {
      setQueueLockSecondsAction(180);
      setQueueIsCountDownActiveAction(false);
      setQueueIsLockedAction(true);
      return () => clearInterval(interval);
    }
  }, [isCountDownActive, lockSeconds]);

  return (
    <TimeText {...props}>
      Queue {!isLocked ? `will lock in ${lockSeconds}s` : "is locked"}
    </TimeText>
  );
}

export default QueueLockTimer;
