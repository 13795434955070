import styled from "styled-components/native";
import Row from "../Row";
import Col from "../Col";
import Text from "../Text";
import { remToDp } from "../../../utilities/responsive";
import Center from "../Center";
import { getFirstOpenedTaskTime } from "../../../utilities/task";

const Container = styled.Pressable`
  background-color: #f4f4f4;
  border-color: #ccc;
  border-width: 1px;
`;

const SText = styled(Text)`
  color: #2c6892;
  margin-top: 2px;
`;
SText.TaskId = styled(SText)`
  color: #014daf;
  font-size: ${remToDp(1.5)}px;
  font-weight: 600;
`;

function TaskItem({
  ProjectDescription,
  ProjectTaskID,
  ProjectTaskDescription,
  TimeUTC,
  TaskStatus,
  ...props
}) {
  return (
    <Container {...props}>
      <Row>
        <Col xs={12} md={6}>
          <Center>
            <SText.TaskId>Task {ProjectTaskID}</SText.TaskId>
          </Center>
        </Col>

        <Col xs={12} md={6}>
          <Center>
            <SText>
              {ProjectDescription} - Open Since{" "}
              {getFirstOpenedTaskTime(TimeUTC)}
            </SText>
          </Center>
        </Col>

        <Col xs={12} md={6}>
          <Center>
            <SText>
              Status:{" "}
              <SText.TaskStatus status={TaskStatus}>
                {TaskStatus}
              </SText.TaskStatus>
            </SText>
          </Center>
        </Col>

        <Col xs={12} md={6}>
          <Center>
            <SText>{ProjectTaskDescription}</SText>
          </Center>
        </Col>
      </Row>
    </Container>
  );
}

export default TaskItem;
