import styled from "styled-components/native";
import Text from "../Text";
import Td from "./Td";
import React from "react";
import { remToDp } from "../../../utilities/responsive";

const SText = styled(Text)`
  font-weight: bold;
  font-size: ${remToDp(1.25)}px;
`;

function Th({ children, ...props }) {
  return (
    <Td {...props}>
      {typeof children === "string" ? (
        <SText style={props.style}>{children}</SText>
      ) : (
        children
      )}
    </Td>
  );
}

export default Th;
