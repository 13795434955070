import { useStoreActions, useStoreState } from "easy-peasy";
import Button from "../../presentational/Button";

function AddStockItemRequestButton({ children, ...props }) {
  const { unserviceableStockItemInput, partQuantityInput } = useStoreState(
    (state) => state.part
  );

  const { addStockItemRequestThunk } = useStoreActions((actions) => actions);

  return (
    <Button
      color="#198754"
      onPress={() => addStockItemRequestThunk()}
      disabled={
        !unserviceableStockItemInput.InventoryID ||
        !unserviceableStockItemInput.Description ||
        !partQuantityInput.Qty ||
        !partQuantityInput.UsrPriorityLevel ||
        !partQuantityInput.UsrNeedBy
      }
      {...props}
    >
      {children || "Create New Stock Item Request"}
    </Button>
  );
}

export default AddStockItemRequestButton;
