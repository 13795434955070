import { axios } from ".";
import qs from "qs";

const employeeAxios = axios.create({
  baseURL: `${process.env.EXPO_PUBLIC_BACKEND_BASE_URL}/api/v1/employee`,
});

async function getEmployeeApiCall(badgeId) {
  const response = await employeeAxios({
    method: "get",
    url: `?${qs.stringify({
      $filter: `EmployeeRefNo eq '${badgeId}'`,
    })}`,
  });
  return response.data;
}

export { employeeAxios, getEmployeeApiCall };
