import { useState } from "react";
import Input from "./Input";

function InputTextArea({ inputStyle, ...props }) {
  const [inputHeight, setInputHeight] = useState(0);

  return (
    <Input
      multiline
      inputStyle={{
        height: inputHeight,
        ...inputStyle,
      }}
      onContentSizeChange={(e) =>
        setInputHeight(e.nativeEvent.contentSize.height)
      }
      {...props}
    />
  );
}

export default InputTextArea;
