import React from "react";
import { NavigationContainer } from "@react-navigation/native";
import { createDrawerNavigator } from "@react-navigation/drawer";
import TaskPage from "../pages/TaskPage";
import {
  PAGE_HOME,
  PAGE_NONROUTINE,
  PAGE_PARTS_REQUEST,
  PAGE_PROJECT_SEARCH,
  PAGE_TASK,
} from "../../utilities/variables";
import Pages from "../pages";

const Drawer = createDrawerNavigator();

function AppNavigator(props) {
  const options = {
    headerShown: false,
  };

  return (
    <NavigationContainer {...props}>
      <Drawer.Navigator initialRouteName={PAGE_HOME}>
        <Drawer.Screen
          name={PAGE_HOME}
          component={Pages.Home}
          options={options}
        />

        <Drawer.Screen
          name={PAGE_PROJECT_SEARCH}
          component={Pages.ProjectSearch}
          options={options}
        />

        <Drawer.Screen
          name={PAGE_TASK}
          component={TaskPage}
          options={options}
        />

        <Drawer.Screen
          name={PAGE_PARTS_REQUEST}
          component={Pages.PartsRequest}
          options={options}
        />

        <Drawer.Screen
          name={PAGE_NONROUTINE}
          component={Pages.Nonroutine}
          options={options}
        />
      </Drawer.Navigator>
    </NavigationContainer>
  );
}

export default AppNavigator;
