import { action } from "easy-peasy";
import uuid from "react-native-uuid";
import { defaultState } from "./state";

const actions = {
  setLoadingAction: action((state, loading) => {
    state.status.loading = loading;
  }),
  addAlertAction: action((state, alert) => {
    state.status.alerts = [...state.status.alerts, alert];
  }),
  removeAlertAction: action((state, alertToRemoveId) => {
    state.status.alerts = state.status.alerts.filter(
      (alert) => alert.id !== alertToRemoveId
    );
  }),
  showSyncAppModalAction: action((state, show) => {
    state.status.showSyncAppModal = show;
  }),
  addPendingOperationAction: action((state, op) => {
    state.status.pendingOperations = [
      ...state.status.pendingOperations,
      { ...op, operationId: uuid.v4() },
    ];
  }),
  removePendingOperationAction: action((state, opId) => {
    state.status.pendingOperations = state.status.pendingOperations.filter(
      (op) => op.operationId !== opId
    );
  }),
  setPendingOperationsAction: action((state, list) => {
    state.status.pendingOperations = list;
  }),
  showSyncAppAlertAction: action((state, show) => {
    state.status.showSyncAppAlert = show;
  }),
  setHasWifiAction: action((state, hasWifi) => {
    state.status.hasWifi = hasWifi;
  }),
  setSyncFailedAction: action((state, syncFailed) => {
    state.status.syncFailed = syncFailed;
  }),
  setAllPendingOperationsAsFailedAction: action((state) => {
    state.status.pendingOperations = state.status.pendingOperations.map(
      (op) => ({ ...op, failed: true })
    );
  }),
  resetStatusStateAction: action((state) => {
    state.status = { ...defaultState, hasWifi: state.status.hasWifi };
  }),
};

export default actions;
