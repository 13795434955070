import { thunk } from "easy-peasy";
import {
  clockInAlternativeApiCall,
  clockInApiCall,
  uploadFaceImageToAzureApiCall,
} from "../../api/facialRecog";
import { Buffer } from "buffer";
import moment from "moment";
import uuid from "react-native-uuid";
import { handleAcumaticaError } from "../../utilities/error";

const thunks = {
  scanFaceThunk: thunk(async (actions, faceImgBase64, helpers) => {
    actions.setLoadingAction(true);

    try {
      const { facialRecog, employee } = helpers.getState();

      actions.createLogStepAction("Frontend");
      actions.createLogStepAction("Uploadmage");

      const imageBaseUrl = `${
        employee.scannedEmployee.blobContainerUrl
      }${uuid.v4()}.png`;
      await uploadFaceImageToAzureApiCall(
        `${imageBaseUrl}?${employee.scannedEmployee.blobContainerSAS}`,
        new Buffer.from(
          faceImgBase64.replace(/^data:image\/\w+;base64,/, ""),
          "base64"
        )
      );

      actions.createLogStepAction("Uploadmage");
      actions.createLogStepAction("PostClockin");

      const res = await clockInApiCall({
        time: moment().format(),
        dataUri: faceImgBase64,
        fileUrl: imageBaseUrl,
        logObj: facialRecog.logObj,
        txName: `TX${parseInt(Math.random() * 10000).toString()}`,
        BAccountID: employee.scannedEmployee.BAccountID,
        isClockingIn: facialRecog.isClockingIn,
        EmployeeRefNo: employee.scannedEmployee.EmployeeRefNo,
      });

      if (
        res.data.logObj.Person.PersonID !==
        employee.scannedEmployee.FacialRecogID
      ) {
        throw new Error("No matching facial recognition ID");
      }

      actions.setLogObjAction(res.data.logObj);

      if (res.data.recog) {
        actions.processFaceScanSuccessAction(res.data.timeEntry);
      } else {
        actions.processFaceScanFailAction(res.data);
      }

      actions.createLogStepAction("PostClockin");
      actions.createLogStepAction("Frontend");

      setTimeout(
        () => actions.processFaceScanCleanupAction(res.data),
        res.data.recog ? 2000 : 5000
      );

      actions.setScannedEmployeeAction({
        KronosClockIn: facialRecog.isClockingIn ? true : false,
      });

      actions.showFaceScanModalAction(false);
      actions.setAlertThunk({
        type: "success",
        message: `Successfully clocked ${
          facialRecog.isClockingIn ? "in" : `out. ${res.data.message}`
        }`,
      });

      !facialRecog.isClockingIn &&
        setTimeout(() => actions.logoutEmployeeThunk(), 3000);
    } catch (err) {
      console.error(err);
      actions.processFaceScanFailAction(handleAcumaticaError(err));
      setTimeout(() => {
        actions.processFaceScanCleanupAction();
        actions.showFaceScanModalAction(true);
      }, 5000);
    }
    actions.setLoadingAction(false);
  }),
  clockInAlternativeThunk: thunk(async (actions, payload, helpers) => {
    actions.setLoadingAction(true);
    try {
      const { facialRecog, employee } = helpers.getState();

      if (
        employee.scannedEmployee.BiometricAlternativeCode ===
        facialRecog.biometricAlternativeCodeInput
      ) {
        const response = await clockInAlternativeApiCall({
          AccountID: employee.scannedEmployee.AccountID,
          isClockingIn: facialRecog.isClockingIn,
          EmployeeRefNo: employee.scannedEmployee.EmployeeRefNo,
        });
        actions.setScannedEmployeeAction({
          KronosClockIn: true,
        });

        actions.setAlertThunk({
          type: "success",
          message: `Successfully clocked ${
            facialRecog.isClockingIn ? "in" : `out. ${response.data.message}`
          }`,
        });

        !facialRecog.isClockingIn &&
          setTimeout(() => actions.logoutEmployeeThunk(), 3000);

        actions.setBiometricAlternativeCodeInputAction("");
      } else {
        actions.logoutEmployeeThunk();
      }

      actions.showFaceScanAlternativeModalAction(false);
    } catch (err) {
      console.error(err);
      actions.processFaceScanFailAction(handleAcumaticaError(err));
      setTimeout(() => {
        actions.processFaceScanCleanupAction();
        actions.showFaceScanAlternativeModalAction(true);
      }, 5000);
    }
    actions.setLoadingAction(false);
  }),
};

export default thunks;
