import { useStoreActions } from "easy-peasy";
import { remToDp } from "../../../utilities/responsive";
import Icon from "../../presentational/Icon";
import { FontAwesome } from "@expo/vector-icons";
import { Pressable } from "react-native";

function PrintItemRequestIcon({ data, ...props }) {
  const { printPartsRequisitionPdfThunk } = useStoreActions(
    (actions) => actions
  );

  return (
    <Pressable onPress={() => printPartsRequisitionPdfThunk(data)}>
      <Icon.Green
        Component={FontAwesome}
        name="print"
        size={remToDp(1.5)}
        {...props}
      />
    </Pressable>
  );
}

export default PrintItemRequestIcon;
