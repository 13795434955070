import { MaterialCommunityIcons } from "@expo/vector-icons";
import AntDesign from "@expo/vector-icons/AntDesign";
import { remToDp } from "../../utilities/responsive";
import { Pressable } from "react-native";
import { Center } from "@cfbs/cfbsstrap-native";

function Icon({ type, iconProps, ...props }) {
  const icons = {
    back: <AntDesign name="arrowleft" size={remToDp(1.5)} {...iconProps} />,
    imageFile: (
      <MaterialCommunityIcons
        name="file-image-plus-outline"
        size={remToDp(2.5)}
        {...iconProps}
      />
    ),
    camera: (
      <MaterialCommunityIcons
        name="camera-outline"
        size={remToDp(2.5)}
        {...iconProps}
      />
    ),
  };

  return (
    <Pressable {...props}>
      <Center>{icons[type]}</Center>
    </Pressable>
  );
}

export default Icon;
