import styled from "styled-components/native";
import { remToDp } from "../../../utilities/responsive";

const Container = styled.View`
  padding: ${remToDp(1)}px;
  width: 100%;
`;

function ModalBody({ children, ...props }) {
  return <Container {...props}>{children}</Container>;
}

export default ModalBody;
