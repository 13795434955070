import { thunk } from "easy-peasy";
import { handleAcumaticaError } from "../../utilities/error";
import {
  editSubtypeApiCall,
  getEquipmentApiCall,
  searchSubtypesApiCall,
} from "../../api/boat";
import { OPERATION_UPDATE_SUBTYPE_ATTRIBUTE_VALUE } from "../../utilities/variables";
import { getPartRequestManifestApiCall } from "../../api/part";
import { getProjectTasksApiCall } from "../../api/project";

const thunks = {
  searchSubtypesThunk: thunk(async (actions, equipmentId, helpers) => {
    try {
      actions.setSubtypeSearchResultsAction(
        await searchSubtypesApiCall(
          equipmentId ||
            helpers.getState().project.chosenProjectTask?.EquipmentID
        )
      );
    } catch (err) {
      console.error(err);
      actions.setAlertThunk({
        type: "error",
        message: handleAcumaticaError(err),
      });
    }
  }),
  editSubtypeThunk: thunk(async (actions, payload, helpers) => {
    const {
      boat: {
        chosenSubtype: {
          EquipmentNoteID,
          SubTypeNoteID,
          SubTypeDetailNoteID,
          EquipmentCD,
          SubTypeID,
          AttributeCD,
          AttributeDesc,
          Attribute,
        },
        subtypeAttributeInput,
      },
      status: { hasWifi },
    } = helpers.getState();

    try {
      const data = {
        EquipmentNoteID,
        SubTypeNoteID,
        SubTypeDetailNoteID,
        Value: subtypeAttributeInput,
        Attribute,
        AttributeCD,
      };

      if (hasWifi) {
        const customerEquipment = await editSubtypeApiCall(data);
        if (customerEquipment.id) {
          actions.setSubtypeAttributeInputAction("");
          actions.setChosenSubtypeAction(null);
          actions.setAlertThunk({
            type: "success",
            message: `Updated Subtype ${SubTypeID} with value for Attribute ${AttributeDesc} for Equipment ${EquipmentCD}`,
          });
        }
      } else {
        const operationLabel = "Update Subtype's Attribute Value";
        actions.addPendingOperationAction({
          ...data,
          operationName: OPERATION_UPDATE_SUBTYPE_ATTRIBUTE_VALUE,
          operationLabel,
          operationDescription: `Update Subtype ${SubTypeID} with value for Attribute ${AttributeDesc} for Equipment ${EquipmentCD}`,
        });
        actions.updateSubtypeAttributeValueOfflineAction({
          SubTypeID,
          AttributeCD,
          AttributeDesc,
          NewValue: subtypeAttributeInput,
        });
        actions.setAlertThunk({
          message: `${operationLabel} is now pending. You will need to sync this app once you are online again.`,
          type: "success",
        });
        actions.setChosenSubtypeAction(null);
        actions.setSubtypeAttributeInputAction("");
      }
    } catch (err) {
      console.error(err);
      actions.setAlertThunk({
        type: "error",
        message: handleAcumaticaError(err),
      });
    }
  }),
  getEquipmentThunk: thunk(async (actions, equipmentId) => {
    actions.setLoadingAction(true);

    try {
      actions.setEquipmentAction(await getEquipmentApiCall(equipmentId));
    } catch (err) {
      console.error(err);
      actions.setAlertThunk({
        type: "error",
        message: handleAcumaticaError(err),
      });
    }

    actions.setLoadingAction(false);
  }),
  getBoatItemRequirementsThunk: thunk(async (actions, payload, helpers) => {
    try {
      actions.setBoatItemRequirementsAction(
        await getPartRequestManifestApiCall(
          helpers.getState().project.chosenProjectTask?.TaskCD
        )
      );
    } catch (err) {
      console.error(err);
      actions.setAlertThunk({
        type: "error",
        message: handleAcumaticaError(err),
      });
    }
  }),
  getEquipmentDetailsOnTaskQueueThunk: thunk(
    async (actions, { WorkOrderID, ProjectTaskID }, helpers) => {
      actions.setLoadingAction(true);

      try {
        const currentTask = helpers.getState().task.currentTask;

        const projectTasks = await getProjectTasksApiCall(
          WorkOrderID || currentTask.WorkOrderID.value
        );
        actions.setChosenProjectTaskAction(
          projectTasks.find(
            (task) =>
              task.TaskCD.trim() ===
              (ProjectTaskID || currentTask.ProjectTaskID.value).trim()
          )
        );
      } catch (err) {
        console.error(err);
        actions.setAlertThunk({
          type: "error",
          message: handleAcumaticaError(err),
        });
      }

      actions.setLoadingAction(false);
    }
  ),
};

export default thunks;
