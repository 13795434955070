import { useStoreActions } from "easy-peasy";
import Button from "../../presentational/Button";

function SyncFixConfirmedButton({ children, ...props }) {
  const { finishAppSyncThunk } = useStoreActions((actions) => actions);
  return (
    <Button onPress={() => finishAppSyncThunk()} {...props}>
      {children || "Finish app sync"}
    </Button>
  );
}

export default SyncFixConfirmedButton;
