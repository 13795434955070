import Modal from "./Modal";
import ModalBody from "./ModalBody";
import ModalCalendar from "./ModalCalendar";
import ModalCamera from "./ModalCamera";
import ModalFooter from "./ModalFooter";
import ModalHeader from "./ModalHeader";
import ModalPdf from "./ModalPdf";

Modal.Calendar = ModalCalendar;
Modal.Header = ModalHeader;
Modal.Body = ModalBody;
Modal.Footer = ModalFooter;
Modal.Camera = ModalCamera;
Modal.Pdf = ModalPdf;

export default Modal;
