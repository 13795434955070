const defaultState = {
  searchInput: "",
  projectList: [],
  chosenProject: null,
  projectTaskList: [],
  projectTaskServerSearchInput: "",
  showProjectTaskServerSearchModal: false,
  imageUrlList: [],
  showAddProjectImagesModal: false,
  chosenProjectTask: null,
};

const state = {
  project: defaultState,
};

export { state as default, defaultState };
