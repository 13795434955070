import { useStoreActions, useStoreState } from "easy-peasy";
import Button from "../../presentational/Button";

function AddServiceTaskButton({ children, ...props }) {
  const { chosenService } = useStoreState((state) => state.nonRoutine);

  const { addServiceTaskThunk } = useStoreActions((actions) => actions);

  return (
    <Button
      fullWidth
      color="#2c6892"
      onPress={() => addServiceTaskThunk()}
      disabled={!chosenService}
      {...props}
    >
      {children || "Add Task"}
    </Button>
  );
}

export default AddServiceTaskButton;
