import PartRequestItem from "./PartRequestItem";
import PartRequest from "./PartRequest";
import styled from "styled-components/native";
import Text from "../Text";

const SPartRequestItem = styled(PartRequestItem)`
  margin-top: 6px;
  margin-bottom: 6px;
`;

function PartRequestList({ list, message, ...props }) {
  return (
    <PartRequest {...props}>
      {(list || []).length ? (
        list.map((partRequest, index) => (
          <SPartRequestItem key={index} {...partRequest} />
        ))
      ) : (
        <Text.NotFound>
          {message || "No Project Part Request found"}
        </Text.NotFound>
      )}
    </PartRequest>
  );
}

export default PartRequestList;
