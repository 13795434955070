import { useStoreActions, useStoreState } from "easy-peasy";
import Option from "../../presentational/Option";
import SearchBar from "../SearchBar";
import Input from "../../presentational/Input";
import { caseInsensitiveFilter } from "../../../utilities/string";

function ZoneInput(props) {
  const {
    nonRoutine: {
      zones,
      zoneSearchInput,
      nonroutineInput: { ATAChapterID, ZoneID },
    },
    employee: { scannedEmployee },
  } = useStoreState((state) => state);

  const { setNonroutineInputAction } = useStoreActions((actions) => actions);

  return (
    <Input.Select
      label={scannedEmployee.customLabels.nonRoutine.Zone}
      value={ZoneID || ""}
      placeholder={scannedEmployee.customLabels.nonRoutine.Zone}
      header={`Select ${scannedEmployee.customLabels.nonRoutine.Zone}`}
      SearchBar={<SearchBar.Zone />}
      disabled={!ATAChapterID}
      options={zones
        .filter((z) =>
          caseInsensitiveFilter([z.ZoneID, z.Description], zoneSearchInput)
        )
        .map((z) => ({
          ...z,
          children: <Option.Nonroutine ID={z.ZoneID} {...z} />,
        }))}
      onChange={(option) =>
        setNonroutineInputAction({
          ZoneID: option.ZoneID,
        })
      }
      {...props}
    />
  );
}

export default ZoneInput;
