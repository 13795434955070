import { useStoreActions, useStoreState } from "easy-peasy";
import SearchBar from "../../presentational/SearchBar";
import Icon from "../../presentational/Icon";

function ZoneSearchBar(props) {
  const { zoneSearchInput } = useStoreState((state) => state.nonRoutine);

  const { setZoneSearchInputAction } = useStoreActions((actions) => actions);

  return (
    <SearchBar
      value={zoneSearchInput}
      onChangeText={(input) => setZoneSearchInputAction(input)}
      clearIcon={<Icon.Clear onPress={() => setZoneSearchInputAction("")} />}
      placeholder="Filter Zones"
      {...props}
    />
  );
}

export default ZoneSearchBar;
