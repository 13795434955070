import { axios } from '.';
import { Platform } from 'react-native';
import * as FileSystem from 'expo-file-system';

import { getAuthToken } from '../utilities/api';

const nonRoutineAxios = axios.create({
  baseURL: `${process.env.EXPO_PUBLIC_BACKEND_BASE_URL}/api/v1/nonRoutineTask`,
});

async function getNonRoutineDropdownsApiCall() {
  const response = await nonRoutineAxios({
    method: 'get',
    url: '/dropdowns',
  });
  return response.data;
}

async function addNonroutineTaskApiCall(data) {
  const response = await nonRoutineAxios({
    method: 'put',
    data,
  });
  return response.data;
}

const uploadImageToNonRoutineTask = async (payload) => {
  const url = `${process.env.EXPO_PUBLIC_BACKEND_BASE_URL}/api/v1/nonRoutineTask/uploadImage`;
  const token = await getAuthToken();
  let header = `Bearer ${token}`;

  if (Platform.OS === 'web') {
    const rest = await fetch(payload.imageUrl);
    const blob = await rest.blob();
    const formData = new FormData();
    formData.append('nonRoutineImage', blob);
    formData.append('id', payload.id);
    const myHeaders = new Headers();
    myHeaders.append('Authorization', header);
    await fetch(url, {
      method: 'PUT',
      headers: myHeaders,
      body: formData,
    });
  } else {
    await FileSystem.uploadAsync(url, payload.imageUrl, {
      fieldName: 'nonRoutineImage',
      httpMethod: 'PUT',
      uploadType: FileSystem.FileSystemUploadType.MULTIPART,
      headers: {
        Authorization: header,
      },
      parameters: {
        id: payload.id,
      },
    });
  }
};

async function createNonroutineTaskPdfApiCall(data) {
  const response = await nonRoutineAxios({
    method: 'post',
    url: '/pdf',
    data,
  });
  return response.data;
}

async function getServicePackagesApiCall() {
  const response = await nonRoutineAxios({
    method: 'get',
    url: '/servicepackage/list',
  });
  return response.data;
}

async function addServicePackageApiCall(data) {
  const response = await nonRoutineAxios({
    method: 'post',
    url: '/servicepackage/add',
    data,
  });
  return response.data;
}

async function getTreeRootOptionsApiCall(SPRootComponentID) {
  const response = await nonRoutineAxios({
    method: 'get',
    url: `/dropdowns/root/${SPRootComponentID}`,
  });
  return response.data;
}

async function getTreeChildOptionsApiCall(ParentID) {
  const response = await nonRoutineAxios({
    method: 'get',
    url: `/dropdowns/child/${ParentID}`,
  });
  return response.data;
}

async function getTreeServiceProfileOptionsApiCall(RMPTreeID) {
  const response = await nonRoutineAxios({
    method: 'get',
    url: `/dropdowns/serviceprofile/${RMPTreeID}`,
  });
  return response.data;
}

async function addServiceProfileApiCall(data) {
  const response = await nonRoutineAxios({
    method: 'post',
    url: '/serviceprofile/add',
    data,
  });
  return response.data;
}

export {
  nonRoutineAxios,
  getNonRoutineDropdownsApiCall,
  addNonroutineTaskApiCall,
  createNonroutineTaskPdfApiCall,
  getServicePackagesApiCall,
  addServicePackageApiCall,
  addServiceProfileApiCall,
  getTreeRootOptionsApiCall,
  getTreeChildOptionsApiCall,
  getTreeServiceProfileOptionsApiCall,
  uploadImageToNonRoutineTask,
};
