import { useStoreActions, useStoreState } from "easy-peasy";
import Button from "../../presentational/Button";

function ShowSyncAppModalButton({ children, ...props }) {
  const { showSyncAppModalAction } = useStoreActions((actions) => actions);
  return (
    <Button onPress={() => showSyncAppModalAction(true)} {...props}>
      {children || "Sync Now"}
    </Button>
  );
}

export default ShowSyncAppModalButton;
