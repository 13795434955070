import { View } from "react-native";
import Row from "../../presentational/Row";
import Col from "../../presentational/Col";
import { useStoreActions, useStoreState } from "easy-peasy";
import Message from "../../presentational/Message";
import Center from "../../presentational/Center";
import Text from "../../presentational/Text";
import Button from "../Button";
import Input from "../../presentational/Input";
import Option from "../../presentational/Option";

function AddServiceTaskForm(props) {
  const {
    status: { hasWifi },
    nonRoutine: {
      serviceType,
      chosenService,
      servicePackageList,
      rootServiceProfileOptions,
      chosenRootServiceProfileOption,
      treeChildServiceProfileDropdowns,
      chosenTreeChildServiceProfileOptions,
      serviceProfileList,
    },
    employee: { scannedEmployee },
  } = useStoreState((state) => state);

  const {
    getRootServiceProfileOptionsThunk,
    getServicePackageListThunk,
    setChosenServiceAction,
    chooseRootServiceProfileOptionThunk,
    chooseChildServiceProfileOptionThunk,
  } = useStoreActions((actions) => actions);

  return hasWifi ? (
    <View {...props}>
      <Row>
        <Col xs={12} md={6}>
          <Input.Select
            label={
              <Center>
                <Text>Type</Text>
              </Center>
            }
            value={serviceType}
            inputStyle={{ backgroundColor: "#ffffff" }}
            onChange={({ type }) =>
              type === "Service Packages"
                ? getServicePackageListThunk()
                : getRootServiceProfileOptionsThunk()
            }
            options={scannedEmployee.addTaskTypes.map((type) => ({
              type,
              children: <Option>{type}</Option>,
            }))}
          />
        </Col>

        <Col xs={12} md={6}>
          <Center>
            <Text>Service Profiles / Packages</Text>
          </Center>

          {serviceType === "Service Packages" ? (
            <Input.Select
              value={chosenService ? chosenService.ServicePackageCD : ""}
              onChange={(service) => setChosenServiceAction(service)}
              inputStyle={{ backgroundColor: "#ffffff" }}
              options={servicePackageList.map((option) => ({
                ...option,
                children: <Option>{option.ServicePackageCD}</Option>,
              }))}
            />
          ) : (
            <>
              <Input.Select
                value={
                  chosenRootServiceProfileOption.ComponentDescription
                    ? chosenRootServiceProfileOption.ComponentDescription.value
                    : ""
                }
                inputStyle={{ backgroundColor: "#ffffff" }}
                onChange={(option) =>
                  chooseRootServiceProfileOptionThunk(option)
                }
                options={rootServiceProfileOptions.map((option) => ({
                  ...option,
                  children: (
                    <Option>{option.ComponentDescription.value}</Option>
                  ),
                }))}
              />

              {treeChildServiceProfileDropdowns.map((options, index) => (
                <Input.Select
                  onChange={(chosenOption) =>
                    chooseChildServiceProfileOptionThunk({
                      index,
                      chosenOption,
                    })
                  }
                  value={
                    chosenTreeChildServiceProfileOptions[index] &&
                    chosenTreeChildServiceProfileOptions[index]
                      .ComponentDescription
                      ? chosenTreeChildServiceProfileOptions[index]
                          .ComponentDescription.value
                      : ""
                  }
                  inputStyle={{ backgroundColor: "#ffffff" }}
                  options={options.map((option) => ({
                    ...option,
                    children: (
                      <Option>{option.ComponentDescription.value}</Option>
                    ),
                  }))}
                />
              ))}

              {serviceProfileList.length > 0 && (
                <Input.Select
                  value={
                    chosenService && chosenService.Description
                      ? chosenService.Description.value
                      : ""
                  }
                  inputStyle={{ backgroundColor: "#ffffff" }}
                  onChange={(option) => setChosenServiceAction(option)}
                  options={serviceProfileList.map((option) => ({
                    ...option,
                    children: <Option>{option.Description.value}</Option>,
                  }))}
                />
              )}
            </>
          )}
        </Col>
      </Row>

      <Button.AddServiceTask />
    </View>
  ) : (
    <Message.Warning>
      <Center>
        <Text.Warning>You must be online</Text.Warning>
      </Center>
    </Message.Warning>
  );
}

export default AddServiceTaskForm;
