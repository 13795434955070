import Input from "../../presentational/Input";
import { useStoreActions, useStoreState } from "easy-peasy";
import DatePicker from "../../presentational/DatePicker";
import moment from "moment";
import { remToDp, useScreenDimensions } from "../../../utilities/responsive";
import Text from "../../presentational/Text";
import { View } from "react-native";
import Gap from "../../presentational/Gap";
import Message from "../../presentational/Message";
import styled from "styled-components/native";
import Center from "../../presentational/Center";
import Option from "../../presentational/Option";

const WarningMessage = styled(Message.Warning)`
  margin-bottom: ${remToDp(1)}px;
`;

const MainContentContainer = styled.View`
  width: 50%;
  justify-content: center;
  margin: auto;
  margin-top: ${remToDp(2)}px;
`;

function AddPartRequestForm(props) {
  const { vhToDp } = useScreenDimensions();

  const {
    nonRoutine: { priorityLevels },
    part: {
      partQuantityInput: { Qty, UsrPriorityDescription, UsrNeedBy, note },
      partRequestList,
      chosenStockItem,
      showAddStockItemRequestModal,
      showAddPartRequestModal,
    },
  } = useStoreState((state) => state);

  const { setPartQuantityInputAction } = useStoreActions((actions) => actions);

  const notSimpleItemRequest =
    showAddStockItemRequestModal ||
    (showAddPartRequestModal && !chosenStockItem.SimpleItemRequest);

  return (
    <View {...props}>
      {((showAddPartRequestModal && chosenStockItem) ||
        showAddStockItemRequestModal) && (
        <>
          {showAddPartRequestModal &&
            partRequestList.some(
              (pr) => pr.InventoryCD === chosenStockItem.InventoryCD.trim()
            ) && (
              <WarningMessage>Warning: Part Exists on this Task</WarningMessage>
            )}

          {!showAddStockItemRequestModal && (
            <Center>
              <Text.Primary>
                How many {chosenStockItem.InventoryCD}'s needed (Unit of Measure
                = {chosenStockItem.BaseUnit})
              </Text.Primary>
            </Center>
          )}

          <MainContentContainer>
            <Input
              value={`${Qty}`}
              placeholder="Quantity (required)"
              onChangeText={(Qty) =>
                setPartQuantityInputAction({
                  Qty: Qty.replace(/[^0-9]/g, ""),
                })
              }
            />

            {notSimpleItemRequest && (
              <Input.Select
                header="Select Priority Level"
                value={UsrPriorityDescription}
                placeholder="Select Priority Level"
                options={priorityLevels.map((pl) => ({
                  ...pl,
                  children: <Option>{pl.Description}</Option>,
                }))}
                onChange={(option) =>
                  setPartQuantityInputAction({
                    UsrPriorityLevel: option.PriorityLevel,
                    UsrPriorityDescription: option.Description,
                  })
                }
              />
            )}

            {notSimpleItemRequest && (
              <>
                <Gap />
                <DatePicker
                  value={UsrNeedBy ? moment(UsrNeedBy).format("L") : ""}
                  onChange={(UsrNeedBy) =>
                    setPartQuantityInputAction({ UsrNeedBy })
                  }
                  placeholder="Need By Date"
                />
              </>
            )}
          </MainContentContainer>

          <Gap />

          {!showAddStockItemRequestModal && (
            <Input.TextArea
              placeholder="Notes (optional)"
              value={note}
              onChangeText={(note) => setPartQuantityInputAction({ note })}
              inputStyle={{ height: vhToDp(8) }}
            />
          )}
        </>
      )}
    </View>
  );
}

export default AddPartRequestForm;
