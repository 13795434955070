import { useStoreActions } from "easy-peasy";
import Icon from "../../presentational/Icon";
import { Camera } from "expo-camera";

function ShowScanBadgeBarcodeModalIcon(props) {
  const { showScanBadgeBarcodeModalAction } = useStoreActions(
    (actions) => actions
  );
  return (
    <Icon.Barcode
      onPress={() => showScanBadgeBarcodeModalAction(true)}
      {...props}
    />
  );
}

export default ShowScanBadgeBarcodeModalIcon;
