import React from "react";
import styled from "styled-components/native";
import { remToDp } from "../../utilities/responsive";

const SText = styled.Text`
  font-size: ${remToDp(1)}px;
  text-decoration: ${(props) => (props.underline ? "underline" : "none")};
  font-weight: ${(props) => (props.bold ? "bold" : "normal")};
`;

function Text({ children, type, ...props }) {
  const types = {
    success: "#0a3622",
    danger: "#58151c",
    warning: "#664d03",
    info: "#055160",
    default: "#000000", // black
  };

  const parentStyle = [
    {
      color: types[type || "default"],
    },
    props.style,
  ];

  return (
    <SText style={parentStyle} {...props}>
      {React.Children.map(children, (child) =>
        React.isValidElement(child)
          ? React.cloneElement(child, {
              ...props,
              ...child.props,
              style: [...parentStyle, child.props.style],
            })
          : child
      )}
    </SText>
  );
}

export default Text;
