import Col from "../../presentational/Col";
import Row from "../../presentational/Row";
import Logo from "../Logo";
import styled from "styled-components/native";
import QueueLockTimer from "../QueueLockTimer";
import { useStoreActions, useStoreState } from "easy-peasy";
import { remToDp } from "../../../utilities/responsive";
import Text from "../../presentational/Text";
import { Dimensions, Platform, View } from "react-native";
import Icon from "../Icon";
import { useEffect } from "react";
import Center from "../../presentational/Center";

const verticalPadding = remToDp(0.7);
const logoHeight = 75;

const Container = styled(Row)`
  align-items: center;
  border-bottom-width: 1px;
  border-bottom-color: #2c6892;
  padding: ${verticalPadding}px ${remToDp(2)}px;
`;

const SLogo = styled(Logo)`
  height: ${logoHeight}px;
  width: ${(props) => props.width}px;
`;

const SRow = styled(Row)`
  align-items: center;
  flex-wrap: nowrap;
`;
SRow.Kiosk = styled(SRow)`
  justify-content: space-between;
`;

const Welcome = styled.View`
  margin-right: 20px;
`;
Welcome.Text = styled(Text)`
  font-size: ${remToDp(1)}px;
  margin-bottom: 8px;
`;
Welcome.Name = styled(Text)`
  font-size: ${remToDp(1.5)}px;
  color: #${(props) => (props.mechanicQualified ? "00ad7e" : "dc3545")};
  font-weight: 600;
`;

function LeftComponent() {
  const {
    employee: {
      scannedEmployee: { EmployeeID, showSidePanel },
    },
    layout: { showSidebarAsDrawer },
    auth: { user },
  } = useStoreState((state) => state);

  return (
    <SRow>
      {Boolean(EmployeeID) === true &&
        Boolean(showSidebarAsDrawer) === true &&
        Boolean(showSidePanel) === true && <Icon.ShowSidebar />}
      <SLogo resizeMode="contain" width={user.email ? 275 : 0} />
    </SRow>
  );
}

function QueueLockComponent() {
  const { isLocked, isCountDownActive } = useStoreState(
    (state) => state.task.queue
  );

  return (isLocked || isCountDownActive) && <QueueLockTimer />;
}

function WelcomeComponent() {
  const {
    scannedEmployee: { FirstName, LastName, EmployeeID },
    qualifications: { validities, mechanicQualified },
  } = useStoreState((state) => state.employee);

  return (
    EmployeeID && (
      <Welcome>
        <Welcome.Text>Welcome</Welcome.Text>
        <Welcome.Name mechanicQualified={validities && mechanicQualified}>
          {FirstName} {LastName}
        </Welcome.Name>
      </Welcome>
    )
  );
}

function ProtectedTopNavbar(props) {
  const {
    employee: {
      scannedEmployee: { showSidePanel, EmployeeID },
    },
    layout: { showSidebarAsDrawer },
  } = useStoreState((state) => state);

  const { setTopNavbarHeightAction, showSidebarAsDrawerAction } =
    useStoreActions((actions) => actions);

  useEffect(() => {
    if (Platform.OS === "web") {
      const subscription = Dimensions.addEventListener(
        "change",
        ({ window, screen }) => showSidebarAsDrawerAction(window.width <= 1200)
      );
      return () => subscription?.remove();
    } else {
      showSidebarAsDrawerAction(true);
    }
  }, [Platform.OS]);

  let components = [
    <Col xs={12} md={4}>
      <Center>
        <LeftComponent />
      </Center>
    </Col>,
  ];
  if (EmployeeID) {
    if (
      (Boolean(showSidePanel) === true &&
        Boolean(showSidebarAsDrawer) === true) ||
      Boolean(showSidePanel) === false
    ) {
      components = [
        <LeftComponent />,
        <QueueLockComponent />,
        <WelcomeComponent />,
      ].map((children, index) => (
        <Col xs={12} md={4} key={index}>
          <Center>{children}</Center>
        </Col>
      ));
    } else {
      components = [
        <LeftComponent />,
        <SRow.Kiosk>
          <View />

          <QueueLockComponent />

          <WelcomeComponent />
        </SRow.Kiosk>,
      ].map((children, index) => (
        <Col xs={12} sm={6} key={index}>
          {children}
        </Col>
      ));
    }
  }

  return (
    <Container
      onLayout={(event) =>
        setTopNavbarHeightAction(
          event.nativeEvent.layout.height || verticalPadding + logoHeight
        )
      }
      {...props}
    >
      {components}
    </Container>
  );
}

export default ProtectedTopNavbar;
