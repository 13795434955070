import { Platform } from 'react-native';
import Modal from '../../presentational/Modal';
import { Camera, CameraType } from 'expo-camera';
import styled from 'styled-components/native';
import { createRef, useState, useEffect } from 'react';
import Button from '../Button';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useScreenDimensions } from '../../../utilities/responsive';
import Message from '../../presentational/Message';
import WebFacialScan from '../Camera/WebFacialScan';

const ModalBody = styled(Modal.Body)`
  background-color: ${(props) => props.backgroundColor};
  height: ${(props) => props.height}px;
`;

const SCamera = styled(Camera)`
  flex: 1;
  margin-bottom: 10px;
`;

function useOnClose() {
  const { showFaceScanModalAction } = useStoreActions((actions) => actions);

  return () => showFaceScanModalAction(false);
}

function FaceScanModal(props) {
  const { vhToDp } = useScreenDimensions();

  const cameraRef = createRef();

  const { showFaceScanModal, isRecognized, isNotRecognized } = useStoreState(
    (state) => state.facialRecog
  );

  const onClose = useOnClose();

  const [cameraIsReady, setCameraIsReady] = useState(false);

  let backgroundColor = '#ffffff';
  if (isRecognized && !isNotRecognized) {
    backgroundColor = '#198754';
  } else if (!isRecognized && isNotRecognized) {
    backgroundColor = '#dc3545';
  }

  return (
    <Modal visible={showFaceScanModal} onRequestClose={onClose} {...props}>
      <Modal.Header onRequestClose={onClose} />

      <ModalBody backgroundColor={backgroundColor} height={vhToDp(50)}>
        {Platform.OS === 'web' && <WebFacialScan></WebFacialScan>}
        {Platform.OS !== 'web' && !cameraIsReady && <Message.AllowCameraUse />}
        {Platform.OS !== 'web' && (
          <SCamera
            ref={cameraRef}
            type={CameraType.front}
            onCameraReady={async () => {
              const { granted } = await Camera.getCameraPermissionsAsync();
              setCameraIsReady(granted);
            }}
          />
        )}
        {Platform.OS !== 'web' && cameraIsReady && (
          <Button.ScanFace cameraRef={cameraRef} />
        )}
      </ModalBody>
    </Modal>
  );
}

export default FaceScanModal;
