import { useStoreActions, useStoreState } from "easy-peasy";
import Button from "../../presentational/Button";
import { useNavigation } from "@react-navigation/native";

function SyncAppButton({ children, ...props }) {
  const navigation = useNavigation();
  const { loading, hasWifi } = useStoreState((state) => state.status);
  const { syncAppThunk } = useStoreActions((actions) => actions);
  return (
    <Button
      disabled={loading || !hasWifi}
      onPress={() => syncAppThunk(navigation)}
      {...props}
    >
      {!hasWifi && "Must connect to wifi to sync"}
      {loading && "Syncing..."}
      {hasWifi && !loading && (children || "Sync App")}
    </Button>
  );
}

export default SyncAppButton;
