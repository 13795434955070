import { Center, Input, Row } from "@cfbs/cfbsstrap-native";
import Text from "../../presentational/Text";
import styled from "styled-components/native";
import { remToDp } from "../../../utilities/responsive";
import { useEditSubtypeForm } from "../../../hooks/boat";
import Gap from "../../presentational/Gap";
import Button from "../../presentational/Button";
import Icon from "../Icon";
import Content from "../Content";

const SubtypeValue = styled(Text.Error)`
  font-size: ${remToDp(1.5)};
`;

const BackButtonRow = styled(Row)`
  align-items: center;
`;

function EditSubtypeForm(props) {
  const { isLoading, chosenSubtype, onBackToSubtypeList, ...inputProps } =
    useEditSubtypeForm();

  return (
    <Content loading={isLoading} {...props}>
      <Button type="transparent" onPress={onBackToSubtypeList}>
        <BackButtonRow>
          <Icon type="back" />
          <Text> Subtype List</Text>
        </BackButtonRow>
      </Button>

      <Gap />

      <Center>
        <Text>
          Sub Type ID: <SubtypeValue>{chosenSubtype.SubTypeID}</SubtypeValue> /
          Attribute: <SubtypeValue>{chosenSubtype.AttributeCD}</SubtypeValue>
        </Text>
      </Center>

      <Gap />

      <Input {...inputProps} />

      <Gap />

      <Center>
        <Button onPress={inputProps.onSubmitEditing}>
          Edit / Add Subtype Attribute Value
        </Button>
      </Center>
    </Content>
  );
}

export default EditSubtypeForm;
