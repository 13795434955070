import { useStoreActions, useStoreState } from "easy-peasy";
import SearchBar from "../../presentational/SearchBar";
import Icon from "../../presentational/Icon";

function PartSearchBar(props) {
  const {
    part: { searchInput },
    employee: { scannedEmployee },
  } = useStoreState((state) => state);
  const { setPartSearchInputAction, searchPartsThunk } = useStoreActions(
    (actions) => actions
  );

  return (
    <SearchBar
      value={searchInput}
      placeholder={`Search by ${scannedEmployee.customLabels.partRequest.SearchPlaceholder}`}
      searchIcon={<Icon.Search onPress={() => searchPartsThunk()} />}
      clearIcon={<Icon.Clear onPress={() => setPartSearchInputAction("")} />}
      onChangeText={(input) => setPartSearchInputAction(input)}
      onSubmitEditing={() => searchPartsThunk()}
      {...props}
    />
  );
}

export default PartSearchBar;
