import styled from "styled-components/native";
import Text from "./Text";
import { remToDp } from "../../../utilities/responsive";

const NotFoundText = styled(Text)`
  font-size: ${remToDp(2)}px;
  border-width: 1px;
  border-color: #ccc;
  border-style: dotted;
  padding: ${remToDp(1)}px;
  text-align: center;
`;

export default NotFoundText;
