import { Buffer } from "buffer";

const PDF_FILE_TYPE = "application/pdf";

// Source for opening pdf file: https://medium.com/@storrisi/how-to-show-a-pdf-stream-on-a-react-client-without-any-library-36220fee60cb
function convertBase64ToBlob(base64String, type) {
  const byteCharacters = Buffer.from(base64String, "base64").toString("binary");
  const byteArray = new Uint8Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteArray[i] = byteCharacters.charCodeAt(i);
  }

  return new Blob([byteArray], { type });
}

function openBase64FileOnWeb(base64, type) {
  const file = convertBase64ToBlob(base64, type || PDF_FILE_TYPE);
  const fileURL = URL.createObjectURL(file);
  window.open(fileURL);
  return fileURL;
}

function openAndDownloadBase64FileOnWeb(base64, type, filename) {
  const linkElem = document.createElement("a");
  linkElem.href = openBase64FileOnWeb(base64, type || PDF_FILE_TYPE);
  if (filename) {
    linkElem.download = filename;
    linkElem.click();
  }
}

export { openBase64FileOnWeb, openAndDownloadBase64FileOnWeb };
