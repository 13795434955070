import { FontAwesome } from "@expo/vector-icons";
import Icon from "../../presentational/Icon";
import { useNavigation } from "@react-navigation/native";
import { useStoreActions, useStoreState } from "easy-peasy";
import { PAGE_NONROUTINE } from "../../../utilities/variables";

function GoToNonRoutinePageIcon(props) {
  const {
    task: { currentTask },
    employee: { scannedEmployee },
  } = useStoreState((state) => state);

  const { setNonroutineInputAction, getRootServiceProfileOptionsThunk } =
    useStoreActions((actions) => actions);

  const navigation = useNavigation();
  return (
    <Icon.WithText
      icon={<Icon.Yellow Component={FontAwesome} name="exclamation-triangle" />}
      text={scannedEmployee.customLabels.nonRoutine.Title}
      onPress={() => {
        scannedEmployee.notAviation
          ? getRootServiceProfileOptionsThunk()
          : setNonroutineInputAction({
              ATAChapterID: currentTask.ATAChapter
                ? currentTask.ATAChapter.value
                : "",
              ATASubChapterID: currentTask.ATASubChapter
                ? currentTask.ATASubChapter.value
                : "",
              ZoneID: currentTask.UsrZoneID ? currentTask.UsrZoneID.value : "",
              STSZone:
                scannedEmployee.stsZone && currentTask.STSZone
                  ? currentTask.STSZone.value
                  : "",
            });
        navigation.navigate(PAGE_NONROUTINE);
      }}
      {...props}
    />
  );
}

export default GoToNonRoutinePageIcon;
