import { useStoreActions, useStoreState } from "easy-peasy";
import Button from "../../presentational/Button";

function AddNonroutineTaskButton(props) {
  const {
    employee: {
      scannedEmployee: {
        customLabels: { nonRoutine },
        doubleInspectionConfirmationWindow,
      },
    },
    nonRoutine: {
      nonroutineInput: { RII, CriticalTask },
    },
  } = useStoreState((state) => state);

  const {
    addNonroutineTaskThunk,
    setShowDuplicateInspectionConfirmationModalAction,
  } = useStoreActions((actions) => actions);

  return (
    <Button
      fullWidth
      color="#2c6892"
      onPress={() =>
        doubleInspectionConfirmationWindow && (RII || CriticalTask)
          ? setShowDuplicateInspectionConfirmationModalAction(true)
          : addNonroutineTaskThunk()
      }
      {...props}
    >
      {nonRoutine.Button}
    </Button>
  );
}

export default AddNonroutineTaskButton;
