import styled from "styled-components/native";
import Row from "../../presentational/Row";
import Col from "../../presentational/Col";
import Text from "../../presentational/Text";
import { remToDp } from "../../../utilities/responsive";
import IconC from "../../containers/Icon";
import Icon from "../../presentational/Icon";
import Button from "../../presentational/Button";
import { Center } from "@cfbs/cfbsstrap-native";

const Container = styled.Pressable`
  background-color: #f4f4f4;
  border-color: #ccc;
  border-width: 1px;
  padding: 8px;
`;

const SCol = styled(Col)`
  flex-direction: row;
  justify-content: ${(props) => props.justifyContent || "flex-start"};
`;

const SText = styled(Text)`
  font-size: ${remToDp(1.5)}px;
`;
SText.Main = styled(Text.Primary)`
  font-size: ${remToDp(1.5)}px;
  font-weight: 600;
  color: #014daf;
`;
SText.ProjectCode = styled(Text)`
  font-size: ${remToDp(0.75)}px;
`;
SText.Status = styled(Text)`
  margin: 8px 0;
`;
SText.Description = styled(Text)`
  font-size: ${remToDp(0.75)}px;
  font-style: italic;
`;

function ProjectTask({
  ProjectCD,
  TaskCD,
  ProjectTaskDescription,
  Status,
  isCheckedOut,
  qualified,
  taskLimitReached,
  onPressDetails,
  ...props
}) {
  return (
    <Container {...props}>
      <SText.ProjectCode>{ProjectCD}</SText.ProjectCode>
      <Row>
        <Col xs={12} md={onPressDetails ? 4 : 10}>
          <SText.Main>{TaskCD}</SText.Main>

          <SText.Status>
            Status: <Text.TaskStatus status={Status}>{Status}</Text.TaskStatus>
          </SText.Status>

          <SText.Description>{ProjectTaskDescription}</SText.Description>
        </Col>

        {onPressDetails && (
          <SCol justifyContent="center" xs={12} md={4}>
            <Center>
              <Button onPress={onPressDetails}>Details</Button>
            </Center>
          </SCol>
        )}

        <SCol justifyContent="flex-end" xs={12} md={onPressDetails ? 4 : 2}>
          {taskLimitReached ? (
            <Icon.WithText
              icon={<Icon.Ban />}
              text={
                <SText.Description>Open Task Limit Reached</SText.Description>
              }
            />
          ) : (
            <>
              {!isCheckedOut && qualified && (
                <IconC.AddProjectTask TaskCD={TaskCD} />
              )}

              {isCheckedOut && (
                <Icon.WithText
                  icon={<Icon.CheckCircle size={remToDp(3.5)} />}
                  text={<SText.Description>Checked Out</SText.Description>}
                />
              )}

              {!qualified && (
                <Icon.WithText
                  icon={<Icon.Ban size={remToDp(3.5)} />}
                  text={<SText.Description>Not Qualified</SText.Description>}
                />
              )}
            </>
          )}
        </SCol>
      </Row>
    </Container>
  );
}

export default ProjectTask;
