import AddPartRequestForm from "./AddPartRequestForm";
import AddUnserviceablePartRequestForm from "./AddUnserviceablePartRequestForm";
import Form from "./Form";
import AddNonroutineTaskForm from "./AddNonroutineTaskForm";
import AddServiceTaskForm from "./AddServiceTaskForm";
import AddUnserviceableStockItemForm from "./AddUnserviceableStockItemForm";
import AddStockItemRequestForm from "./AddStockItemRequestForm";

Form.AddUnserviceablePartRequest = AddUnserviceablePartRequestForm;
Form.AddPartRequest = AddPartRequestForm;
Form.AddUnserviceableStockItem = AddUnserviceableStockItemForm;
Form.AddStockItemRequest = AddStockItemRequestForm;

Form.AddNonroutineTask = AddNonroutineTaskForm;
Form.AddServiceTask = AddServiceTaskForm;

export default Form;
