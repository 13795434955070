import Environment from "./Environment";
import EnvironmentHeader from "./EnvironmentHeader";
import EnvironmentItem from "./EnvironmentItem";
import EnvironmentList from "./EnvironmentList";
import EnvironmentText from "./EnvironmentText";

Environment.Header = EnvironmentHeader;
Environment.Item = EnvironmentItem;
Environment.Text = EnvironmentText;
Environment.List = EnvironmentList;

export default Environment;
