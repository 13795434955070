import Nav from "./Nav";
import NonroutineNav from "./NonroutineNav";
import PartsRequestNav from "./PartsRequestNav";
import TaskNav from "./TaskNav";

Nav.Task = TaskNav;
Nav.PartsRequest = PartsRequestNav;
Nav.Nonroutine = NonroutineNav;

export default Nav;
