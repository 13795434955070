import { useStoreState } from "easy-peasy";
import Nav from "./Nav";
import Icon from "../Icon";

function PartsRequestNav(props) {
  const {
    task: {
      currentTask: { CreateNRFromNR },
    },
    employee: {
      scannedEmployee: { hideNonRoutine },
    },
  } = useStoreState((state) => state);

  return (
    <Nav
      buttons={[
        {
          children: <Icon.GoToTaskHomePage />,
          show: true,
        },
        {
          children: <Icon.GoToNonRoutinePage />,
          show: !hideNonRoutine && CreateNRFromNR.value === "YES",
        },
      ]}
      {...props}
    />
  );
}

export default PartsRequestNav;
