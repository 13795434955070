import { useStoreActions } from "easy-peasy";
import Icon from "../../presentational/Icon";
import styled from "styled-components/native";
import { remToDp } from "../../../utilities/responsive";
import Text from "../../presentational/Text";

const SText = styled(Text)`
  font-style: italic;
  font-size: ${remToDp(0.75)}px;
`;

function AddProjectTaskIcon({ TaskCD, ...props }) {
  const { scanTaskThunk } = useStoreActions((actions) => actions);
  return (
    <Icon.WithText
      icon={<Icon.Plus size={remToDp(3.5)} />}
      text={<SText>Add to My TaskList</SText>}
      onPress={() => scanTaskThunk(TaskCD)}
      {...props}
    />
  );
}

export default AddProjectTaskIcon;
