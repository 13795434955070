const defaultState = {
  loading: false,
  alerts: [],
  showSyncAppModal: false,
  pendingOperations: [],
  showSyncAppAlert: false,
  hasWifi: true,
  syncFailed: false,
};

const state = {
  status: defaultState,
};

export { state as default, defaultState };
