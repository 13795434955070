import AddProjectImagesModal from "./AddProjectImagesModal";
import BoatDetailsModal from "./BoatDetailsModal";
import ConfirmDuplicateInspectionModal from "./ConfirmDuplicateInspectionModal";
import Modal from "./Modal";
import ProjectTaskInformationModal from "./ProjectTaskInformationModal";
import SearchTasksServerSideModal from "./SearchTasksServerSideModal";

Modal.BoatDetails = BoatDetailsModal;
Modal.SearchTasksServerSide = SearchTasksServerSideModal;
Modal.AddProjectImages = AddProjectImagesModal;
Modal.ProjectTaskInformation = ProjectTaskInformationModal;
Modal.ConfirmDuplicateInspection = ConfirmDuplicateInspectionModal;

export default Modal;
