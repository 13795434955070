function qualSplit(quals) {
  function splitQual(q) {
    let qualString = q;

    const engine = qualString.substring(
      q.lastIndexOf("("),
      q.lastIndexOf(")") + 1
    );

    qualString = q.replace(engine, "").trim();

    const make = qualString.split(" ")[0];
    const model = qualString.replace(make, "").replace(engine, "").trim();

    return { make, model, engine };
  }

  return Array.isArray(quals)
    ? quals.reduce((arrayResult, qual) => {
        qual.qualificationExternalId === "TYPE" &&
          arrayResult.push(splitQual(qualificationName));
        return arrayResult;
      }, [])
    : splitQual(quals);
}

module.exports = { qualSplit };
