import styled from "styled-components/native";
import Input from "../Input";
import Center from "../../presentational/Center";
import Gap from "../../presentational/Gap";
import Text from "../../presentational/Text";
import React, { useEffect } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import { remToDp, useScreenDimensions } from "../../../utilities/responsive";
import Button from "../Button";
import Logo from "../Logo";
import Modal from "../Modal";
import Icon from "../../presentational/Icon";
import { useIsFocused, useNavigation } from "@react-navigation/native";
import { PAGE_HOME } from "../../../utilities/variables";
import { Platform } from "react-native";

const Header = styled(Text)`
  font-size: ${remToDp(2.5)}px;
  text-align: center;
  color: #333;
  font-weight: 500;
`;

const FieldLabel = styled(Text)`
  font-size: ${remToDp(1.75)}px;
  font-weight: 500;
  margin-bottom: ${remToDp(0.5)}px;
  color: #6c757d;
`;

const SLogo = styled(Logo)`
  height: ${(props) => props.height}px;
`;
SLogo.Container = styled.View`
  padding: ${remToDp(4)}px ${remToDp(2)}px;
  background-color: #e9ecef;
  margin-bottom: ${remToDp(2)}px;
  width: 50%;
`;

function BadgePage(props) {
  const { vhToDp } = useScreenDimensions();

  const isFocused = useIsFocused();

  const navigation = useNavigation();

  const {
    auth: { user },
    employee: { isTest },
  } = useStoreState((state) => state);

  const { loginAcmThunk } = useStoreActions((actions) => actions);

  useEffect(() => {
    !user.logo && loginAcmThunk();
  }, [user.logo]);

  useEffect(() => {
    isFocused && navigation.navigate(PAGE_HOME);
  }, [isFocused]);

  return (
    <Center {...props}>
      <SLogo.Container>
        <SLogo height={vhToDp(15)} />
      </SLogo.Container>
      <Header>ProMRO Sign In</Header>
      <Gap />
      <Gap />
      <Icon.CreditCard />
      <Gap />
      <Gap />
      <FieldLabel>Scan badge or enter ID to Sign-in</FieldLabel>
      <Gap />
      <Gap />
      <Input.Badge />
      {Platform.OS === "web" && Boolean(isTest) === true && (
        <>
          <Gap />
          <Button.SelectEnvironment />
        </>
      )}
      <Modal.ScanBadgeBarcode />
    </Center>
  );
}

export default BadgePage;
