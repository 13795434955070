import { useStoreActions, useStoreState } from "easy-peasy";
import SearchBar from "../../presentational/SearchBar";
import Icon from "../../presentational/Icon";

function LaborItemSearchBar(props) {
  const { laborItemSearchInput } = useStoreState((state) => state.nonRoutine);

  const { setLaborItemSearchInputAction } = useStoreActions(
    (actions) => actions
  );

  return (
    <SearchBar
      value={laborItemSearchInput}
      onChangeText={(input) => setLaborItemSearchInputAction(input)}
      clearIcon={
        <Icon.Clear onPress={() => setLaborItemSearchInputAction("")} />
      }
      placeholder="Filter Labor Items"
      {...props}
    />
  );
}

export default LaborItemSearchBar;
