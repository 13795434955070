import { useStoreActions } from "easy-peasy";
import Button from "../../presentational/Button";

function SyncFailureUnderstoodButton({ children, ...props }) {
  const {
    showSyncAppModalAction,
    setAllPendingOperationsAsFailedAction,
    reloadAppThunk,
    showSyncAppAlertAction,
  } = useStoreActions((actions) => actions);

  return (
    <Button
      onPress={() => {
        setAllPendingOperationsAsFailedAction();
        reloadAppThunk();
        showSyncAppAlertAction(false);
        showSyncAppModalAction(false);
      }}
      {...props}
    >
      {children || "I Understand"}
    </Button>
  );
}

export default SyncFailureUnderstoodButton;
