import { useEffect, useState } from "react";
import { Dimensions, PixelRatio } from "react-native";

function useScreenDimensions() {
  const [dimensions, setDimensions] = useState(Dimensions.get("window"));

  useEffect(() => {
    function handleOrientationChange(event) {
      setDimensions(event.window);
    }

    const dimensionsListener = Dimensions.addEventListener(
      "change",
      handleOrientationChange
    );

    return () => {
      dimensionsListener.remove();
    };
  }, []);

  function vwToDp(vwValue) {
    const dpValue = (vwValue * dimensions.width) / 100;
    return PixelRatio.roundToNearestPixel(dpValue);
  }

  function vhToDp(vhValue) {
    const dpValue = (vhValue * dimensions.height) / 100;
    return PixelRatio.roundToNearestPixel(dpValue);
  }

  return { dimensions, vwToDp, vhToDp };
}

function pxToDp(pxValue) {
  return PixelRatio.roundToNearestPixel(pxValue / PixelRatio.get());
}

function remToDp(remValue, baseFontSize = 16) {
  const dpValue = remValue * PixelRatio.getFontScale() * baseFontSize;
  return PixelRatio.roundToNearestPixel(dpValue);
}

export { useScreenDimensions, pxToDp, remToDp };
