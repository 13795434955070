import { axios } from ".";

const centrikAxios = axios.create({
  baseURL: `${process.env.EXPO_PUBLIC_BACKEND_BASE_URL}/api/v1/centrik`,
});

async function getValiditiesApiCall(email) {
  const response = await centrikAxios({
    method: "get",
    url: `/${email}`,
  });
  return response.data;
}

export { centrikAxios, getValiditiesApiCall };
