import { useStoreActions } from "easy-peasy";
import Icon from "../../presentational/Icon";
import { useNavigation } from "@react-navigation/native";
import { FontAwesome } from "@expo/vector-icons";
import {
  OPERATION_MARK_COMPLETE_NAME,
  PAGE_TASK,
} from "../../../utilities/variables";

function MarkCompleteIcon({ isOnTaskHomepage, ...props }) {
  const navigation = useNavigation();
  const { markTaskCompleteThunk } = useStoreActions((actions) => actions);
  return (
    <Icon.WithText
      icon={<Icon.Green Component={FontAwesome} name="check" />}
      text={OPERATION_MARK_COMPLETE_NAME}
      onPress={() =>
        isOnTaskHomepage
          ? markTaskCompleteThunk(navigation)
          : navigation.navigate(PAGE_TASK)
      }
      {...props}
    />
  );
}

export default MarkCompleteIcon;
