import { Platform } from "react-native";
import * as SecureStore from "expo-secure-store";
import AsyncStorage from "@react-native-async-storage/async-storage";

const webPlatform = "web";

const Storage = {
  getItem: async (key) => {
    return JSON.parse(
      Platform.OS === "web"
        ? localStorage.getItem(key)
        : await AsyncStorage.getItem(key)
    );
  },
  setItem: (key, value) => {
    const stringifiedValue = JSON.stringify(value);
    Platform.OS === "web"
      ? localStorage.setItem(key, stringifiedValue)
      : AsyncStorage.setItem(key, stringifiedValue);
  },
  removeItem: (key) => {
    Platform.OS === "web"
      ? localStorage.removeItem(key)
      : AsyncStorage.removeItem(key);
  },
};

const SecureStorage = {
  getItem: async (key) => {
    return JSON.parse(
      Platform.OS === webPlatform
        ? localStorage.getItem(key)
        : await SecureStore.getItemAsync(key)
    );
  },
  setItem: (key, value) => {
    const stringifiedValue = JSON.stringify(value);
    Platform.OS === webPlatform
      ? localStorage.setItem(key, stringifiedValue)
      : SecureStore.setItemAsync(key, stringifiedValue);
  },
  removeItem: (key) => {
    Platform.OS === webPlatform
      ? localStorage.removeItem(key)
      : SecureStore.deleteItemAsync(key);
  },
};

export { Storage, SecureStorage };
