import { useStoreActions, useStoreState } from "easy-peasy";
import Button from "../../presentational/Button";

function ShowAddStockItemRequestModalButton({ children, ...props }) {
  const { PartNotFoundEnable } = useStoreState(
    (state) => state.employee.scannedEmployee
  );

  const {
    showAddStockItemRequestModalAction,
    showPartSearchResultsModalAction,
  } = useStoreActions((actions) => actions);

  return (
    <Button
      onPress={() => {
        showPartSearchResultsModalAction(false);
        showAddStockItemRequestModalAction(true);
      }}
      color="#dc3545"
      fullWidth
      disabled={!PartNotFoundEnable}
      {...props}
    >
      {PartNotFoundEnable ? "Part Not Found Item Request" : "Not Authorized"}
    </Button>
  );
}

export default ShowAddStockItemRequestModalButton;
