import { useStoreState } from "easy-peasy";
import { remToDp, useScreenDimensions } from "../../../utilities/responsive";
import Center from "../../presentational/Center";
import Text from "../../presentational/Text";
import Navbar from "../Navbar";
import ProtectedPage from "./ProtectedPage";
import styled from "styled-components/native";
import { getFirstOpenedTaskTime } from "../../../utilities/task";
import { useState } from "react";
import { Platform } from "react-native";
import Modal from "../Modal";
import { Col, Row } from "@cfbs/cfbsstrap-native";
import Button from "../Button";
import { OPERATION_TOGGLE_BILLABLE_TASK } from "../../../utilities/variables";

const ContentContainer = styled.ScrollView`
  max-width: ${(props) => props.maxWidth};
  width: 100%;
  height: ${(props) =>
    props.screenHeight - props.bottomNavbarHeight - props.topNavbarHeight}px;
`;
ContentContainer.Inner = styled.View`
  padding: ${(props) => props.paddingHeight}px
    ${(props) => props.paddingWidth}px;
  background-color: #e9ecef;
`;

const Header = styled(Center)`
  margin-bottom: 12px;
`;
Header.Title = styled(Text.Success)`
  font-size: ${remToDp(1.5)}px;
`;
Header.Status = styled(Text)`
  font-size: ${remToDp(1)}px;
  margin-top: ${remToDp(0.5)}px;
  margin-bottom: ${remToDp(0.5)}px;
`;
Header.Sub = styled(Text.Primary)`
  font-size: ${remToDp(1.5)}px;
  margin: 8px 0px;
`;

function TaskPage({ children, ...props }) {
  const { dimensions, vwToDp, vhToDp } = useScreenDimensions();

  const {
    layout: { topNavbarHeight },
    employee: {
      scannedEmployee: {
        customLabels: { task },
        showSidePanel,
        nonBillableTask,
      },
    },
    status: { hasWifi, pendingOperations },
  } = useStoreState((state) => state);

  const [bottomNavbarHeight, setBottomNavbarHeight] = useState(0);

  const { ProjectTaskID, ProjectDescription, TaskStatus, TimeUTC } =
    useStoreState((state) => state.task.currentTask);

  return (
    <ProtectedPage {...props}>
      <Center>
        <ContentContainer
          screenHeight={dimensions.height}
          topNavbarHeight={topNavbarHeight}
          bottomNavbarHeight={bottomNavbarHeight}
          maxWidth={
            !showSidePanel || Platform.OS !== "web" ? `${vwToDp(70)}px` : "100%"
          }
        >
          <ContentContainer.Inner
            paddingHeight={vhToDp(4)}
            paddingWidth={vwToDp(2)}
          >
            <Row>
              <Col xs={12} md={2.5} />

              <Col xs={12} md={7}>
                <Header>
                  <Header.Title>
                    {task.Title}: {ProjectTaskID.value} -{" "}
                    {ProjectDescription.value}
                  </Header.Title>

                  <Header.Status>
                    Status:{" "}
                    <Text.TaskStatus status={TaskStatus.value}>
                      {TaskStatus.value}
                    </Text.TaskStatus>
                  </Header.Status>

                  {TimeUTC.value && (
                    <Text>
                      Open Since {getFirstOpenedTaskTime(TimeUTC.value)}
                    </Text>
                  )}
                </Header>
              </Col>

              <Col xs={12} md={2.5}>
                {nonBillableTask && (
                  <Center>
                    {!hasWifi &&
                    pendingOperations.find(
                      (op) =>
                        op.operationName === OPERATION_TOGGLE_BILLABLE_TASK
                    ) ? (
                      <></>
                    ) : (
                      <Button.ToggleBillableTask />
                    )}
                  </Center>
                )}
              </Col>
            </Row>

            {children}
          </ContentContainer.Inner>
        </ContentContainer>
      </Center>

      <Navbar.Task
        onLayout={(event) =>
          setBottomNavbarHeight(event.nativeEvent.layout.height)
        }
      />

      <Modal.InspectionReject />
      <Modal.PdfDisplay />
    </ProtectedPage>
  );
}

export default TaskPage;
