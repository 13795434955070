import { remToDp } from "../../../utilities/responsive";
import Center from "../Center";
import Text from "../Text";
import styled from "styled-components/native";

const SText = styled(Text)`
  padding: ${remToDp(1)}px;
`;

function Option({ children, style, ...props }) {
  return (
    <Center {...props}>
      <SText style={style}>{children}</SText>
    </Center>
  );
}

export default Option;
