import Checkbox from "./Checkbox";
import Input from "./Input";
import InputSelect from "./InputSelect";
import InputTextArea from "./InputTextArea";

Input.Select = InputSelect;
Input.TextArea = InputTextArea;
Input.Checkbox = Checkbox;

export default Input;
