import styled from "styled-components/native";
import Table from "../Table";
import Text from "../Text";
import Icon from "../../containers/Icon";
import Quantity from "../Quantity";
import Center from "../Center";

const Container = styled(Table)`
  background-color: #f4f4f4;
  border-width: 1px;
  border-color: #ccc;
`;

const RightAlignTd = styled(Table.td)`
  width: 100%;
  align-items: flex-end;
`;

function PartRequestItem({
  InventoryCD,
  Description,
  PriorityLvl,
  UsrNeedBy,
  Qty,
  PartStatus,
  ItemReqNumber,
  CompanyQty,
  Customer,
  CustomerQty,
  TotalQty,
  ShowTotalQty,
  SimpleItemRequest,
  CompanyAbrv,
  RequestedBy,
  OrderNbr,
  ProjectID,
  NNumber,
  TaskID,
  Zone,
  UOM,
  UsrPriorityLevel,
  ...props
}) {
  return (
    <Container {...props}>
      <Table.tr>
        <Table.td>
          <Text.Primary>
            {InventoryCD} - {Description}
          </Text.Primary>
        </Table.td>

        <Table.td>
          <Text.Primary>{PriorityLvl}</Text.Primary>
        </Table.td>

        <Table.td>
          <Text.Primary>{UsrNeedBy}</Text.Primary>
        </Table.td>

        <Table.td>
          <Text.Primary>{Qty}</Text.Primary>
        </Table.td>

        <Table.td>
          <Text.Primary>{PartStatus}</Text.Primary>
        </Table.td>
      </Table.tr>

      <Table.tr>
        <Table.td>
          <Text>
            On Hand:{" "}
            {SimpleItemRequest ? (
              <Quantity quantity={CompanyQty} />
            ) : (
              <>
                {CompanyAbrv} (<Quantity quantity={CompanyQty} />) / {Customer}{" "}
                (<Quantity quantity={CustomerQty} />)
                {ShowTotalQty && (
                  <>
                    {" "}
                    / Total Available: (<Quantity quantity={TotalQty} />)
                  </>
                )}
              </>
            )}
          </Text>
        </Table.td>

        <Table.td>
          <Center>
            {!SimpleItemRequest &&
              (OrderNbr ? (
                <Icon.PrintItemRequest
                  data={{
                    OrderNbr,
                    Customer,
                    RequestedBy,
                    UsrNeedBy,
                    ProjectID,
                    NNumber,
                    TaskID,
                    Zone,
                    InventoryCD,
                    Description,
                    UOM,
                    Qty,
                    UsrPriorityLevel,
                  }}
                />
              ) : (
                <Icon.GetPartRequests />
              ))}
          </Center>
        </Table.td>

        <RightAlignTd>
          {!SimpleItemRequest && (
            <Text>
              Request: {ItemReqNumber}
              {"\n"}
              {RequestedBy && `by: ${RequestedBy}`}
            </Text>
          )}
        </RightAlignTd>
      </Table.tr>
    </Container>
  );
}

export default PartRequestItem;
