import React from "react";
import { Calendar as RNCalendar } from "react-native-calendars";
import styled from "styled-components/native";
import Text from "./Text";
import { remToDp } from "../../utilities/responsive";
import moment from "moment";

const DirectionArrow = styled(Text)`
  color: #51caff;
  font-size: ${remToDp(1.25)}px;
`;

function Calendar({ value, ...props }) {
  return (
    <RNCalendar
      markedDates={{
        [moment(new Date(value)).format("YYYY-MM-DD")]: {
          selected: true,
          disableTouchEvent: true,
          selectedDotColor: "orange",
        },
      }}
      renderArrow={(direction) => (
        <DirectionArrow>{direction === "left" ? "<" : ">"}</DirectionArrow>
      )}
      {...props}
    />
  );
}

export default Calendar;
