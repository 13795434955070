import Input from "./Input";
import { Platform, Pressable } from "react-native";
import { useState } from "react";
import Modal from "../Modal";
import styled from "styled-components/native";
import Text from "../Text";
import { useScreenDimensions } from "../../../utilities/responsive";

const ScrollViewList = styled.ScrollView`
  max-height: ${(props) => props.maxHeight}px;
`;

const Option = styled.Pressable`
  border-bottom-width: 1px;
  border-color: #eaeaea;
`;

const Label = styled(Text)`
  opacity: ${(props) => (props.disabled ? 0.25 : 1)};
`;

function InputSelect({
  options,
  onChange,
  header,
  onFocus,
  label,
  disabled,
  containerStyle,
  SearchBar,
  ...props
}) {
  const { vhToDp } = useScreenDimensions();

  const [showOptions, setShowOptions] = useState(false);

  return (
    <>
      <Pressable onPress={disabled ? null : () => setShowOptions(true)}>
        <Input
          onFocus={(e) => {
            onFocus && onFocus(e);
            Platform.OS !== "web" && setShowOptions(true);
          }}
          containerStyle={{
            opacity: disabled ? 0.25 : 1,
            ...containerStyle,
          }}
          label={<Label disabled={disabled}>{label}</Label>}
          {...props}
        />
      </Pressable>

      <Modal visible={showOptions} onRequestClose={() => setShowOptions(false)}>
        <Modal.Header onRequestClose={() => setShowOptions(false)}>
          {header}
        </Modal.Header>

        <Modal.Body>
          {SearchBar}

          <ScrollViewList maxHeight={vhToDp(80)}>
            {(options || []).map(({ children, ...option }, index) => (
              <Option
                onPress={() => {
                  onChange(option);
                  setShowOptions(false);
                }}
                key={index}
              >
                {children}
              </Option>
            ))}
          </ScrollViewList>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default InputSelect;
