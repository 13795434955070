import { useStoreActions, useStoreState } from "easy-peasy";
import Button from "../../presentational/Button";
import { useNavigation } from "@react-navigation/native";

function RejectInspectionButton({ children, ...props }) {
  const { inspectionRejectReasonInput } = useStoreState((state) => state.task);

  const { rejectTaskInspectionThunk } = useStoreActions((actions) => actions);

  const navigation = useNavigation();
  return (
    <Button
      color="#f8f9fa"
      onPress={() => rejectTaskInspectionThunk(navigation)}
      titleStyle={{ color: "#000000" }}
      disabled={!inspectionRejectReasonInput}
      {...props}
    >
      {!inspectionRejectReasonInput ? "Fill in reason" : children || "Submit"}
    </Button>
  );
}

export default RejectInspectionButton;
