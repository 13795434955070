import { thunk } from "easy-peasy";
import { getWeatherApiCall } from "../../api/weather";

const thunks = {
  getWeatherThunk: thunk(async (actions) => {
    try {
      const { current, location } = await getWeatherApiCall();
      actions.setCurrentWeatherAction(current);
      actions.setWeatherLocationAction(location);
    } catch (err) {
      console.error(err);
      actions.setAlertThunk({
        type: "error",
        message: handleAcumaticaError(err),
      });
    }
  }),
};

export default thunks;
