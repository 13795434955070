import { useStoreActions, useStoreState } from "easy-peasy";
import Modal from "../../presentational/Modal";
import Input from "../../presentational/Input";
import Button from "../Button";
import styled from "styled-components/native";
import Text from "../../presentational/Text";
import { useScreenDimensions } from "../../../utilities/responsive";

const RejectionReasonLabel = styled(Text)`
  margin-bottom: 12px;
  color: #2c6892;
`;

function useOnClose() {
  const { showInspectionRejectModalAction, setInspectionRejectReasonAction } =
    useStoreActions((actions) => actions);

  return () => {
    showInspectionRejectModalAction(false);
    setInspectionRejectReasonAction("");
  };
}

function InspectionRejectModal(props) {
  const { vhToDp } = useScreenDimensions();

  const { showInspectionRejectModal, inspectionRejectReasonInput } =
    useStoreState((state) => state.task);

  const { setInspectionRejectReasonAction } = useStoreActions(
    (actions) => actions
  );

  const onClose = useOnClose();

  return (
    <Modal
      visible={showInspectionRejectModal}
      onRequestClose={onClose}
      {...props}
    >
      <Modal.Header onRequestClose={onClose}>Inspection Rejection</Modal.Header>

      <Modal.Body>
        <RejectionReasonLabel>
          Briefly describe the reason for rejecting this inspection
        </RejectionReasonLabel>
        <Input
          multiline={true}
          onChangeText={(text) => setInspectionRejectReasonAction(text)}
          value={inspectionRejectReasonInput}
          placeholder="Reason for rejection"
          inputStyle={{ height: vhToDp(50) }}
        />
      </Modal.Body>

      <Modal.Footer>
        <Button.RejectInspection />
      </Modal.Footer>
    </Modal>
  );
}

export default InspectionRejectModal;
