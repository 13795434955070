import styled from "rn-css";

const Tr = styled.View`
  flex-direction: row;
  width: 100%;
  background-color: ${(props) => props.striped && "#f2f2f2"};

  &:hover {
    background-color: ${(props) => props.hover && "#EDEDED"};
  }
`;

export default Tr;
