import { thunk } from "easy-peasy";
import {
  addInspectionRejectionApiCall,
  addTaskToEmployeeTasksApiCall,
  getEmployeeTasksApiCall,
  removeTasksFromEmployeeTasksApiCall,
  toggleBillableTaskApiCall,
} from "../../api/task";
import { calculateTimeDifferenceInHours } from "../../utilities/date";
import {
  OPERATION_INSPECTION_COMPLETE_NAME,
  OPERATION_INSPECTION_REJECT_NAME,
  OPERATION_MARK_COMPLETE_NAME,
  OPERATION_STOP_ALL_TASKS_NAME,
  OPERATION_STOP_TASK_NAME,
  OPERATION_TOGGLE_BILLABLE_TASK,
  PAGE_HOME,
  QUALIFICATION_ERROR_MESSAGE,
} from "../../utilities/variables";
import { handleAcumaticaError } from "../../utilities/error";

const thunks = {
  getEmployeeTasksThunk: thunk(async (actions, fromLogin, helpers) => {
    !fromLogin && actions.setLoadingAction(true);

    const { employee, task } = helpers.getState();

    try {
      const taskListResponse = await getEmployeeTasksApiCall(
        employee.scannedEmployee.EmployeeID
      );

      // lock the queue to prevent future scans of new task cards if setup requires
      const kioskScanTimeLimit = employee.scannedEmployee.kioskScanTimeLimit;

      if (kioskScanTimeLimit) {
        if (!task.queue.isCountDownActive && taskListResponse.data.length) {
          actions.setQueueIsLockedAction(true);
        } else if (!taskListResponse.data.length) {
          actions.setQueueIsCountDownActiveAction(false);
          actions.setQueueLockSecondsAction(kioskScanTimeLimit);
          actions.setQueueIsLockedAction(false);
        }
      }
      actions.setTaskListAction(taskListResponse.data);
    } catch (err) {
      console.error(err);
      actions.setAlertThunk({
        type: "error",
        message: handleAcumaticaError(err),
      });
    }

    !fromLogin && actions.setLoadingAction(false);
  }),
  scanTaskThunk: thunk(async (actions, taskIdToScan, helpers) => {
    actions.setLoadingAction(true);

    const { auth, employee, task, status } = helpers.getState();

    const taskId = taskIdToScan || task.taskIdScanInput;

    try {
      if (!employee.usingCentrik || employee.qualifications.mechanicQualified) {
        if (status.hasWifi) {
          if (employee.qualifications.validities) {
            if (taskId) {
              if (task.queue.isLocked) {
                actions.showForcedLogoutModalAction(true);
              } else if (
                !task.list.find((t) => t.ProjectTaskID.value === taskId)
              ) {
                const response = await addTaskToEmployeeTasksApiCall({
                  taskId,
                  qualifications: employee.qualifications,
                  tenant: auth.user.tenant,
                  accountId: employee.scannedEmployee.AccountID,
                  employeeId: employee.scannedEmployee.EmployeeID,
                  employeeRefNo: employee.scannedEmployee.EmployeeRefNo,
                  acmVersion: employee.scannedEmployee.ACMVersion,
                  contactId: employee.scannedEmployee.ContactID,
                  projectId: task.list.length
                    ? task.list[0].WorkOrderID.value
                    : null,
                  inspector: employee.scannedEmployee.Inspector,
                  bypassQuals: employee.scannedEmployee.BypassQuals,
                });

                if (response.data.length) {
                  !task.list.length &&
                    actions.setQueueIsCountDownActiveAction(true);
                  !task.startTime && actions.updateStartTimeAction();
                  await actions.getEmployeeTasksThunk();
                  actions.setTaskIdScanInputAction("");
                  actions.setTaskSearchInputAction("");
                } else {
                  actions.setAlertThunk({
                    type: "error",
                    message: `Task ${taskId} does not exist`,
                  });
                }
              } else {
                actions.setAlertThunk({
                  message: `Task ${taskId} is already in this list`,
                  type: "warning",
                });
              }
            } else {
              actions.setAlertThunk({
                message: "No barcode value was detected",
                type: "warning",
              });
            }
          } else {
            actions.setAlertThunk({
              message:
                "Please wait until you name is green.  Your qualifications are still loading . . .",
              type: "warning",
            });
          }
        } else {
          actions.setAlertThunk({
            type: "error",
            message:
              "Cannot scan task offline. Connect to the internet and try again.",
          });
        }
      } else {
        actions.setAlertThunk({
          message: QUALIFICATION_ERROR_MESSAGE,
          type: "warning",
        });
      }
    } catch (err) {
      console.error(err);
      actions.setAlertThunk({
        type: "error",
        message: handleAcumaticaError(err),
      });
    }

    actions.setLoadingAction(false);
  }),
  stopAllTasksThunk: thunk(async (actions, project, helpers) => {
    actions.setLoadingAction(true);

    const {
      employee: {
        scannedEmployee: {
          EmployeeID,
          EmployeeRefNo,
          ContactID,
          LegalName,
          Approver,
        },
        usingCentrik,
        qualifications,
      },
      task: { currentTask, startTime },
      status: { hasWifi },
    } = helpers.getState();

    try {
      const data = {
        ...project,
        remove: true,
        EmployeeID: { value: EmployeeID },
        EmployeeRefNo: { value: EmployeeRefNo },
        ContactID: { value: ContactID },
        LegalName: { value: LegalName },
      };
      startTime &&
        (data.duration = calculateTimeDifferenceInHours(
          new Date(startTime),
          new Date()
        ));
      Approver && (data.Approver = { value: Approver });

      if (!usingCentrik || qualifications.mechanicQualified) {
        if (hasWifi) {
          await removeTasksFromEmployeeTasksApiCall(data);

          actions.setStartTimeAction(null);

          if (project.signout) {
            actions.logoutEmployeeThunk();
          } else {
            currentTask.ProjectTaskID &&
              actions.setCorrectiveActionInputAction("");
            await actions.getEmployeeTasksThunk();
          }
        } else {
          actions.addPendingOperationAction({
            ...data,
            operationName: OPERATION_STOP_ALL_TASKS_NAME,
            operationLabel: OPERATION_STOP_ALL_TASKS_NAME,
            operationDescription: "Remove all tasks from queue",
          });
          actions.syncAllTasksAction();
        }
      } else {
        actions.setAlertThunk({
          message: QUALIFICATION_ERROR_MESSAGE,
          type: "warning",
        });
      }
    } catch (err) {
      console.error(err);
      actions.setAlertThunk({
        type: "error",
        message: handleAcumaticaError(err),
      });
    }

    actions.setLoadingAction(false);
  }),
  stopTaskThunk: thunk(async (actions, navigation, helpers) => {
    actions.setLoadingAction(true);

    const {
      employee: {
        scannedEmployee: {
          EmployeeID,
          EmployeeRefNo,
          ContactID,
          LegalName,
          Approver,
        },
      },
      task: { currentTask, correctiveActionInput, startTime },
      status: { hasWifi },
    } = helpers.getState();

    try {
      if (correctiveActionInput && !currentTask.correctiveActionOnStopWork) {
        actions.setAlertThunk({
          error: `${correctiveActionInput} can not be recorded on Stop Work action!`,
          type: "warning",
        });
      } else {
        const data = {
          ...currentTask,
          remove: true,
          process: "incomplete",
          EmployeeID: { value: EmployeeID },
          EmployeeRefNo: { value: EmployeeRefNo },
          ContactID: { value: ContactID },
          LegalName: { value: LegalName },
          CorrectiveAction: {
            value:
              currentTask.correctiveActionOnStopWork && correctiveActionInput
                ? correctiveActionInput
                : "",
          },
        };
        startTime &&
          (data.duration = calculateTimeDifferenceInHours(
            new Date(startTime),
            new Date()
          ));
        Approver && (data.Approver = { value: Approver });

        if (hasWifi) {
          await removeTasksFromEmployeeTasksApiCall(data);
          actions.setCorrectiveActionInputAction("");
          await actions.getEmployeeTasksThunk();
        } else {
          actions.addPendingOperationAction({
            ...data,
            operationName: OPERATION_STOP_TASK_NAME,
            operationLabel: `Stop Task ${currentTask.ProjectTaskID.value}`,
            operationDescription: `Remove Task ${currentTask.ProjectTaskID.value} from queue`,
          });
          actions.syncTaskAction(currentTask.ProjectTaskID.value);
        }

        actions.updateStartTimeAction();
        navigation.navigate(PAGE_HOME);
      }
    } catch (err) {
      console.error(err);
      actions.setAlertThunk({
        type: "error",
        message: handleAcumaticaError(err),
      });
    }

    actions.setLoadingAction(false);
  }),
  markTaskCompleteThunk: thunk(async (actions, navigation, helpers) => {
    actions.setLoadingAction(true);

    const {
      employee: {
        scannedEmployee: {
          EmployeeID,
          EmployeeRefNo,
          ContactID,
          LegalName,
          Approver,
          customLabels,
        },
      },
      task: { currentTask, correctiveActionInput, startTime },
      status: { hasWifi },
    } = helpers.getState();

    try {
      if (
        currentTask.correctiveActionOnMarkComplete &&
        !correctiveActionInput
      ) {
        actions.setAlertThunk({
          message: `${customLabels.task.CorrectiveAction} cannot be blank`,
          type: "error",
        });
      } else if (
        !currentTask.correctiveActionOnMarkComplete &&
        correctiveActionInput
      ) {
        actions.setAlertThunk({
          message: `${correctiveActionInput} can not be recorded on Mark Complete action!`,
          type: "warning",
        });
      } else {
        const data = {
          ...currentTask,
          remove: true,
          process: "complete",
          EmployeeID: { value: EmployeeID },
          EmployeeRefNo: { value: EmployeeRefNo },
          ContactID: { value: ContactID },
          LegalName: { value: LegalName },
          CorrectiveAction: { value: correctiveActionInput },
        };
        startTime &&
          (data.duration = calculateTimeDifferenceInHours(
            new Date(startTime),
            new Date()
          ));
        Approver && (data.Approver = { value: Approver });

        if (hasWifi) {
          await removeTasksFromEmployeeTasksApiCall(data);
          await actions.getEmployeeTasksThunk();
        } else {
          actions.addPendingOperationAction({
            ...data,
            operationName: OPERATION_MARK_COMPLETE_NAME,
            operationLabel: `Mark Complete Task ${currentTask.ProjectTaskID.value}`,
            operationDescription: `Make Task ${currentTask.ProjectTaskID.value} ready for inspection`,
          });
          actions.syncTaskAction(currentTask.ProjectTaskID.value);
        }

        actions.updateStartTimeAction();

        navigation.navigate(PAGE_HOME);
      }

      actions.setCorrectiveActionInputAction("");
    } catch (err) {
      console.error(err);
      actions.setAlertThunk({
        type: "error",
        message: handleAcumaticaError(err),
      });
    }

    actions.setLoadingAction(false);
  }),
  approveTaskInspectionCompleteThunk: thunk(
    async (actions, navigation, helpers) => {
      actions.setLoadingAction(true);

      const {
        employee: {
          scannedEmployee: {
            EmployeeID,
            EmployeeRefNo,
            ContactID,
            LegalName,
            Approver,
          },
        },
        task: { currentTask, correctiveActionInput, startTime },
        status: { hasWifi },
      } = helpers.getState();

      try {
        if (
          correctiveActionInput &&
          !currentTask.correctiveActionOnInspectionComplete
        ) {
          actions.setAlertThunk({
            message: `${correctiveActionInput} can not be recorded on Inspection Complete action!`,
            type: "warning",
          });
        } else {
          const data = {
            ...currentTask,
            remove: true,
            process: "inspectionComplete",
            EmployeeID: { value: EmployeeID },
            EmployeeRefNo: { value: EmployeeRefNo },
            ContactID: { value: ContactID },
            LegalName: { value: LegalName },
            CorrectiveAction: {
              value:
                currentTask.correctiveActionOnInspectionComplete &&
                correctiveActionInput
                  ? correctiveActionInput
                  : "",
            },
          };
          startTime &&
            (data.duration = calculateTimeDifferenceInHours(
              new Date(startTime),
              new Date()
            ));
          Approver && (data.Approver = { value: Approver });

          if (hasWifi) {
            await removeTasksFromEmployeeTasksApiCall(data);
            await actions.getEmployeeTasksThunk();
          } else {
            actions.addPendingOperationAction({
              ...data,
              operationName: OPERATION_INSPECTION_COMPLETE_NAME,
              operationLabel: `Task ${currentTask.ProjectTaskID.value} Inspection Approval`,
              operationDescription: `Approve inspection for Task ${currentTask.ProjectTaskID.value}`,
            });
            actions.syncTaskAction(currentTask.ProjectTaskID.value);
          }

          actions.updateStartTimeAction();
          navigation.navigate(PAGE_HOME);
        }

        actions.setCorrectiveActionInputAction("");
      } catch (err) {
        console.error(err);
        actions.setAlertThunk({
          type: "error",
          message: handleAcumaticaError(err),
        });
      }

      actions.setLoadingAction(false);
    }
  ),
  rejectTaskInspectionThunk: thunk(async (actions, navigation, helpers) => {
    actions.setLoadingAction(true);

    const {
      task: { currentTask, inspectionRejectReasonInput },
      status,
    } = helpers.getState();

    try {
      const data = {
        rejectionDescription: inspectionRejectReasonInput,
        projectNoteId: currentTask.ProjectNoteID.value,
        taskNoteId: currentTask.TaskNoteID.value,
        process: "inspectionReject",
        RefNoteID: currentTask.RefNoteID,
        EmployeeCD: currentTask.EmployeeCD,
        ProjectTaskID: currentTask.ProjectTaskID.value,
      };

      if (!data.rejectionDescription) {
        throw new Error("Need reason for rejecting description");
      } else if (status.hasWifi) {
        await addInspectionRejectionApiCall(data);
        await actions.getEmployeeTasksThunk();
      } else {
        actions.addPendingOperationAction({
          ...data,
          operationName: OPERATION_INSPECTION_REJECT_NAME,
          operationLabel: `Task ${currentTask.ProjectTaskID.value} Inspection Reject`,
          operationDescription: `Reject inspection for Task ${currentTask.ProjectTaskID.value}`,
        });
        actions.syncTaskAction(currentTask.ProjectTaskID.value);
      }
      actions.showInspectionRejectModalAction(false);
      actions.setInspectionRejectReasonAction("");

      navigation.navigate(PAGE_HOME);
    } catch (err) {
      console.error(err);
      actions.setAlertThunk({
        type: "error",
        message: handleAcumaticaError(err),
      });
    }

    actions.setLoadingAction(false);
  }),
  toggleBillableTaskThunk: thunk(async (actions, payload, helpers) => {
    actions.setLoadingAction(true);

    const {
      task: { currentTask },
      status,
    } = helpers.getState();

    try {
      const data = {
        NonBillable: currentTask.NonBillable
          ? !currentTask.NonBillable.value
          : true,
        ProjectID: currentTask.WorkOrderID.value,
        ProjectTaskID: currentTask.ProjectTaskID.value,
      };

      if (status.hasWifi) {
        await toggleBillableTaskApiCall(data);
        await actions.getEmployeeTasksThunk();
        actions.reloadCurrentTaskAction();
      } else {
        actions.addPendingOperationAction({
          ...data,
          operationName: OPERATION_TOGGLE_BILLABLE_TASK,
          operationLabel: `Task ${currentTask.ProjectTaskID.value} - Mark ${
            data.NonBillable ? "Un-" : ""
          }Billable`,
          operationDescription: `Mark Task ${
            currentTask.ProjectTaskID.value
          } as ${data.NonBillable ? "Un-" : ""}Billable`,
        });
        actions.syncTaskAction(currentTask.ProjectTaskID.value);
      }
    } catch (err) {
      console.error(err);
      actions.setAlertThunk({
        type: "error",
        message: handleAcumaticaError(err),
      });
    }

    actions.setLoadingAction(false);
  }),
};

export default thunks;
