import { Image, View } from 'react-native';
import Row from './Row';
import Col from './Col';
import Button from './Button';

function Gallery({ image, onDelete }) {
  return (
    <View style={{ width: 124 }}>
      <Row>
        <Col style={{ paddingBottom: 0 }} xs={12}>
          <Button color='#dc3545' fullWidth onPress={() => onDelete(image)}>
            Delete
          </Button>
        </Col>
      </Row>
      <Row>
        <Col style={{ paddingTop: 0 }} xs={12}>
          <Image
            source={{ uri: image.uri }}
            style={{ width: 100, height: 100 }}
          ></Image>
        </Col>
      </Row>
    </View>
  );
}

export default Gallery;
