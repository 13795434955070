import { useStoreActions, useStoreState } from "easy-peasy";
import { useEffect } from "react";
import Icon from "../Icon";
import { useScreenDimensions } from "../../../utilities/responsive";
import { Platform } from "react-native";
import Input from "../../presentational/Input";

function BadgeInput() {
  const { vwToDp } = useScreenDimensions();

  const {
    employee: { badgeIdInput },
    auth: { user },
  } = useStoreState((state) => state);

  const { setBadgeIdInputAction, loginAcmThunk, loginEmployeeThunk } =
    useStoreActions((actions) => actions);

  useEffect(() => {
    !user.logo && loginAcmThunk();
  }, [user.logo]);

  return (
    <Input
      onChangeText={(badgeIdInput) => setBadgeIdInputAction(badgeIdInput)}
      value={badgeIdInput}
      placeholder="Enter your ID"
      secureTextEntry
      onSubmitEditing={() => loginEmployeeThunk()}
      containerStyle={{ maxWidth: vwToDp(30) }}
      inputStyle={{ textAlign: "center" }}
      rightIcon={
        Platform.OS === "web" ? null : <Icon.ShowScanBadgeBarcodeModal />
      }
    />
  );
}

export default BadgeInput;
