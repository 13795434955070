import { useStoreActions } from "easy-peasy";
import { remToDp } from "../../../utilities/responsive";
import styled from "styled-components/native";
import Text from "../../presentational/Text";

const Container = styled.Pressable`
  background-color: #2c6892;
  padding: ${remToDp(1)}px;
  justify-content: center;
  flex: 1;
`;

const SText = styled(Text)`
  color: #ffffff;
`;

function ShowAddUnserviceablePartRequestModalButton({
  children,
  onPress,
  ...props
}) {
  const {
    showAddUnserviceablePartRequestModalAction,
    showPartSearchResultsModalAction,
  } = useStoreActions((actions) => actions);

  return (
    <Container
      onPress={() => {
        onPress();
        showPartSearchResultsModalAction(false);
        showAddUnserviceablePartRequestModalAction(true);
      }}
      {...props}
    >
      {children || <SText>Add To Unserviceable Part Request</SText>}
    </Container>
  );
}

export default ShowAddUnserviceablePartRequestModalButton;
