import ItemRequirementsTab from "./ItemRequirementsTab";
import SubtypesTab from "./SubtypesTab";
import Tab from "./Tab";
import WorkInstructionsTab from "./WorkInstructionsTab";

Tab.ItemRequirements = ItemRequirementsTab;
Tab.WorkInstructions = WorkInstructionsTab;
Tab.Subtypes = SubtypesTab;

export default Tab;
