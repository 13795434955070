import { useStoreActions, useStoreState } from "easy-peasy";
import Option from "../../presentational/Option";
import SearchBar from "../SearchBar";
import Input from "../../presentational/Input";
import { caseInsensitiveFilter } from "../../../utilities/string";

function ChapterInput(props) {
  const {
    nonRoutine: {
      chapters,
      chapterSearchInput,
      nonroutineInput: { ATAChapterID },
    },
    employee: { scannedEmployee },
  } = useStoreState((state) => state);

  const { setNonroutineInputAction } = useStoreActions((actions) => actions);

  return (
    <Input.Select
      label={scannedEmployee.customLabels.nonRoutine.Chapter}
      value={ATAChapterID || ""}
      placeholder={scannedEmployee.customLabels.nonRoutine.Chapter}
      header={`Select ${scannedEmployee.customLabels.nonRoutine.Chapter}`}
      SearchBar={<SearchBar.Chapter />}
      options={chapters
        .filter((c) =>
          caseInsensitiveFilter(
            [c.ChapterID, c.Description],
            chapterSearchInput
          )
        )
        .map((c) => ({
          ...c,
          children: <Option.Nonroutine ID={c.ChapterID} {...c} />,
        }))}
      onChange={(option) =>
        setNonroutineInputAction({
          ATAChapterID: option.ChapterID,
          ATASubChapterID: "",
          ZoneID: "",
          STSZone: "",
        })
      }
      {...props}
    />
  );
}

export default ChapterInput;
