import { Table } from "@cfbs/cfbsstrap-native";
import { useBoatItemRequirementList } from "../../../hooks/boat";

function BoatItemRequirementList(props) {
  const { headers, list } = useBoatItemRequirementList();
  return (
    <Table border hover striped {...props}>
      <Table.tr>
        {headers.map((header) => (
          <Table.th>{header}</Table.th>
        ))}
      </Table.tr>

      {list.map((itemRequirement) => (
        <Table.tr key={itemRequirement.ItemReqID}>
          <Table.td>{itemRequirement.ItemReqID}</Table.td>
          <Table.td>{itemRequirement.Note}</Table.td>
          <Table.td>{itemRequirement.SOOrderNbr}</Table.td>
          <Table.td>{itemRequirement.InventoryID}</Table.td>
          <Table.td>{itemRequirement.RequestedQty}</Table.td>
          <Table.td>{itemRequirement.SOLineNbr}</Table.td>
          <Table.td>{itemRequirement.SOLineQty}</Table.td>
          <Table.td>{itemRequirement.OpenQty}</Table.td>
          <Table.td>{itemRequirement.TaskCD}</Table.td>
          <Table.td>{itemRequirement.TaskID}</Table.td>
          <Table.td>{itemRequirement.IssuedQty}</Table.td>
        </Table.tr>
      ))}
    </Table>
  );
}

export default BoatItemRequirementList;
