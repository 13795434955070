import Div from "./Div";
import Row from "../presentational/Row";
import TabContext from "./TabContext";
import React from "react";
import styled from "styled-components/native";

const SRow = styled(Row)`
  margin-bottom: 8px;
`;

function Tabs({ children, activeKey, onSelect, ...props }) {
  return (
    <TabContext.Provider value={{ activeKey, onSelect }}>
      <Div {...props}>
        <SRow>{children}</SRow>

        {React.Children.map(children, (child) =>
          child.props.eventKey === activeKey ? child.props.children : null
        )}
      </Div>
    </TabContext.Provider>
  );
}

export default Tabs;
