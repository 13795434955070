import { action } from "easy-peasy";
import { defaultState } from "./state";

const actions = {
  setTopNavbarHeightAction: action((state, height) => {
    state.layout.topNavbarHeight = height;
  }),
  showSidebarAsDrawerAction: action((state, show) => {
    state.layout.showSidebarAsDrawer = show;
  }),
  openSidebarDrawerAction: action((state, open) => {
    state.layout.openSidebarDrawer = open;
  }),
  resetLayoutStateAction: action((state) => {
    const { allowPortrait, ...defaultProps } = defaultState;
    state.layout = { ...state.layout, ...defaultProps };
  }),
  setAllowPortraitAction: action((state, allow) => {
    state.layout.allowPortrait = allow;
  }),
};

export default actions;
