import { useStoreActions, useStoreState } from "easy-peasy";
import React, { useEffect } from "react";
import { DeviceEventEmitter, Platform, View } from "react-native";

function InactiveTimer({ children, ...props }) {
  const {
    auth: {
      user: { autoLogout, autoLogoutMinutes },
    },
    employee: {
      scannedEmployee: { EmployeeID },
    },
  } = useStoreState((state) => state);

  const { logoutEmployeeThunk } = useStoreActions((actions) => actions);

  const inactiveTime = autoLogoutMinutes * 60000;

  let userActivityTimeout;

  function logout() {
    return () => EmployeeID && autoLogout && logoutEmployeeThunk();
  }

  function handleUserActivity() {
    clearTimeout(userActivityTimeout);
    userActivityTimeout = setTimeout(logout(), inactiveTime);
  }

  useEffect(() => {
    // Start the timer when the user is signed in
    EmployeeID &&
      autoLogout &&
      (userActivityTimeout = setTimeout(logout(), inactiveTime));

    // Add event listeners for user activity
    if (Platform.OS === "web") {
      window.addEventListener("mousemove", handleUserActivity);
      window.addEventListener("keydown", handleUserActivity);
      window.addEventListener("touchstart", handleUserActivity);
      window.addEventListener("scroll", handleUserActivity); // Add more events as needed
    } else {
      DeviceEventEmitter.addListener("userIsActive", handleUserActivity);
    }

    return () => {
      // Clean up event listeners and timers
      clearTimeout(userActivityTimeout);

      if (Platform.OS === "web") {
        window.removeEventListener("mousemove", handleUserActivity);
        window.removeEventListener("keydown", handleUserActivity);
        window.removeEventListener("touchstart", handleUserActivity);
        window.removeEventListener("scroll", handleUserActivity);
      } else {
        DeviceEventEmitter.removeAllListeners("userIsActive");
      }
    };
  }, [autoLogout, EmployeeID]);

  return <View {...props}>{children}</View>;
}

export default InactiveTimer;
