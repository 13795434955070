import PartRequest from "./PartRequest";
import PartRequestHeader from "./PartRequestHeader";
import PartRequestItem from "./PartRequestItem";
import PartRequestItemList from "./PartRequestItemList";

PartRequest.Item = PartRequestItem;
PartRequest.ItemList = PartRequestItemList;
PartRequest.Header = PartRequestHeader;

export default PartRequest;
