import { useStoreActions, useStoreState } from "easy-peasy";
import Modal from "../../presentational/Modal";
import Button from "../Button";
import Form from "../Form";

function useOnClose() {
  const {
    showAddUnserviceablePartRequestModalAction,
    showPartSearchResultsModalAction,
  } = useStoreActions((actions) => actions);
  return () => {
    showAddUnserviceablePartRequestModalAction(false);
    showPartSearchResultsModalAction(true);
  };
}

function AddUnserviceablePartRequestModal(props) {
  const {
    part: { showAddUnserviceablePartRequestModal },
    employee: { scannedEmployee },
  } = useStoreState((state) => state);

  const onClose = useOnClose();

  return (
    <Modal
      visible={showAddUnserviceablePartRequestModal}
      onRequestClose={onClose}
      {...props}
    >
      <Modal.Header onRequestClose={onClose}>
        {scannedEmployee.customLabels.unserviceablePart.Title}
      </Modal.Header>

      <Modal.Body>
        <Form.AddUnserviceablePartRequest />
      </Modal.Body>

      <Modal.Footer>
        <Button.AddUnserviceablePartRequest />
      </Modal.Footer>
    </Modal>
  );
}

export default AddUnserviceablePartRequestModal;
