import { axios } from ".";

const partAxios = axios.create({
  baseURL: `${process.env.EXPO_PUBLIC_BACKEND_BASE_URL}/api/v1/partReq`,
});

const stockItemAxios = axios.create({
  baseURL: `${process.env.EXPO_PUBLIC_BACKEND_BASE_URL}/api/v1/stockItem`,
});

const unserviceablePartAxios = axios.create({
  baseURL: `${process.env.EXPO_PUBLIC_BACKEND_BASE_URL}/api/v1/unsvcPart`,
});

async function getPartRequestsApiCall({
  ProjectTaskIDInt,
  CustomerWarehouse,
  CompanyWarehouse,
}) {
  const response = await partAxios({
    method: "get",
    url: `/${ProjectTaskIDInt}?cust=${CustomerWarehouse}&comp=${CompanyWarehouse}`,
  });
  return response.data;
}

async function getPartRequestManifestApiCall(ProjectTaskID) {
  const response = await partAxios({
    method: "get",
    url: `/manifest/${ProjectTaskID}`,
  });
  return response.data;
}

async function createPartRequestManifestPdfApiCall(data) {
  const response = await partAxios({
    method: "post",
    url: "/manifest/pdf",
    data,
  });
  return response.data;
}

async function searchPartsApiCall(data) {
  const response = await stockItemAxios({
    method: "post",
    url: "/find",
    data,
  });
  return response.data;
}

async function addUnserviceablePartRequestApiCall(data) {
  const response = await unserviceablePartAxios({
    method: "put",
    data,
  });
  return response.data;
}

async function addPartRequestApiCall(data) {
  const response = await partAxios({
    method: "put",
    data,
  });

  if (response.status === 206) {
    throw new Error(response.data.message);
  }

  return response.data;
}

async function addUnserviceableStockItemApiCall(data) {
  const response = await stockItemAxios({
    method: "put",
    url: "/unserviceable",
    data,
  });
  return response.data;
}

async function addStockItemRequestApiCall(data) {
  const response = await stockItemAxios({
    method: "put",
    data,
  });
  return response.data;
}

async function createsPartsRequisitionPdfApiCall(data) {
  const response = await partAxios({
    method: "post",
    url: "/requisition/pdf",
    data,
  });
  return response.data;
}

async function createUnserviceablePartPdfApiCall(data) {
  const response = await unserviceablePartAxios({
    method: "post",
    url: "/pdf",
    data,
  });
  return response.data;
}

export {
  partAxios,
  stockItemAxios,
  unserviceablePartAxios,
  getPartRequestsApiCall,
  getPartRequestManifestApiCall,
  createPartRequestManifestPdfApiCall,
  searchPartsApiCall,
  addUnserviceablePartRequestApiCall,
  addPartRequestApiCall,
  addUnserviceableStockItemApiCall,
  addStockItemRequestApiCall,
  createsPartsRequisitionPdfApiCall,
  createUnserviceablePartPdfApiCall,
};
