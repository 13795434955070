import { Pressable } from "react-native";
import Text from "../../presentational/Text";
import { useStoreActions } from "easy-peasy";

function SearchSubstitutePartButton({ children, style, value, ...props }) {
  const { searchPartsThunk } = useStoreActions((actions) => actions);

  return (
    <Pressable onPress={() => searchPartsThunk(value)} {...props}>
      <Text.Success style={style}>{children}</Text.Success>
    </Pressable>
  );
}

export default SearchSubstitutePartButton;
