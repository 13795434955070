import { axios } from ".";

const boatAxios = axios.create({
  baseURL: `${process.env.EXPO_PUBLIC_BACKEND_BASE_URL}/api/v1/boat`,
});

async function searchBoatsApiCall(searchvalue) {
  const response = await boatAxios({
    method: "get",
    url: `/projects/${searchvalue}`,
  });
  return response.data;
}

async function searchSubtypesApiCall(equipmentCd) {
  const response = await boatAxios({
    method: "get",
    url: `/subtype/list/${equipmentCd}`,
  });
  return response.data;
}

async function editSubtypeApiCall(data) {
  const response = await boatAxios({
    method: "put",
    url: "/subtype/edit",
    data,
  });
  return response.data;
}

async function getEquipmentApiCall(equipmentId) {
  const response = await boatAxios({
    method: "get",
    url: `/equipment/${equipmentId}`,
  });
  return response.data;
}

export {
  boatAxios,
  searchBoatsApiCall,
  searchSubtypesApiCall,
  editSubtypeApiCall,
  getEquipmentApiCall,
};
