import Row from "../Row";
import styled from "styled-components/native";
import { remToDp } from "../../../utilities/responsive";
import Text from "../Text";

const SRow = styled(Row)`
  justify-content: space-between;
  width: 100%;
  border-bottom-width: 1px;
  border-color: #dee2e6;
  align-items: center;
  padding: ${remToDp(1)}px;
`;

const SText = styled(Text)`
  font-size: ${remToDp(1.5)}px;
`;

function ModalHeader({ children, onRequestClose, ...props }) {
  return (
    <SRow onRequestClose {...props}>
      <SText>{children}</SText>
      {onRequestClose && <SText onPress={onRequestClose}>x</SText>}
    </SRow>
  );
}

export default ModalHeader;
