import TaskItem from "./TaskItem";
import Task from "./Task";
import styled from "styled-components/native";
import Text from "../Text";

const STaskItem = styled(TaskItem)`
  margin-top: 6px;
  margin-bottom: 6px;
`;

function TaskList({ list, message, ...props }) {
  return (
    <Task {...props}>
      {(list || []).length ? (
        list.map((task, index) => <STaskItem key={index} {...task} />)
      ) : (
        <Text.NotFound>{message || "No Project Tasks Found"}</Text.NotFound>
      )}
    </Task>
  );
}

export default TaskList;
