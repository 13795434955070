import { useStoreActions, useStoreState } from "easy-peasy";
import Environment from "../../presentational/Environment";
import { setAuthToken } from "../../../utilities/api";

function EnvironmentIrelandList(props) {
  const { isTest } = useStoreState((state) => state.employee);

  const {
    setUserAction,
    setTenantAction,
    setLogoAction,
    setFaviconAction,
    setSpinnerAction,
  } = useStoreActions((actions) => actions);

  function setGeography(geo) {
    let token = "";
    let logo = "";
    let email = "";

    if (isTest) {
      if (geo === "ireland") {
        token =
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InN0c0lyZWxhbmQyMVIyVGVzdEBjZmJzLXVzLmNvbSIsImlkIjoic3RzSXJlbGFuZDIxUjJUZXN0QGNmYnMtdXMuY29tIiwiaWF0IjoxNjk1NjcwNTI3fQ.-U75HTZ_N3bIaPnB2XYFwNPAQDttiEI0Rm7TaMHE9jk";
        logo = "logoModCenterIrelandTest.png";
        email = "stsIreland21R2Test@cfbs-us.com";
      } else if (geo === "irelandQA") {
        token =
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InN0c0lyZWxhbmRRQUBjZmJzLXVzLmNvbSIsImlkIjoic3RzSXJlbGFuZFFBQGNmYnMtdXMuY29tIiwiaWF0IjoxNjk1NzQwNDgwfQ.5kzGTaJEGl2TLmcaapaIHQyPZcGaViO_qUN6v50HUKM";
        logo = "logoModCenterIrelandQA.png";
        email = "stsIrelandQA@cfbs-us.com";
      }
    } else {
      token =
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InN0c0lyZWxhbmRAY2Zicy11cy5jb20iLCJpZCI6InN0c0lyZWxhbmRAY2Zicy11cy5jb20iLCJpYXQiOjE2OTU3NDA1OTB9.WYMZ4TcUFVCHN3DVUyVTxbxvy1CY1MAIl3Amk8DBtrs";
      logo = "logoModCenterIreland.png";
      email = "stsIreland@cfbs-us.com";
    }

    setUserAction({ email });
    setTenantAction("sts");
    setLogoAction(logo);
    setAuthToken(token);
    setFaviconAction("favicon_sts.ico");
    setSpinnerAction("rotate_sts.gif");
  }

  return (
    <Environment.List
      list={(isTest
        ? [
            {
              environment: "TEST",
              geographyLabel: "Ireland - STS Aviation Services",
              geographyValue: "ireland",
            },
            {
              environment: "QA",
              geographyLabel: "Ireland - STS Aviation Services",
              geographyValue: "irelandQA",
            },
          ]
        : [
            {
              environment: "",
              geographyLabel: "Ireland - STS Aviation Services",
              geographyValue: "",
            },
          ]
      ).map(({ geographyLabel, geographyValue, ...environment }) => ({
        geography: geographyLabel,
        onPress: () => setGeography(geographyValue),
        ...environment,
      }))}
      {...props}
    />
  );
}

export default EnvironmentIrelandList;
