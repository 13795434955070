import { useStoreActions, useStoreState } from "easy-peasy";
import Button from "../../presentational/Button";
import { FontAwesome5 } from "@expo/vector-icons";
import Text from "../../presentational/Text/Text";
import styled from "styled-components/native";

const SText = styled(Text)`
  color: #ffffff;
  margin-left: 8px;
`;

function ShowClockInModalButton({ children, ...props }) {
  const { BiometricAlternative, customLabels } = useStoreState(
    (state) => state.employee.scannedEmployee
  );

  const {
    showFaceScanModalAction,
    showFaceScanAlternativeModalAction,
    setIsClockingInAction,
  } = useStoreActions((actions) => actions);

  return (
    <Button
      color="#198754"
      onPress={() => {
        setIsClockingInAction(true);
        BiometricAlternative
          ? showFaceScanAlternativeModalAction(true)
          : showFaceScanModalAction(true);
      }}
      {...props}
    >
      {children || (
        <>
          <FontAwesome5 name="clock" size={24} color="#ffffff" />
          <SText>{customLabels.facialRecognition.KronosIn}</SText>
        </>
      )}
    </Button>
  );
}

export default ShowClockInModalButton;
