import { useStoreState } from "easy-peasy";
import Message from "../presentational/Message";
import Image from "../presentational/Image";
import styled from "styled-components/native";
import { Linking, Pressable, View } from "react-native";
import Button from "./Button";
import Text from "../presentational/Text";
import Row from "../presentational/Row";

const Container = styled(View)`
  width: 100%;
`;
Container.Row = styled(Row)`
  background-color: ${(props) => props.backgroundColor};
  align-items: center;
  flex-wrap: nowrap;
`;

const SyncAppAlert = styled(Row)`
  background-color: #ffd1d1;
  padding: 8px;
  align-items: center;
`;

const SyncAppFixConfirmed = styled(SyncAppAlert)`
  background-color: #fff3cd;
`;

const SImage = styled(Image)`
  height: 65px;
  aspect-ratio: 1;
`;

function Alerts(props) {
  const {
    status: { alerts, showSyncAppAlert, hasWifi, pendingOperations },
    employee: { qualifications, scannedEmployee },
  } = useStoreState((state) => state);

  return (
    <Container {...props}>
      {alerts.map(({ type, ...alert }) => {
        let Component = Message;
        let backgroundColor = "";

        if (type === "error") {
          Component = Message.Error;
          backgroundColor = "#ffd1d1";
        } else if (type === "success") {
          Component = Message.Success;
          backgroundColor = "#d1ffd3";
        } else if (type === "warning") {
          Component = Message.Warning;
          backgroundColor = "#fff3cd";
        }

        return (
          <Container.Row backgroundColor={backgroundColor} key={alert.id}>
            <Component>{alert.message}</Component>
            {Boolean(qualifications.validities) === true &&
              Boolean(qualifications.validities.centrikURL) === true && (
                <Pressable
                  onPress={() =>
                    Linking.openURL("https://stsaviation.centrik.net")
                  }
                >
                  <SImage
                    source={{
                      uri: "https://kioskbranding.blob.core.windows.net/images/centrikLogo.png",
                    }}
                    onPress={() =>
                      Linking.openURL(qualifications.validities.centrikURL)
                    }
                  />
                </Pressable>
              )}
          </Container.Row>
        );
      })}

      {Boolean(scannedEmployee.EmployeeID) === true &&
        (Boolean(showSyncAppAlert) === true ||
          pendingOperations.filter((op) => op.failed).length > 0) && (
          <SyncAppAlert>
            <Text.Error>App is not synced. </Text.Error>
            <Button.ShowSyncAppModal />
          </SyncAppAlert>
        )}

      {!hasWifi && (
        <Message.Warning>
          You are offline. Your ability to perform certain tasks is limited. You
          will need to sync this app once you are online again.
        </Message.Warning>
      )}

      {pendingOperations.filter((op) => op.failed).length > 0 && (
        <SyncAppFixConfirmed>
          <Text.Warning>I fixed the errors with syncing myself. </Text.Warning>
          <Button.SyncFixConfirmed />
        </SyncAppFixConfirmed>
      )}
    </Container>
  );
}

export default Alerts;
