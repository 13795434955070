import { useStoreActions } from "easy-peasy";
import Icon from "../../presentational/Icon";
import { useNavigation } from "@react-navigation/native";
import { FontAwesome } from "@expo/vector-icons";
import { OPERATION_INSPECTION_COMPLETE_NAME } from "../../../utilities/variables";

function InspectionCompleteIcon(props) {
  const navigation = useNavigation();
  const { approveTaskInspectionCompleteThunk } = useStoreActions(
    (actions) => actions
  );
  return (
    <Icon.WithText
      icon={<Icon.Yellow Component={FontAwesome} name="thumbs-up" />}
      text={OPERATION_INSPECTION_COMPLETE_NAME}
      onPress={() => approveTaskInspectionCompleteThunk(navigation)}
      {...props}
    />
  );
}

export default InspectionCompleteIcon;
