import { useStoreActions, useStoreState } from "easy-peasy";
import Modal from "../../presentational/Modal";
import Button from "../Button";
import Form from "../Form";

function useOnClose() {
  const { showAddStockItemRequestModalAction } = useStoreActions(
    (actions) => actions
  );

  return () => showAddStockItemRequestModalAction(false);
}

function AddStockItemRequestModal(props) {
  const { showAddStockItemRequestModal } = useStoreState((state) => state.part);

  const onClose = useOnClose();

  return (
    <Modal
      visible={showAddStockItemRequestModal}
      onRequestClose={onClose}
      {...props}
    >
      <Modal.Header onRequestClose={onClose}>
        New Stock Item Request
      </Modal.Header>

      <Modal.Body>
        <Form.AddStockItemRequest />
      </Modal.Body>

      <Modal.Footer>
        <Button.AddStockItemRequest />
      </Modal.Footer>
    </Modal>
  );
}

export default AddStockItemRequestModal;
