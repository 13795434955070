import { useNavigation } from "@react-navigation/native";
import Icon from "../../presentational/Icon";
import { PAGE_TASK } from "../../../utilities/variables";

function GoToTaskHomePageIcon(props) {
  const navigation = useNavigation();
  return (
    <Icon.WithText
      icon={<Icon.TaskList color="#6c757d" />}
      text="Task"
      onPress={() => navigation.navigate(PAGE_TASK)}
      {...props}
    />
  );
}

export default GoToTaskHomePageIcon;
