import { action } from "easy-peasy";
import { defaultState } from "./state";

const actions = {
  showFaceScanModalAction: action((state, show) => {
    state.facialRecog.showFaceScanModal = show;
  }),
  setIsClockingInAction: action((state, isClockingIn) => {
    state.facialRecog.isClockingIn = isClockingIn;
  }),
  createLogStepAction: action((state, step) => {
    const tx = state.facialRecog.txName;
    const txStep = step ? `${tx}-${step}` : tx;
    const log = state.facialRecog.logObj;

    if (log[txStep]) {
      function date(jsonProp) {
        return new Date(log[txStep][jsonProp]);
      }

      log[txStep]["stop"] = new Date().toISOString();
      log[txStep]["length"] = (date("stop") - date("start")) / 1000;
    } else {
      log[txStep] = { start: new Date(), stop: "" };
    }

    state.facialRecog.logObj = log;
  }),
  setLogObjAction: action((state, logObj) => {
    state.facialRecog.logObj = logObj;
  }),
  processFaceScanSuccessAction: action((state, data) => {
    state.facialRecog.isRecognized = true;
    state.facialRecog.isNotRecognized = false;
  }),
  processFaceScanFailAction: action((state, message) => {
    state.facialRecog.showFaceScanModal = false;
    state.facialRecog.showFaceScanAlternativeModal = false;
    state.facialRecog.isRecognized = false;
    state.facialRecog.isNotRecognized = true;
    state.facialRecog.showModalMessage = true;
    state.facialRecog.modalMessage = message;
    state.facialRecog.modalMessageHeader = message
      ? "Attention!"
      : "Please see supervisor immediately";
  }),
  processFaceScanCleanupAction: action((state, data) => {
    state.facialRecog.isRecognized = false;
    state.facialRecog.isNotRecognized = false;
    state.facialRecog.showModalMessage = false;
    state.facialRecog.modalMessage = "";
    state.facialRecog.modalMessageHeader = "";
    state.facialRecog.txName = "";
    state.facialRecog.logObj = {};
  }),
  setBiometricAlternativeCodeInputAction: action((state, input) => {
    state.facialRecog.biometricAlternativeCodeInput = input;
  }),
  showFaceScanAlternativeModalAction: action((state, show) => {
    state.facialRecog.showFaceScanAlternativeModal = show;
  }),
  resetFacialRecogStateAction: action((state, show) => {
    state.facialRecog = defaultState;
  }),
};

export default actions;
