const defaultState = {
  searchInput: "",
  boatSearchResults: [],
  chosenBoat: null,
  subtypeSearchResults: [],
  chosenSubtype: null,
  subtypeAttributeInput: "",
  equipment: null,
  boatItemRequirements: [],
  showEquipmentDetailsModal: false,
};

const state = {
  boat: defaultState,
};

export { state as default, defaultState };
