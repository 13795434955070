import Modal from "../../presentational/Modal/Modal";
import FaceScanAlternativeModal from "./FaceScanAlternativeModal";
import FaceScanModal from "./FaceScanModal";
import ForcedLoggedOutModal from "./ForcedLoggedOutModal";
import InspectionRejectModal from "./InspectionRejectModal";
import MessageModal from "./MessageModal";
import ScanBadgeBarcodeModal from "./ScanBadgeBarcodeModal";
import ScanTaskBarcodeModal from "./ScanTaskBarcodeModal";
import StockItemSearchResultsModal from "./StockItemSearchResultsModal";
import SyncAppModal from "./SyncAppModal";
import AddUnserviceablePartRequestModal from "./AddUnserviceablePartRequestModal";
import AddPartRequestModal from "./AddPartRequestModal";
import AddUnserviceableStockItemModal from "./AddUnserviceableStockItemModal";
import AddStockItemRequestModal from "./AddStockItemRequestModal";
import ModalPdfDisplay from "./ModalPdfDisplay";

Modal.FaceScan = FaceScanModal;
Modal.FaceScanAlternative = FaceScanAlternativeModal;
Modal.Message = MessageModal;
Modal.ForcedLoggedOut = ForcedLoggedOutModal;
Modal.InspectionReject = InspectionRejectModal;
Modal.StockItemSearchResults = StockItemSearchResultsModal;
Modal.SyncApp = SyncAppModal;
Modal.ScanTaskBarcode = ScanTaskBarcodeModal;
Modal.ScanBadgeBarcode = ScanBadgeBarcodeModal;
Modal.AddUnserviceablePartRequest = AddUnserviceablePartRequestModal;
Modal.AddPartRequest = AddPartRequestModal;
Modal.AddUnserviceableStockItem = AddUnserviceableStockItemModal;
Modal.AddStockItemRequest = AddStockItemRequestModal;
Modal.PdfDisplay = ModalPdfDisplay;

export default Modal;
