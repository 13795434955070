import { axios } from ".";

const panelAxios = axios.create({
  baseURL: `${process.env.EXPO_PUBLIC_BACKEND_BASE_URL}/api/v1/panel`,
});

async function getKioskSidePanelApiCall() {
  const response = await panelAxios({ method: "get", url: "/kiosk/side" });
  return response.data;
}

export { panelAxios, getKioskSidePanelApiCall };
