import { useStoreActions, useStoreState } from "easy-peasy";
import Option from "../../presentational/Option";
import SearchBar from "../SearchBar";
import Input from "../../presentational/Input";
import { caseInsensitiveFilter } from "../../../utilities/string";

function StsZoneInput(props) {
  const {
    stsZones,
    stsZoneSearchInput,
    nonroutineInput: { ATAChapterID, STSZone },
  } = useStoreState((state) => state.nonRoutine);

  const { setNonroutineInputAction } = useStoreActions((actions) => actions);

  return (
    <Input.Select
      label="STS Zone"
      value={STSZone || ""}
      placeholder="STS Zone"
      header="Select STS Zone"
      SearchBar={<SearchBar.StsZone />}
      disabled={!ATAChapterID}
      options={stsZones
        .filter((z) =>
          caseInsensitiveFilter([z.ZoneID, z.Description], stsZoneSearchInput)
        )
        .map((z) => ({
          ...z,
          children: <Option.Nonroutine ID={z.ZoneID} {...z} />,
        }))}
      onChange={(option) =>
        setNonroutineInputAction({
          STSZone: option.ZoneID,
        })
      }
      {...props}
    />
  );
}

export default StsZoneInput;
