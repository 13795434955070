const defaultState = {
  current: {},
  location: {},
};

const state = {
  weather: defaultState,
};

export { state as default, defaultState };
