import ChapterSearchBar from "./ChapterSearchBar";
import LaborItemSearchBar from "./LaborItemSearchBar";
import PartSearchBar from "./PartSearchBar";
import SearchBar from "./SearchBar";
import StsZoneSearchBar from "./StsZoneSearchBar";
import SubchapterSearchBar from "./SubchapterSearchBar";
import ZoneSearchBar from "./ZoneSearchBar";

SearchBar.Part = PartSearchBar;
SearchBar.Chapter = ChapterSearchBar;
SearchBar.Subchapter = SubchapterSearchBar;
SearchBar.Zone = ZoneSearchBar;
SearchBar.StsZone = StsZoneSearchBar;
SearchBar.LaborItem = LaborItemSearchBar;

export default SearchBar;
