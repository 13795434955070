import { useNavigation } from "@react-navigation/native";
import Icon from "../../presentational/Icon";
import Navbar from "../../presentational/Navbar";
import { PAGE_HOME } from "../../../utilities/variables";
import { useStoreActions, useStoreState } from "easy-peasy";

function GoToEmployeeTasksPageIcon(props) {
  const {
    customLabels: { taskList },
  } = useStoreState((state) => state.employee.scannedEmployee);

  const navigation = useNavigation();

  const { setTaskSearchInputAction } = useStoreActions((actions) => actions);

  return (
    <Navbar.Item
      icon={<Icon.TaskList />}
      text={taskList.Title}
      onPress={() => {
        setTaskSearchInputAction("");
        navigation.navigate(PAGE_HOME);
      }}
      {...props}
    />
  );
}

export default GoToEmployeeTasksPageIcon;
