import { useStoreActions, useStoreState } from "easy-peasy";

function useConfirmDuplicateInspectionModal() {
  const {
    employee: { scannedEmployee },
    task: { currentTask },
    nonRoutine: { showDuplicateInspectionConfirmationModal },
  } = useStoreState((state) => state);

  const {
    setShowDuplicateInspectionConfirmationModalAction,
    addNonroutineTaskThunk,
  } = useStoreActions((actions) => actions);

  return {
    visible: showDuplicateInspectionConfirmationModal,
    onRequestClose: () =>
      setShowDuplicateInspectionConfirmationModalAction(false),
    projectTaskId: currentTask.ProjectTaskID.value,
    buttonLabel: scannedEmployee.customLabels.nonRoutine.Button,
    onPressAddNonroutineTask: () => addNonroutineTaskThunk(),
  };
}

export { useConfirmDuplicateInspectionModal };
