import { useStoreActions, useStoreState } from "easy-peasy";
import Environment from "../../presentational/Environment";
import { setAuthToken } from "../../../utilities/api";

function EnvironmentDubaiList(props) {
  const { isTest } = useStoreState((state) => state.employee);

  const {
    setUserAction,
    setTenantAction,
    setLogoAction,
    setFaviconAction,
    setSpinnerAction,
  } = useStoreActions((actions) => actions);

  function setGeography(geo) {
    let token = "";
    let logo = "";
    let email = "";

    if (isTest) {
      if (geo === "dubai") {
        token =
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InN0c0R1YmFpMjFSMlRlc3RAY2Zicy11cy5jb20iLCJpZCI6InN0c0R1YmFpMjFSMlRlc3RAY2Zicy11cy5jb20iLCJpYXQiOjE2OTQ1MDQ0OTd9.Gwu1YD9XptNzRv5LgLRHtpkC5K2o0tI7SzVqcmtUBVg";
        logo = "logoModCenterDubaiTest.png";
        email = "stsDubai21R2Test@cfbs-us.com";
      } else if (geo === "dubaiQA") {
        token =
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InN0c0R1YmFpUUFAY2Zicy11cy5jb20iLCJpZCI6InN0c0R1YmFpUUFAY2Zicy11cy5jb20iLCJpYXQiOjE2OTU3NDAwOTZ9.1QUwA3xEYCi54V-UFCJl1munsmcQwp4St7MrYRqpUIk";
        logo = "logoModCenterDubaiTest.png";
        email = "stsDubaiQA@cfbs-us.com";
      }
    } else {
      token =
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InN0c0R1YmFpQGNmYnMtdXMuY29tIiwiaWQiOiJzdHNEdWJhaUBjZmJzLXVzLmNvbSIsImlhdCI6MTY5NTY2NzIzOH0.t0PCXoxAMBaes4OYmvqZRsQXub5Us9xlt2P5w-nH2oY";
      logo = "logoModCenterDubai.png";
      email = "stsDubai@cfbs-us.com";
    }

    setUserAction({ email });
    setTenantAction("sts");
    setLogoAction(logo);
    setAuthToken(token);
    setFaviconAction("favicon_sts.ico");
    setSpinnerAction("rotate_sts.gif");
  }

  return (
    <Environment.List
      list={(isTest
        ? [
            {
              environment: "TEST",
              geographyLabel: "Dubai - STS Aviation Services",
              geographyValue: "dubai",
            },
            {
              environment: "QA",
              geographyLabel: "Dubai - STS Aviation Services",
              geographyValue: "dubaiQA",
            },
          ]
        : [
            {
              environment: "",
              geographyLabel: "Dubai - STS Aviation Services",
              geographyValue: "",
            },
          ]
      ).map(({ geographyLabel, geographyValue, ...environment }) => ({
        geography: geographyLabel,
        onPress: () => setGeography(geographyValue),
        ...environment,
      }))}
      {...props}
    />
  );
}

export default EnvironmentDubaiList;
