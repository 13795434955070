function caseInsensitiveComparisonString(str) {
  return str.trim().toLowerCase();
}

function caseInsensitiveFilter(strList, input) {
  return strList.some((str) =>
    caseInsensitiveComparisonString(str).includes(
      caseInsensitiveComparisonString(input)
    )
  );
}

export { caseInsensitiveComparisonString, caseInsensitiveFilter };
