import store from "./src/store/index.js";
import { StoreProvider } from "easy-peasy";
import React from "react";
import { axiosInterceptor } from "./src/utilities/api";
import { authAxios, authLoginAxios } from "./src/api/auth";
import AppNavigator from "./src/components/navigation/AppNavigator.js";
import { employeeAxios } from "./src/api/employee.js";
import { facialRecogAxios } from "./src/api/facialRecog.js";
import { centrikAxios } from "./src/api/centrik.js";
import { meAxios, projectAxios, taskAxios } from "./src/api/task.js";
import {
  partAxios,
  stockItemAxios,
  unserviceablePartAxios,
} from "./src/api/part.js";
import { nonRoutineAxios } from "./src/api/nonroutine.js";
import { boatAxios } from "./src/api/boat.js";
import { panelAxios } from "./src/api/panel.js";

export default function App() {
  axiosInterceptor(authAxios, store);
  axiosInterceptor(authLoginAxios, store);
  axiosInterceptor(employeeAxios, store);
  axiosInterceptor(facialRecogAxios, store);
  axiosInterceptor(centrikAxios, store);
  axiosInterceptor(meAxios, store);
  axiosInterceptor(projectAxios, store);
  axiosInterceptor(partAxios, store);
  axiosInterceptor(stockItemAxios, store);
  axiosInterceptor(unserviceablePartAxios, store);
  axiosInterceptor(nonRoutineAxios, store);
  axiosInterceptor(boatAxios, store);
  axiosInterceptor(taskAxios, store);
  axiosInterceptor(panelAxios, store);

  return (
    <StoreProvider store={store}>
      <AppNavigator />
    </StoreProvider>
  );
}
