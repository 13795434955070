import { useStoreActions, useStoreState } from "easy-peasy";
import Modal from "../../presentational/Modal";
import Button from "../Button";
import Form from "../Form";

function useOnClose() {
  const { showAddPartRequestModalAction, showPartSearchResultsModalAction } =
    useStoreActions((actions) => actions);

  return () => {
    showAddPartRequestModalAction(false);
    showPartSearchResultsModalAction(true);
  };
}

function AddPartRequestModal(props) {
  const {
    part: { showAddPartRequestModal },
    employee: { scannedEmployee },
  } = useStoreState((state) => state);

  const onClose = useOnClose();

  return (
    <Modal
      visible={showAddPartRequestModal}
      onRequestClose={onClose}
      {...props}
    >
      <Modal.Header onRequestClose={onClose}>
        {scannedEmployee.customLabels.partQuantity.Title}
      </Modal.Header>

      <Modal.Body>
        <Form.AddPartRequest />
      </Modal.Body>

      <Modal.Footer>
        <Button.AddPartRequest />
      </Modal.Footer>
    </Modal>
  );
}

export default AddPartRequestModal;
