import styled from "styled-components/native";
import { remToDp } from "../../../utilities/responsive";

const Text = styled.Text`
  color: #00205b;
  font-size: ${remToDp(1)}px;
  font-weight: ${(props) => (props.bold ? "bold" : "normal")};
`;

export default Text;
