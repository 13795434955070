import { useStoreActions, useStoreState } from "easy-peasy";
import SearchBar from "../../presentational/SearchBar";
import Icon from "../../presentational/Icon";

function ChapterSearchBar(props) {
  const { chapterSearchInput } = useStoreState((state) => state.nonRoutine);
  const { setChapterSearchInputAction } = useStoreActions((actions) => actions);

  return (
    <SearchBar
      value={chapterSearchInput}
      onChangeText={(input) => setChapterSearchInputAction(input)}
      clearIcon={<Icon.Clear onPress={() => setChapterSearchInputAction("")} />}
      placeholder="Filter Chapters"
      {...props}
    />
  );
}

export default ChapterSearchBar;
