import BoatItemRequirementList from "./BoatItemRequirementList";
import List from "./List";
import ProjectList from "./ProjectList";
import ProjectTaskList from "./ProjectTaskList";
import SubtypeList from "./SubtypeList";

List.Project = ProjectList;
List.ProjectTask = ProjectTaskList;
List.Subtype = SubtypeList;
List.BoatItemRequirement = BoatItemRequirementList;

export default List;
