import { thunk } from 'easy-peasy';
import { loginAcmApiCall, loginApiCall } from '../../api/auth';
import {
  getAuthToken,
  removeAuthToken,
  setAuthToken,
} from '../../utilities/api';
import { Storage } from '../../utilities/Storage';
import { handleAcumaticaError } from '../../utilities/error';
import { PAGE_HOME } from '../../utilities/variables';

const thunks = {
  loginThunk: thunk(async (actions, payload, helpers) => {
    actions.setLoadingAction(true);

    try {
      const response = await loginApiCall(helpers.getState().auth.loginInput);
      const { token, ...user } = response.data;
      await setAuthToken(token);
      actions.setUserAction(user);
      actions.setLogoAction(user.logo);
      actions.setFaviconAction(user.favicon);
      actions.setTenantAction(user.tenant);
      actions.setSpinnerAction(user.spinner);
    } catch (err) {
      console.error(err);
      actions.setAlertThunk({
        type: 'error',
        message: handleAcumaticaError(err),
      });
    }

    actions.setLoadingAction(false);
  }),
  loginAcmThunk: thunk(async (actions, input) => {
    actions.setLoadingAction(true);

    try {
      const response = await loginAcmApiCall();
      actions.setUserAction(response.data);
    } catch (err) {
      console.error(err);
      actions.setAlertThunk({
        type: 'error',
        message: handleAcumaticaError(err),
      });
    }

    actions.setLoadingAction(false);
  }),
  selectEnvironmentThunk: thunk(async (actions, navigation) => {
    await Promise.all([
      removeAuthToken(),
      Storage.removeItem('tenant'),
      Storage.removeItem('logo'),
      Storage.removeItem('favicon'),
      Storage.removeItem('spinner'),
    ]);
    actions.resetUserAction();
    actions.resetLoginInputAction();
    actions.logoutEmployeeThunk();
    actions.setQueueIsCountDownActiveAction(false);
    navigation && navigation.navigate(PAGE_HOME);
  }),
  getAuthenticatedUserThunk: thunk(async (actions, payload, helpers) => {
    actions.setLoadingAction(true);

    if (await getAuthToken()) {
      const [tenant, logo, favicon, spinner] = await Promise.all([
        Storage.getItem('tenant'),
        Storage.getItem('logo'),
        Storage.getItem('favicon'),
        Storage.getItem('spinner'),
      ]);
      actions.setTenantAction(tenant);
      actions.setLogoAction(logo);
      actions.setFaviconAction(favicon);
      actions.setSpinnerAction(spinner);
    }

    actions.setLoadingAction(false);
  }),
};

export default thunks;
