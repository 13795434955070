import { useStoreState } from "easy-peasy";
import PartRequest from "../../presentational/PartRequest";

function PartRequestList(props) {
  const {
    part: { partRequestList },
    employee: { scannedEmployee },
    task: { currentTask },
  } = useStoreState((state) => state);

  return (
    <PartRequest.ItemList
      list={partRequestList.map((partRequest) => {
        let Qty = partRequest.Qty || 0;
        typeof partRequest.Qty === "object" && (Qty = partRequest.Qty.value);
        return {
          ...partRequest,
          Qty,
          CompanyAbrv: scannedEmployee.CompanyAbrv,
          ShowTotalQty: currentTask.ShowTotalQty
            ? currentTask.ShowTotalQty.value
            : false,
          Customer: currentTask.Customer.value,
        };
      })}
      message={scannedEmployee.customLabels.partRequest.None}
      {...props}
    />
  );
}

export default PartRequestList;
