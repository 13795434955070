const defaultState = {
  showFaceScanModal: false,
  isClockingIn: false,
  showFaceScanAlternativeModal: false,
  logObj: {},
  isRecognized: false,
  isNotRecognized: false,
  modalMessage: "",
  modalMessageHeader: "",
  showModalMessage: false,
  biometricAlternativeCodeInput: "",
};

const state = {
  facialRecog: defaultState,
};

export { state as default, defaultState };
