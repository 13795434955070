import { CheckBox as RNCheckbox } from "@rneui/themed";
import styled from "styled-components/native";

const SCheckbox = styled(RNCheckbox)`
  background-color: rgba(0, 0, 0, 0);
`;

function Checkbox({ containerStyle, disabled, onPress, ...props }) {
  return (
    <SCheckbox
      containerStyle={{
        backgroundColor: "rgba(0, 0, 0, 0)",
        ...containerStyle,
        opacity: disabled ? 0.25 : 1,
      }}
      {...props}
      onPress={(e) => !disabled && onPress(e)}
    />
  );
}

export default Checkbox;
