import BadgeInput from "./BadgeInput";
import ChapterInput from "./ChapterInput";
import Input from "./Input";
import LaborItemInput from "./LaborItemInput";
import StsZoneInput from "./StzZoneInput";
import SubchapterInput from "./SubchapterInput";
import TaskInput from "./TaskInput";
import ZoneInput from "./ZoneInput";

Input.Task = TaskInput;
Input.Badge = BadgeInput;

Input.Chapter = ChapterInput;
Input.Subchapter = SubchapterInput;
Input.Zone = ZoneInput;
Input.StsZone = StsZoneInput;
Input.LaborItem = LaborItemInput;

export default Input;
