import React from "react";
import Center from "../../presentational/Center";
import Environment from "../../presentational/Environment";
import List from "../List";
import Gap from "../../presentational/Gap";
import styled from "styled-components/native";
import { useScreenDimensions } from "../../../utilities/responsive";
import { useStoreState } from "easy-peasy";

const SGap = styled(Gap)`
  padding: ${(props) => props.padding}px;
`;

function EnvironmentPage(props) {
  const { vhToDp } = useScreenDimensions();
  const { isTest } = useStoreState((state) => state.employee);
  const padding = vhToDp(3);
  return (
    <Center.Screen {...props}>
      <Environment>
        <Environment.Header>
          Please Select a Geography and Environment
        </Environment.Header>

        <List.EnvironmentUs />

        {Boolean(isTest) === true && <SGap padding={padding} />}

        <List.EnvironmentUk />

        {Boolean(isTest) === true && <SGap padding={padding} />}

        <List.EnvironmentDubai />

        {Boolean(isTest) === true && <SGap padding={padding} />}

        <List.EnvironmentIreland />
      </Environment>
    </Center.Screen>
  );
}

export default EnvironmentPage;
