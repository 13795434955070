import { thunk } from "easy-peasy";
import { getEmployeeApiCall } from "../../api/employee";
import { getValiditiesApiCall } from "../../api/centrik";
import { qualSplit } from "../../utilities/qualification";
import { getNonRoutineDropdownsApiCall } from "../../api/nonroutine";
import { handleAcumaticaError } from "../../utilities/error";
import { customLabels } from "../../utilities/customLabels";
import { getKioskSidePanelApiCall } from "../../api/panel";

const thunks = {
  loginEmployeeThunk: thunk(async (actions, badgeId, helpers) => {
    actions.setLoadingAction(true);

    actions.setTaskListAction([]);

    try {
      const { employee, kioskConfig } = await getEmployeeApiCall(
        badgeId || helpers.getState().employee.badgeIdInput
      );

      if (employee.showSidePanel) {
        actions.setKioskSidePanelAction(await getKioskSidePanelApiCall());
      }

      actions.setAllowPortraitAction(employee.allowPortrait);
      actions.setScannedEmployeeAction({
        ...employee,
        customLabels: customLabels(employee.customLabels),
      });

      const [nonRoutineDropdowns, _] = await Promise.all([
        getNonRoutineDropdownsApiCall(),
        actions.getEmployeeTasksThunk(true),
      ]);

      if (!Array.isArray(nonRoutineDropdowns.priorityLevels)) {
        actions.setAlertThunk({
          type: "warning",
          message: `Priority levels is not a list. Empty list set as default for priority levels. Check your licensing. Retrieved priority levels: ${nonRoutineDropdowns.priorityLevels}`,
        });
      }
      actions.setPriorityLevelsAction(nonRoutineDropdowns.priorityLevels);
      actions.setChaptersAction(nonRoutineDropdowns.chapters);
      actions.setSubChaptersAction(nonRoutineDropdowns.subChapters);
      actions.setZonesAction(nonRoutineDropdowns.zones);
      actions.setStsZonesAction(nonRoutineDropdowns.stsZones);
      actions.setLaborItemsAction(nonRoutineDropdowns.laborItems);
      actions.setShelfLifeOptionsAction(nonRoutineDropdowns.itemConditions);

      if (kioskConfig && kioskConfig.CentrikBaseURL) {
        actions.setUsingCentrikAction(true);
        const validities = await getValiditiesApiCall(employee.Email);
        actions.setValiditesAction(validities || {});
        actions.checkQualificationsThunk({
          employee,
          creds: validities,
        });
      } else {
        actions.setUsingCentrikAction(false);
        actions.setValiditesAction({});
        actions.setQualificationsMechanicAction(true);
        actions.setQualificationsInspectorAction({});
        actions.setQualificationsBoroscopeAction(false);
        actions.setQualificationsFailedAction("");
      }

      if (employee.KronosClockIn) {
        actions.setAlertThunk({
          type: "success",
          message: " Successfully detected a prior clock in",
        });
      }
    } catch (err) {
      console.error(err);
      actions.setAlertThunk({
        type: "error",
        message: handleAcumaticaError(err),
      });
    }

    actions.setLoadingAction(false);
  }),
  logoutEmployeeThunk: thunk(async (actions) => {
    actions.resetEmployeeStateAction();
    actions.resetFacialRecogStateAction();
    actions.resetNonroutineStateAction();
    actions.resetPartStateAction();
    actions.resetStatusStateAction();
    actions.resetTaskStateAction();
    actions.resetProjectStateAction();
    actions.resetFileStateAction();
    actions.resetWeatherStateAction();
    actions.resetLayoutStateAction();
  }),
  checkQualificationsThunk: thunk(async (actions, { creds, employee }) => {
    let customer = [];
    let personalQualifications = [];
    let typeQualifications = [];
    let missingQualification = [];
    let warningQualification = [];
    let failedQualification = "";
    let mechanicQualified = true;
    let inspectorQualified = {};
    let boroscopeQualified = false;

    /* #region  make sure only non-expired qualifications are compared */
    if (employee.qualificationReqs.active) {
      (creds && creds.personal ? creds.personal : []).forEach((c) => {
        c.expiresOn &&
          c.expiresOn.length &&
          new Date() <= new Date(c.expiresOn) &&
          personalQualifications.push(c.qualificationName);

        const expiryDate = new Date(c.expiresOn);
        const difference = expiryDate.getTime() - new Date();
        const expiresInDays = Math.ceil(difference / (1000 * 3600 * 24));

        /* #region Check for type qualifications and  against grace days */

        /* #region  look for missing and check expiry against grace days */
        if (employee.qualificationGraceDays && c.qualificationMandatory) {
          isNaN(expiresInDays) &&
            missingQualification.push(
              `${c.qualificationName} has an invalid expiry or missing expiry date`
            );

          !isNaN(expiresInDays) &&
            expiresInDays <= employee.qualificationGraceDays &&
            missingQualification.push(
              expiresInDays < 0
                ? `${c.qualificationName}  expired  ${
                    expiresInDays * -1
                  } days ago.`
                : `${c.qualificationName}  expires in ${expiresInDays} days.`
            );
        }
        /* #endregion */

        /* #region  look expiring days on warning days if array exists */
        !isNaN(expiresInDays) &&
          c.qualificationMandatory &&
          (employee.qualificationWarningDays || []).includes(expiresInDays) &&
          warningQualification.push(
            `Warning: ${c.qualificationName} expires in ${expiresInDays} days.`
          );
        /* #endregion */

        typeQualifications = creds.personal.reduce((arrayResult, cred) => {
          cred.qualificationExternalId === "TYPE" &&
            arrayResult.push(qualSplit(cred.qualificationName));
          return arrayResult;
        }, []);
      });

      actions.setQualificationsPersonalAction(personalQualifications);
      actions.setQualificationsTypeAction(typeQualifications);

      // view qualification requirements for user
      if (!missingQualification.length && employee.qualificationReqs) {
        function checkQualifications(qualifications = []) {
          return qualifications.every((qualification) => {
            if (!personalQualifications.includes(qualification)) {
              failedQualification = qualification;
              missingQualification.push(
                `Failed to verify ${qualification} qualifications`
              );
              return false;
            }
            return true;
          });
        }

        /* #region  check mechanic qualifications */
        employee.qualificationReqs.mechanicQuals &&
          (mechanicQualified = checkQualifications(
            employee.qualificationReqs.mechanicQuals
          ));
        /* #endregion */

        /* #region  check inspector qualifications */
        // currently only handle 2 or less indexes in array
        (employee.qualificationReqs.inspectorQuals || []).forEach((i) => {
          if (i[Object.keys(i)[0]].length === 1) {
            inspectorQualified[Object.keys(i)[0]] =
              personalQualifications.includes(i[Object.keys(i)[0]][0]);
          }

          if (i[Object.keys(i)[0]].length === 2) {
            inspectorQualified[Object.keys(i)[0]] = false;
            //loop through each personal qualification
            //checking for 2 values to be part of the qualification
            const keys = Object.keys(personalQualifications);
            for (let i2 = 0; i2 < keys.length; i2++) {
              if (
                personalQualifications[keys[i2]].includes(
                  i[Object.keys(i)[0]][0]
                ) &&
                personalQualifications[keys[i2]].includes(
                  i[Object.keys(i)[0]][1]
                )
              ) {
                inspectorQualified[Object.keys(i)[0]] = true;
                break;
              }
            }
          }
        });
        /* #endregion */

        /* #region  check boroscope qualifications */
        boroscopeQualified = employee.qualificationReqs.boroscopeQuals.some(
          (qualification) => personalQualifications.includes(qualification)
        );
        /* #endregion */

        /* #region  check mecahnic and inspector qualifications */
        mechanicQualified &&
          (mechanicQualified = checkQualifications(
            employee.Inspector === "YES"
              ? employee.qualificationReqs.certifyingCompetency
              : employee.qualificationReqs.mechanicCompetency
          ));
        /* #endregion */

        /* #region  check category qualifications using mechanicQualified process */
      } else {
        mechanicQualified = false;
      }

      /* #region  type specific qualifications */
      if (creds && creds.typeSpecific) {
        customer = creds.typeSpecific.slice(0);

        actions.setQualificationsCustomerAction(customer);

        customer.forEach((c) => {
          c.mechanicQualified = mechanicQualified;
          c.validities.every((v) => {
            const expiresOnDate = new Date(v.expiresOn);
            const difference = expiresOnDate.getTime() - new Date();
            const expiresInDays = Math.ceil(difference / (1000 * 3600 * 24));

            if (
              (isNaN(expiresInDays) ||
                expiresInDays <= employee.qualificationGraceDays) &&
              v.qualificationMandatory
            ) {
              c.mechanicQualified = false;
              c.failedMessage = `${v.qualificationName} failed expiry date check`;
              return false;
            }

            return true;
          });
        });
      }
      /* #endregion */

      /* #region  mechanic not qualified */
      if (!mechanicQualified) {
        missingQualification.push(`You can not continue`);
        actions.setLockedAlertThunk({
          type: "error",
          message: missingQualification,
        });
      }

      mechanicQualified &&
        warningQualification.length &&
        actions.setAlertThunk({
          message: warningQualification,
          type: "warning",
        });
      /* #endregion */
    }

    /* #region  set state for qualifications*/
    actions.setQualificationsMechanicAction(mechanicQualified);
    actions.setQualificationsInspectorAction(inspectorQualified);
    actions.setQualificationsBoroscopeAction(boroscopeQualified);
    actions.setQualificationsFailedAction(failedQualification);
    /* #endregion */
  }),
};

export default thunks;
