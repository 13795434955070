import { useStoreState } from "easy-peasy";
import { ActivityIndicator } from "react-native";
import styled from "styled-components/native";
import Image from "../presentational/Image";

const SpinnerImage = styled(Image)`
  width: 200px;
  height: 200px;
  align-self: center;
`;

function Loading(props) {
  const { spinner } = useStoreState((state) => state.auth.user);

  return spinner ? (
    <SpinnerImage
      source={{
        uri: `https://kioskbranding.blob.core.windows.net/images/${spinner}`,
      }}
      {...props}
    />
  ) : (
    <ActivityIndicator size="large" color="#AB2328" {...props} />
  );
}

export default Loading;
