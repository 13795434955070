import { useStoreState } from "easy-peasy";
import Image from "../presentational/Image";
import styled from "styled-components/native";

const SImage = styled(Image)`
  width: 100%;
`;

function Logo(props) {
  const { logo } = useStoreState((state) => state.auth.user);

  return (
    <SImage
      source={{
        uri: `https://kioskbranding.blob.core.windows.net/images/${
          logo || "promro_logo.png"
        }`,
      }}
      alt=""
      {...props}
    />
  );
}

export default Logo;
