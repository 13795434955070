import { useContext } from "react";
import TabContext from "../TabContext";
import { Button } from "@rneui/themed";
import styled from "styled-components/native";
import React from "react";
import { remToDp } from "../../../utilities/responsive";

const Container = styled(Button)`
  border-width: ${(props) => (props.selected ? "1px" : "0px")};
  border-bottom-width: ${(props) => (props.selected ? "0px" : "1px")};
  border-color: #dee2e6;
  opacity: ${(props) => (props.disabled ? 0.3 : 1)};
  border-top-left-radius: ${remToDp(0.375)}px;
  border-top-right-radius: ${remToDp(0.375)}px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
`;

function Tab({ eventKey, onPress, title, ...props }) {
  const { activeKey, onSelect } = useContext(TabContext);

  return (
    <Container
      type="transparent"
      {...props}
      onPress={(evt) => {
        onSelect && onSelect(eventKey);
        onPress && onPress(evt);
      }}
      selected={activeKey === eventKey}
    >
      {title}
    </Container>
  );
}

export default Tab;
