import { useStoreState } from "easy-peasy";
import Nav from "./Nav";
import Icon from "../Icon";

function NonroutineNav(props) {
  const { hidePartRequest } = useStoreState(
    (state) => state.employee.scannedEmployee
  );

  return (
    <Nav
      buttons={[
        {
          children: <Icon.GoToTaskHomePage />,
          show: true,
        },
        {
          children: <Icon.GoToPartsRequestPage />,
          show: !hidePartRequest,
        },
      ]}
      {...props}
    />
  );
}

export default NonroutineNav;
