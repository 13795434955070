import { useStoreActions } from "easy-peasy";
import Icon from "../../presentational/Icon";
import Navbar from "../../presentational/Navbar";

function StopAllWorkIcon(props) {
  const { stopAllTasksThunk } = useStoreActions((actions) => actions);
  return (
    <Navbar.Item
      icon={<Icon.Stop />}
      text="Stop All Work"
      onPress={() =>
        stopAllTasksThunk({
          process: "incompleteAll",
        })
      }
      {...props}
    />
  );
}

export default StopAllWorkIcon;
