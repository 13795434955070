import styled from "styled-components/native";
import Text from "../Text";
import React from "react";
import { remToDp } from "../../../utilities/responsive";

const Container = styled.View`
  flex: 1;
  padding: ${remToDp(1)}px;
  border: ${(props) => (props.border ? "1px solid #dee2e6" : "0")};
  justify-content: center;
  align-items: center;
`;
Container.Text = styled(Text)`
  font-size: ${remToDp(1.25)}px;
`;

function Td({ children, ...props }) {
  return (
    <Container {...props}>
      {typeof children === "string" ? (
        <Container.Text style={props.style}>{children}</Container.Text>
      ) : (
        children
      )}
    </Container>
  );
}

export default Td;
