import Input from "../../presentational/Input";
import { useStoreActions, useStoreState } from "easy-peasy";
import { View } from "react-native";
import { useScreenDimensions } from "../../../utilities/responsive";
import Text from "../../presentational/Text";
import Gap from "../../presentational/Gap";

function AddUnserviceableStockItemForm(props) {
  const { vhToDp } = useScreenDimensions();

  const { note, ...unserviceableStockItemInput } = useStoreState(
    (state) => state.part.unserviceableStockItemInput
  );

  const { setUnserviceableStockItemInputAction } = useStoreActions(
    (actions) => actions
  );

  return (
    <View {...props}>
      {[
        { label: "Inventory ID", stateProp: "InventoryID" },
        { label: "Description", stateProp: "Description" },
      ].map(({ label, stateProp }, index) => (
        <View key={index}>
          <Input
            label={
              <Text>
                <Text.Error>*</Text.Error> {label}
              </Text>
            }
            placeholder={`${label} (required)`}
            value={unserviceableStockItemInput[stateProp]}
            onChangeText={(value) =>
              setUnserviceableStockItemInputAction({ [stateProp]: value })
            }
          />

          <Gap />
        </View>
      ))}

      <Input.TextArea
        label="Notes for Purchasing"
        placeholder="Notes"
        value={note}
        onChangeText={(note) => setUnserviceableStockItemInputAction({ note })}
        inputStyle={{ height: vhToDp(8) }}
      />
    </View>
  );
}

export default AddUnserviceableStockItemForm;
