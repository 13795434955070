import { useStoreActions, useStoreState } from "easy-peasy";
import Button from "../../presentational/Button";

function ShowClockOutModalButton({ children, ...props }) {
  const {
    employee: {
      scannedEmployee: { BiometricAlternative, customLabels },
    },
    status: { hasWifi },
  } = useStoreState((state) => state);

  const {
    showFaceScanModalAction,
    showFaceScanAlternativeModalAction,
    setIsClockingInAction,
  } = useStoreActions((actions) => actions);

  return (
    <Button
      color="#198754"
      fullWidth
      onPress={() => {
        if (hasWifi) {
          setIsClockingInAction(false);
          BiometricAlternative
            ? showFaceScanAlternativeModalAction(true)
            : showFaceScanModalAction(true);
        }
      }}
      disabled={!hasWifi}
      {...props}
    >
      {children ||
        (hasWifi
          ? customLabels.facialRecognition.KronosOut
          : `Can Only ${customLabels.facialRecognition.KronosOut} Online`)}
    </Button>
  );
}

export default ShowClockOutModalButton;
