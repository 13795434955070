import Pages from "./Pages";
import HomePage from "./HomePage";
import NonroutinePage from "./NonroutinePage";
import PartsRequestPage from "./PartsRequestPage";
import ProjectSearchPage from "./ProjectSearchPage";
import TaskPage from "../containers/Page/TaskPage";

Pages.Home = HomePage;
Pages.Nonroutine = NonroutinePage;
Pages.PartsRequest = PartsRequestPage;
Pages.ProjectSearch = ProjectSearchPage;
Pages.Task = TaskPage;

export default Pages;
