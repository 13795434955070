import { action } from "easy-peasy";
import { defaultState } from "./state";
import { Storage } from "../../utilities/Storage";

const actions = {
  setLoginInputAction: action((state, input) => {
    state.auth.loginInput = {
      ...state.auth.loginInput,
      ...input,
    };
  }),
  resetLoginInputAction: action((state) => {
    state.auth.loginInput = defaultState.loginInput;
  }),
  setUserAction: action((state, user) => {
    state.auth.user = {
      ...state.auth.user,
      ...user,
    };
  }),
  resetUserAction: action((state) => {
    state.auth.user = defaultState.user;
  }),
  setLogoAction: action((state, logo) => {
    state.auth.user.logo = logo;
    Storage.setItem("logo", logo);
  }),
  setFaviconAction: action((state, favicon) => {
    state.auth.user.favicon = favicon;
    Storage.setItem("favicon", favicon);
  }),
  setTenantAction: action((state, tenant) => {
    state.auth.user.tenant = tenant;
    Storage.setItem("tenant", tenant);
  }),
  setSpinnerAction: action((state, spinner) => {
    state.auth.user.spinner = spinner;
    Storage.setItem("spinner", spinner);
  }),
  showForcedLogoutModalAction: action((state, show) => {
    state.auth.showForcedLogoutModal = show;
  }),
};

export default actions;
