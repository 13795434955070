import { useState } from "react";
import NavbarC from "../Navbar";
import ProtectedPage from "./ProtectedPage";
import styled from "styled-components/native";
import { Platform } from "react-native";
import { useStoreState } from "easy-peasy";
import { useScreenDimensions } from "../../../utilities/responsive";

const ChildrenContainer = styled.ScrollView`
  height: ${(props) =>
    props.height - props.bottomNavbarHeight - props.topNavbarHeight}px;
  width: 100%;
  max-width: ${(props) => props.maxWidth};
  margin: auto;
`;

function NonTaskPage({ children, Navbar, ...props }) {
  const { dimensions, vwToDp } = useScreenDimensions();

  const {
    layout: { topNavbarHeight },
    employee: {
      scannedEmployee: { showSidePanel },
    },
  } = useStoreState((state) => state);

  const [bottomNavbarHeight, setBottomNavbarHeight] = useState(0);

  const NavbarComponent = Navbar || NavbarC.Home;

  return (
    <ProtectedPage {...props}>
      <ChildrenContainer
        height={dimensions.height}
        topNavbarHeight={topNavbarHeight}
        bottomNavbarHeight={bottomNavbarHeight}
        maxWidth={
          !showSidePanel || Platform.OS !== "web" ? `${vwToDp(70)}px` : "100%"
        }
      >
        {children}
      </ChildrenContainer>

      <NavbarComponent
        onLayout={(event) =>
          setBottomNavbarHeight(event.nativeEvent.layout.height)
        }
      />
    </ProtectedPage>
  );
}

export default NonTaskPage;
