import { useStoreActions, useStoreState } from "easy-peasy";
import LoginPage from "./LoginPage";
import Page from "./Page";
import { useEffect } from "react";
import { useIsFocused } from "@react-navigation/native";
import BadgePage from "./BadgePage";
import EnvironmentPage from "./EnvironmentPage";
import InactiveTimer from "../InactiveTimer";
import Row from "../../presentational/Row";
import Col from "../../presentational/Col";
import Sidebar from "../Sidebar";
import Drawer from "../Drawer";
import { Platform } from "react-native";

function ProtectedPage({ children, ...props }) {
  const { auth, employee, status, task, layout } = useStoreState(
    (state) => state
  );

  const isFocused = useIsFocused();

  const {
    getAuthenticatedUserThunk,
    showSyncAppAlertAction,
    checkTaskLimitReachedAction,
    getWeatherThunk,
  } = useStoreActions((actions) => actions);

  useEffect(() => {
    isFocused && getAuthenticatedUserThunk();
  }, [isFocused]);

  useEffect(() => {
    isFocused &&
      status.hasWifi &&
      status.pendingOperations.length &&
      employee.scannedEmployee.EmployeeID &&
      showSyncAppAlertAction(true);
  }, [
    isFocused,
    status.hasWifi,
    status.pendingOperations.length,
    employee.scannedEmployee.EmployeeID,
  ]);

  useEffect(() => {
    checkTaskLimitReachedAction();
  }, [task.list.length]);

  useEffect(() => {
    employee.scannedEmployee.showSidePanel && isFocused && getWeatherThunk();
  }, [employee.scannedEmployee.showSidePanel, isFocused]);

  const isStsTenant =
    Boolean(process.env.EXPO_PUBLIC_STS_TENANT) === true &&
    process.env.EXPO_PUBLIC_STS_TENANT === "sts";

  return (
    <Page {...props}>
      {Boolean(auth.user.tenant) === false &&
        (Platform.OS === "web" ? !isStsTenant : true) && <LoginPage />}
      {Boolean(auth.user.tenant) === false &&
        (Platform.OS === "web" ? isStsTenant : false) && <EnvironmentPage />}
      {Boolean(auth.user.tenant) === true &&
        !employee.scannedEmployee.EmployeeID && <BadgePage />}
      {Boolean(auth.user.tenant) === true &&
        Boolean(employee.scannedEmployee.EmployeeID) === true &&
        (!status.showSyncAppAlert ||
          status.pendingOperations.filter((op) => op.failed).length > 0) && (
          <>
            <InactiveTimer />

            {layout.showSidebarAsDrawer &&
              Boolean(employee.scannedEmployee.showSidePanel) === true && (
                <>
                  <Drawer>
                    <Sidebar />
                  </Drawer>
                  {children}
                </>
              )}

            {!layout.showSidebarAsDrawer &&
              Boolean(employee.scannedEmployee.showSidePanel) === true && (
                <Row>
                  <Col xs={6} sm={6}>
                    <Sidebar />
                  </Col>
                  <Col xs={6} sm={6}>
                    {children}
                  </Col>
                </Row>
              )}

            {Boolean(employee.scannedEmployee.showSidePanel) === false &&
              children}
          </>
        )}
    </Page>
  );
}

export default ProtectedPage;
