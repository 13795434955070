import styled from "styled-components/native";
import Td from "./Td";
import Th from "./Th";
import Tr from "./Tr";

const Table = styled.View`
  width: 100%;
`;

Table.th = Th;
Table.td = Td;
Table.tr = Tr;
export default Table;
