import { useStoreActions } from "easy-peasy";
import styled from "styled-components/native";
import Text from "../../presentational/Text";
import { remToDp } from "../../../utilities/responsive";

const Container = styled.Pressable`
  background-color: #e49a3a;
  padding: ${remToDp(1)}px;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

const SText = styled(Text)`
  color: #ffffff;
`;

function ShowAddPartRequestModalButton({ children, onPress, ...props }) {
  const { showAddPartRequestModalAction, showPartSearchResultsModalAction } =
    useStoreActions((actions) => actions);

  return (
    <Container
      onPress={() => {
        onPress();
        showPartSearchResultsModalAction(false);
        showAddPartRequestModalAction(true);
      }}
      {...props}
    >
      {children || <SText>Add To Request</SText>}
    </Container>
  );
}

export default ShowAddPartRequestModalButton;
