import { ActivityIndicator } from "react-native";
import styled from "styled-components/native";
import Image from "../presentational/Image";
import { useLoadingSpinner } from "../../hooks/status";
import { Center } from "@cfbs/cfbsstrap-native";

const SpinnerImage = styled(Image)`
  width: 200px;
  height: 200px;
  align-self: center;
`;

function Content({ loading, children, ...props }) {
  const spinner = useLoadingSpinner();

  if (loading)
    return (
      <Center>
        {spinner ? (
          <SpinnerImage
            source={{
              uri: `https://kioskbranding.blob.core.windows.net/images/${spinner}`,
            }}
            {...props}
          />
        ) : (
          <ActivityIndicator size="large" color="#AB2328" {...props} />
        )}
      </Center>
    );
  else return children;
}

export default Content;
