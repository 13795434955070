import styled from "styled-components/native";
import { useProjectList } from "../../../hooks/project";
import Project from "../Project";
import Text from "../../presentational/Text";

const Container = styled.View`
  padding: 8px;
`;

function ProjectList(props) {
  const list = useProjectList();
  return (
    <Container {...props}>
      {(list || []).length ? (
        list.map((task, index) => <Project key={index} {...task} />)
      ) : (
        <Text.NotFound>
          No <Text.Emphasized>Projects</Text.Emphasized> Found
        </Text.NotFound>
      )}
    </Container>
  );
}

export default ProjectList;
