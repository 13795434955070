import Input from "../Input";
import { useStoreState } from "easy-peasy";
import Icon from "../Icon";
import Navbar from "../../presentational/Navbar";
import Text from "../../presentational/Text";
import Center from "../../presentational/Center";
import Button from "../../presentational/Button";

function HomeNavbar(props) {
  const {
    employee: {
      scannedEmployee: {
        hideSearchTasks,
        facialRecogition,
        KioskRole,
        SecondaryFacialRecogRequired,
        KronosClockIn,
        hideStopAllWork,
        timeActivity,
        TANoteID,
        SkipFacialRecogition,
      },
    },
    task: { list, taskLimitReached },
  } = useStoreState((state) => state);

  const hasEnabledFacialRecogition =
    facialRecogition && SecondaryFacialRecogRequired && !SkipFacialRecogition;
  const isClockedIn = hasEnabledFacialRecogition && KronosClockIn;
  const hasSufficientFaceRole = isClockedIn && KioskRole !== "Not Applicable";
  const showIconOnEmployeeTasks =
    hasSufficientFaceRole ||
    !facialRecogition ||
    !SecondaryFacialRecogRequired ||
    SkipFacialRecogition;
  const hasScannedTasks =
    list.filter((task) => !task.needsToBeSynced).length > 0;
  const showKronosOut =
    showIconOnEmployeeTasks &&
    ((timeActivity && TANoteID) || !timeActivity) &&
    hasSufficientFaceRole &&
    !hasScannedTasks;

  const buttons = [
    {
      children: <Icon.GoToSearchTasksPage />,
      show:
        !taskLimitReached &&
        !hideSearchTasks &&
        ((hasEnabledFacialRecogition && isClockedIn) ||
          !hasEnabledFacialRecogition),
    },
    {
      children: taskLimitReached ? (
        <Center>
          <Text.Error>Open Task Limit Reached</Text.Error>
        </Center>
      ) : (
        <Input.Task />
      ),
      show: taskLimitReached || showIconOnEmployeeTasks,
    },
  ];

  hasScannedTasks &&
    buttons.push({
      children: <Icon.StopAllWork />,
      show: !hideStopAllWork && showIconOnEmployeeTasks,
    });

  buttons.push({
    children: <Icon.SignOut />,
    show: true,
  });

  return (
    <Navbar buttons={buttons} {...props}>
      {showKronosOut && <Button.ShowClockOutModal />}
    </Navbar>
  );
}

export default HomeNavbar;
