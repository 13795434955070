import Content from "../Content";
import styled from "styled-components/native";
import List from "../List";
import { useItemRequirementsTab } from "../../../hooks/boat";
import { useScreenDimensions } from "../../../utilities/responsive";

const ScrollView = styled.ScrollView`
  max-height: ${(props) => props.maxHeight}px;
  width: 100%;
`;

function ItemRequirementsTab() {
  const loading = useItemRequirementsTab();
  const { vhToDp } = useScreenDimensions();
  return (
    <Content loading={loading}>
      <ScrollView horizontal>
        <ScrollView maxHeight={vhToDp(30)}>
          <List.BoatItemRequirement />
        </ScrollView>
      </ScrollView>
    </Content>
  );
}

export default ItemRequirementsTab;
