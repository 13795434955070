import { useStoreActions } from "easy-peasy";
import Button from "../../presentational/Button";

function ShowAddUnserviceableStockItemModalButton({ children, ...props }) {
  const {
    showAddUnserviceableStockItemModalAction,
    showPartSearchResultsModalAction,
  } = useStoreActions((actions) => actions);
  return (
    <Button
      onPress={() => {
        showPartSearchResultsModalAction(false);
        showAddUnserviceableStockItemModalAction(true);
      }}
      color="#ffc107"
      fullWidth
      {...props}
    >
      {children || "Add To Unserviceable Part Request"}
    </Button>
  );
}

export default ShowAddUnserviceableStockItemModalButton;
