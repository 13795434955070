import { useStoreActions, useStoreState } from "easy-peasy";
import DrawerView from "../presentational/Drawer";

function Drawer({ children, ...props }) {
  const { openSidebarDrawer } = useStoreState((state) => state.layout);

  const { openSidebarDrawerAction } = useStoreActions((actions) => actions);

  return (
    <DrawerView
      show={openSidebarDrawer}
      onClose={() => openSidebarDrawerAction(false)}
      {...props}
    >
      {children}
    </DrawerView>
  );
}

export default Drawer;
