const defaultState = {
  list: [],
  searchInput: "",
  taskIdScanInput: "",
  queue: {
    isLocked: false,
    lockSeconds: 0,
    isCountDownActive: false,
  },
  correctiveActionInput: "",
  currentTask: {},
  showInspectionRejectModal: false,
  inspectionRejectReasonInput: "",
  startTime: null,
  showScanTaskBarcodeModal: false,
  taskLimitReached: false,
};

const state = {
  task: defaultState,
};

export { state as default, defaultState };
