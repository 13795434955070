import { SearchBar as RNSearchBar } from "@rneui/themed";
import styled from "styled-components/native";
import Icon from "./Icon";

const SSearchBar = styled(RNSearchBar)`
  padding: 0 16px;
  width: 100%;
`;

function SearchBar(props) {
  return (
    <SSearchBar
      containerStyle={{
        backgroundColor: "white",
        borderTopWidth: 0,
        borderBottomWidth: 0,
        width: "100%",
      }}
      inputContainerStyle={{ backgroundColor: "#F7F7F7", width: "100%" }}
      searchIcon={<Icon.Search />}
      clearIcon={<Icon.Clear />}
      {...props}
    />
  );
}

export default SearchBar;
