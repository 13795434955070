import { useStoreState } from "easy-peasy";
import Nav from "./Nav";
import Icon from "../Icon";

function TaskNav(props) {
  const {
    task: {
      currentTask: { CreateNRFromNR, TaskStatus },
    },
    employee: {
      scannedEmployee: {
        hidePartRequest,
        hideNonRoutine,
        hidePartsRequestOnInspection,
      },
    },
  } = useStoreState((state) => state);

  return (
    <Nav
      buttons={[
        {
          children: <Icon.GoToNonRoutinePage />,
          show: !hideNonRoutine && CreateNRFromNR.value === "YES",
        },
        {
          children: <Icon.GoToPartsRequestPage />,
          show:
            (hidePartsRequestOnInspection &&
              TaskStatus.value === "Inspection") ||
            hidePartRequest
              ? false
              : true,
        },
      ]}
      isOnTaskHomepage
      {...props}
    />
  );
}

export default TaskNav;
