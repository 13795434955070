import List from "./List";
import EmployeeTaskList from "./EmployeeTaskList";
import PartRequestList from "./PartRequestList";
import StockItemSearchResultsList from "./StockItemSearchResultsList";
import EnvironmentUkList from "./EnvironmentUkList";
import EnvironmentUsList from "./EnvironmentUsList";
import EnvironmentDubaiList from "./EnvironmentDubaiList";
import EnvironmentIrelandList from "./EnvironmentIrelandList";

List.EmployeeTask = EmployeeTaskList;
List.PartRequest = PartRequestList;
List.StockItemSearchResults = StockItemSearchResultsList;
List.EnvironmentUk = EnvironmentUkList;
List.EnvironmentUs = EnvironmentUsList;
List.EnvironmentDubai = EnvironmentDubaiList;
List.EnvironmentIreland = EnvironmentIrelandList;

export default List;
