import Row from "../../presentational/Row";
import Col from "../../presentational/Col";
import Input from "../../presentational/Input";
import { useStoreActions, useStoreState } from "easy-peasy";
import DatePicker from "../../presentational/DatePicker";
import moment from "moment";
import { ScrollView } from "react-native";
import { useScreenDimensions } from "../../../utilities/responsive";
import styled from "styled-components/native";
import Option from "../../presentational/Option";

const Container = styled(ScrollView)`
  max-height: ${(props) => props.maxHeight};
`;

function AddUnserviceablePartRequestForm(props) {
  const {
    part: {
      unserviceablePartInput: {
        Quantity,
        Position,
        SerialNbr,
        StateShelfLife,
        Description,
        Date: DateInput,
        Station,
        Owner,
        TechLogRef,
        Operator,
        ReasonforRemoval,
      },
      chosenStockItem,
      shelfLifeOptions,
    },
    employee: { scannedEmployee },
  } = useStoreState((state) => state);

  const { setUnserviceablePartInputAction } = useStoreActions(
    (actions) => actions
  );

  const { vhToDp } = useScreenDimensions();

  return (
    <Container maxHeight={vhToDp(80)}>
      <Row {...props}>
        <Col xs={12} md={6}>
          <Input
            label={scannedEmployee.customLabels.unserviceablePart.PartNbr}
            value={chosenStockItem.InventoryCD}
            editable={false}
          />
        </Col>

        <Col xs={12} md={6}>
          <Input
            label={scannedEmployee.customLabels.unserviceablePart.SerialNbr}
            placeholder={`Enter ${scannedEmployee.customLabels.unserviceablePart.SerialNbr}`}
            value={SerialNbr}
            onChangeText={(SerialNbr) =>
              setUnserviceablePartInputAction({ SerialNbr })
            }
            required
          />
        </Col>

        <Col xs={12} md={6}>
          {shelfLifeOptions ? (
            <Input.Select
              label={
                scannedEmployee.customLabels.unserviceablePart.ModStateShelfLife
              }
              value={StateShelfLife}
              placeholder={`Select ${scannedEmployee.customLabels.unserviceablePart.ModStateShelfLife}`}
              header={`Select ${scannedEmployee.customLabels.unserviceablePart.ModStateShelfLife}`}
              options={shelfLifeOptions.map((option) => ({
                ...option,
                children: (
                  <Option id={option.ConditionID}>
                    {option.ConditionDescription}
                  </Option>
                ),
              }))}
              onChange={(option) =>
                setUnserviceablePartInputAction({
                  StateShelfLife: option.ConditionID,
                })
              }
              {...props}
            />
          ) : (
            <Input
              label={
                scannedEmployee.customLabels.unserviceablePart.ModStateShelfLife
              }
              placeholder={`Enter ${scannedEmployee.customLabels.unserviceablePart.ModStateShelfLife}`}
              value={StateShelfLife}
              onChangeText={(StateShelfLife) =>
                setUnserviceablePartInputAction({ StateShelfLife })
              }
              required
            />
          )}
        </Col>

        <Col xs={12} md={6}>
          <Input
            label={scannedEmployee.customLabels.unserviceablePart.Position}
            placeholder={`Enter ${scannedEmployee.customLabels.unserviceablePart.Position}`}
            value={Position}
            onChangeText={(Position) =>
              setUnserviceablePartInputAction({ Position })
            }
          />
        </Col>

        <Col xs={12} md={6}>
          <Input
            label={scannedEmployee.customLabels.unserviceablePart.Quantity}
            value={`${Quantity}`}
            onChangeText={(Quantity) =>
              setUnserviceablePartInputAction({
                Quantity: Quantity.replace(/[^0-9]/g, ""),
              })
            }
            placeholder="Enter Quantity"
            required
          />
        </Col>

        <Col xs={12} md={6}>
          <DatePicker
            label={scannedEmployee.customLabels.unserviceablePart.Date}
            value={DateInput ? moment(DateInput).format("L") : ""}
            onChange={(Date) => setUnserviceablePartInputAction({ Date })}
            placeholder="mm/dd/yyyy"
            required
          />
        </Col>

        <Col xs={12} md={6}>
          <Input
            label={scannedEmployee.customLabels.unserviceablePart.Station}
            placeholder={`Enter ${scannedEmployee.customLabels.unserviceablePart.Station}`}
            value={Station}
            onChangeText={(Station) =>
              setUnserviceablePartInputAction({ Station })
            }
            required
          />
        </Col>

        <Col xs={12} md={6}>
          <Input
            label={scannedEmployee.customLabels.unserviceablePart.Owner}
            placeholder={`Enter ${scannedEmployee.customLabels.unserviceablePart.Owner}`}
            value={Owner}
            onChangeText={(Owner) => setUnserviceablePartInputAction({ Owner })}
            required
          />
        </Col>

        <Col xs={12} md={6}>
          <Input
            label={scannedEmployee.customLabels.unserviceablePart.TechLogRef}
            placeholder={`Enter ${scannedEmployee.customLabels.unserviceablePart.TechLogRef}`}
            value={TechLogRef}
            onChangeText={(TechLogRef) =>
              setUnserviceablePartInputAction({ TechLogRef })
            }
            required
          />
        </Col>

        <Col xs={12} md={6}>
          <Input
            label={scannedEmployee.customLabels.unserviceablePart.Operator}
            placeholder={`Enter ${scannedEmployee.customLabels.unserviceablePart.Operator}`}
            value={Operator}
            onChangeText={(Operator) =>
              setUnserviceablePartInputAction({ Operator })
            }
            required
          />
        </Col>

        <Col xs={12}>
          <Input
            label={scannedEmployee.customLabels.unserviceablePart.Description}
            placeholder={
              scannedEmployee.customLabels.unserviceablePart
                .DescriptionPlaceholder
            }
            value={Description}
            onChangeText={(Description) =>
              setUnserviceablePartInputAction({ Description })
            }
            required
          />
        </Col>

        <Col xs={12}>
          <Input.TextArea
            label={scannedEmployee.customLabels.unserviceablePart.RemovalReason}
            placeholder={
              scannedEmployee.customLabels.unserviceablePart
                .RemovalReasonPlaceholder
            }
            value={ReasonforRemoval}
            onChangeText={(ReasonforRemoval) =>
              setUnserviceablePartInputAction({
                ReasonforRemoval: ReasonforRemoval.slice(0, 250),
              })
            }
            required
          />
        </Col>
      </Row>
    </Container>
  );
}

export default AddUnserviceablePartRequestForm;
