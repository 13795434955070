import { useStoreActions, useStoreState } from "easy-peasy";
import Task from "../../presentational/Task";
import { useNavigation } from "@react-navigation/native";
import {
  PAGE_TASK,
  QUALIFICATION_ERROR_MESSAGE,
} from "../../../utilities/variables";
import { caseInsensitiveFilter } from "../../../utilities/string";

function EmployeeTaskList(props) {
  const navigation = useNavigation();

  const {
    task: { list, searchInput, currentTask },
    employee: { scannedEmployee, usingCentrik, qualifications },
    status: { hasWifi },
  } = useStoreState((state) => state);

  const {
    setCurrentTaskAction,
    setStockItemsAction,
    setAlertThunk,
    getEquipmentDetailsOnTaskQueueThunk,
    setSubtypeSearchResultsAction,
    setBoatItemRequirementsAction,
    setChosenProjectTaskAction,
  } = useStoreActions((actions) => actions);

  return (
    <Task.List
      list={list
        .filter(
          (task) =>
            caseInsensitiveFilter(
              [
                task.ProjectTaskDescription.value,
                task.ProjectDescription.value,
                task.ProjectTaskID.value,
              ],
              searchInput
            ) && !task.needsToBeSynced
        )
        .map((task) => ({
          ProjectDescription: task.ProjectDescription.value,
          ProjectTaskID: task.ProjectTaskID.value,
          ProjectTaskDescription: task.ProjectTaskDescription.value,
          TimeUTC: task.TimeUTC.value,
          TaskStatus: task.TaskStatus.value,
          onPress: async () => {
            if (!usingCentrik || qualifications.mechanicQualified) {
              setCurrentTaskAction(task);

              if (currentTask) {
                if (
                  currentTask.ProjectTaskID &&
                  task.ProjectTaskID.value !== currentTask.ProjectTaskID.value
                ) {
                  setStockItemsAction([]);
                  setBoatItemRequirementsAction([]);
                  setChosenProjectTaskAction(null);
                }

                currentTask.WorkOrderID &&
                  task.WorkOrderID.value !== currentTask.WorkOrderID.value &&
                  setSubtypeSearchResultsAction([]);
              }

              hasWifi &&
                scannedEmployee.equipmentDetailOnTaskQueue &&
                (await getEquipmentDetailsOnTaskQueueThunk({
                  WorkOrderID: task.WorkOrderID.value,
                  ProjectTaskID: task.ProjectTaskID.value,
                }));

              navigation.navigate(PAGE_TASK);
            } else {
              setAlertThunk({
                type: "warning",
                message: QUALIFICATION_ERROR_MESSAGE,
              });
            }
          },
        }))}
      message={scannedEmployee.customLabels.taskList.None}
      {...props}
    />
  );
}

export default EmployeeTaskList;
