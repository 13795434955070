const defaultState = {
  loginInput: {
    email: "",
    password: "",
  },
  user: {
    email: "",
    username: "",
    logo: "",
    tenant: "",
    favicon: "",
    spinner: "",
    autoLogout: false,
    autoLogoutMinutes: "0",
  },
  showForcedLogoutModal: false,
};

const state = {
  auth: defaultState,
};

export { state as default, defaultState };
