import { useStoreActions, useStoreState } from "easy-peasy";
import Option from "../../presentational/Option";
import SearchBar from "../SearchBar";
import Input from "../../presentational/Input";
import { caseInsensitiveFilter } from "../../../utilities/string";

function SubchapterInput(props) {
  const {
    nonRoutine: {
      subChapters,
      subchapterSearchInput,
      nonroutineInput: { ATAChapterID, ATASubChapterID },
    },
    employee: { scannedEmployee },
  } = useStoreState((state) => state);

  const { setNonroutineInputAction } = useStoreActions((actions) => actions);

  return (
    <Input.Select
      label={scannedEmployee.customLabels.nonRoutine.Subchapter}
      value={ATASubChapterID || ""}
      placeholder={scannedEmployee.customLabels.nonRoutine.Subchapter}
      header={`Select ${scannedEmployee.customLabels.nonRoutine.Subchapter}`}
      SearchBar={<SearchBar.Subchapter />}
      disabled={!ATAChapterID}
      options={subChapters
        .filter(
          (sc) =>
            sc.ChapterID === ATAChapterID &&
            caseInsensitiveFilter(
              [sc.SubChapterID, sc.Description],
              subchapterSearchInput
            )
        )
        .map((sc) => ({
          ...sc,
          children: <Option.Nonroutine ID={sc.SubChapterID} {...sc} />,
        }))}
      onChange={(option) =>
        setNonroutineInputAction({
          ATASubChapterID: option.SubChapterID,
        })
      }
      {...props}
    />
  );
}

export default SubchapterInput;
