import { useStoreActions, useStoreState } from "easy-peasy";
import Button from "../../presentational/Button";

function AddUnserviceablePartRequestButton({ children, ...props }) {
  const {
    part: {
      unserviceablePartInput: {
        Quantity,
        SerialNbr,
        StateShelfLife,
        Description,
        Date,
        Station,
        Owner,
        TechLogRef,
        Operator,
        ReasonforRemoval,
      },
    },
    employee: { scannedEmployee },
  } = useStoreState((state) => state);

  const { addUnserviceablePartRequestThunk } = useStoreActions(
    (actions) => actions
  );

  const hiddenFields = scannedEmployee.hiddenFields
    ? scannedEmployee.hiddenFields.unserviceablePart
    : [];

  return (
    <Button
      disabled={
        Quantity <= 0 ||
        !SerialNbr.trim() ||
        !StateShelfLife.trim() ||
        !Description.trim() ||
        !Date.trim() ||
        !Station.trim() ||
        ((!hiddenFields.length || !hiddenFields.includes("Owner")) &&
          !Owner.trim()) ||
        !TechLogRef.trim() ||
        ((!hiddenFields.length || !hiddenFields.includes("Operator")) &&
          !Operator.trim()) ||
        !ReasonforRemoval.trim()
      }
      color="#198754"
      onPress={() => addUnserviceablePartRequestThunk()}
      {...props}
    >
      {children || "Submit"}
    </Button>
  );
}

export default AddUnserviceablePartRequestButton;
