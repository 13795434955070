import Page from "../containers/Page";
import Form from "../containers/Form";
import Nav from "../containers/Nav";
import { useStoreState } from "easy-peasy";

function NonroutinePage(props) {
  const {
    employee: { scannedEmployee },
    task: { currentTask },
  } = useStoreState((state) => state);

  return currentTask ? (
    <Page.Task {...props}>
      {scannedEmployee.notAviation ? (
        <Form.AddServiceTask />
      ) : (
        <Form.AddNonroutineTask />
      )}
      <Nav.Nonroutine />
    </Page.Task>
  ) : (
    ""
  );
}

export default NonroutinePage;
