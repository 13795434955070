import { action } from "easy-peasy";
import { defaultState } from "./state";

const actions = {
  setBoatSearchInputAction: action((state, input) => {
    state.boat.searchInput = input;
  }),
  setBoatSearchResultsAction: action((state, list) => {
    state.boat.boatSearchResults = list;
  }),
  setChosenBoatAction: action((state, boat) => {
    state.boat.chosenBoat = boat;
  }),
  setSubtypeSearchResultsAction: action((state, list) => {
    state.boat.subtypeSearchResults = list;
  }),
  setChosenSubtypeAction: action((state, subtype) => {
    state.boat.chosenSubtype = subtype;
  }),
  setSubtypeAttributeInputAction: action((state, input) => {
    state.boat.subtypeAttributeInput = input;
  }),
  resetBoatStateAction: action((state) => {
    state.boat = defaultState;
  }),
  updateSubtypeAttributeValueOfflineAction: action(
    (state, { SubTypeID, AttributeCD, NewValue }) => {
      const index = state.boat.subtypeSearchResults.findIndex(
        (subtype) =>
          subtype.SubTypeID === SubTypeID && subtype.AttributeCD === AttributeCD
      );
      state.boat.subtypeSearchResults = [
        ...state.boat.subtypeSearchResults.slice(0, index),
        {
          ...state.boat.subtypeSearchResults[index],
          NewValue,
        },
        ...state.boat.subtypeSearchResults.slice(index + 1),
      ];
    }
  ),
  setEquipmentAction: action((state, equipment) => {
    state.boat.equipment = equipment;
  }),
  setBoatItemRequirementsAction: action((state, list) => {
    state.boat.boatItemRequirements = list;
  }),
  setShowEquipmentDetailsModalAction: action((state, show) => {
    state.boat.showEquipmentDetailsModal = show;
  }),
};

export default actions;
