import React from "react";
import Modal from "./Modal";
import { useConfirmDuplicateInspectionModal } from "../../../hooks/nonroutine";
import Button from "../../presentational/Button";
import Gap from "../../presentational/Gap";
import Div from "../Div";

function ConfirmDuplicateInspectionModal(props) {
  const {
    projectTaskId,
    buttonLabel,
    onPressAddNonroutineTask,
    ...modalProps
  } = useConfirmDuplicateInspectionModal();

  return (
    <Modal {...modalProps} {...props}>
      <Modal.Header onRequestClose={modalProps.onRequestClose}>
        Confirm Duplicate Inspection
      </Modal.Header>

      <Modal.Body>
        <Div>
          You have selected task {projectTaskId} to be marked as duplicate
          inspection. Do you still want to save non-routine task?
        </Div>

        <Gap />

        <Button fullWidth color="#2c6892" onPress={onPressAddNonroutineTask}>
          {buttonLabel}
        </Button>
      </Modal.Body>
    </Modal>
  );
}

export default ConfirmDuplicateInspectionModal;
