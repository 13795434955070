import styled from "styled-components/native";
import Row from "../presentational/Row";
import Col from "../presentational/Col";
import Text from "../presentational/Text";
import { remToDp } from "../../utilities/responsive";
import Center from "../presentational/Center";
import Button from "../presentational/Button";
import { Pressable } from "react-native";

const Container = styled.Pressable`
  background-color: #${(props) => (props.chosen ? "00ad7e" : "f4f4f4")};
  border-color: #ccc;
  border-width: 1px;
  padding: 8px;
  opacity: 1;
`;

const SText = styled(Text)`
  font-size: ${remToDp(1.5)}px;
`;
SText.Main = styled(Text.Primary)`
  font-size: ${remToDp(1.5)}px;
  font-weight: 600;
  color: #014daf;
`;
SText.Status = styled(Text)`
  font-size: ${remToDp(0.75)}px;
  color: #${(props) => (props.chosen ? "fff" : "000")};
`;
SText.Description = styled(Text)`
  font-size: ${remToDp(0.75)}px;
  font-style: italic;
  margin-top: 8px;
  color: #${(props) => (props.chosen ? "fff" : "000")};
`;
SText.ProjectID = styled(SText)`
  text-align: right;
  color: #${(props) => (props.chosen ? "fff" : "000")};
`;

function Project({
  Status,
  Customer,
  EquipmentID,
  Description,
  ProjectID,
  chosen,
  onPressAddProject,
  onPressProjectName,
  ...props
}) {
  const boatMdColNum = 4;
  const startEndMdColNum = onPressAddProject ? boatMdColNum : 6;
  return (
    <Container chosen={chosen} {...props}>
      <SText.Status chosen={chosen}>{Status}</SText.Status>
      <Row>
        <Col xs={12} md={startEndMdColNum}>
          {onPressProjectName ? (
            <Pressable onPress={onPressProjectName}>
              <SText.Main>
                {Customer} - {EquipmentID}
              </SText.Main>
            </Pressable>
          ) : (
            <SText.Main>
              {Customer} - {EquipmentID}
            </SText.Main>
          )}

          <SText.Description chosen={chosen}>{Description}</SText.Description>
        </Col>

        {onPressAddProject && (
          <Col xs={12} md={boatMdColNum}>
            <Center>
              <Button onPress={onPressAddProject}>Add Images</Button>
            </Center>
          </Col>
        )}

        <Col xs={12} md={startEndMdColNum}>
          <SText.ProjectID chosen={chosen}>Project {ProjectID}</SText.ProjectID>
        </Col>
      </Row>
    </Container>
  );
}

export default Project;
