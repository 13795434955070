import React, { useEffect, useRef } from "react";
import { Pressable, Animated } from "react-native";
import Row from "./Row";
import Text from "./Text";
import styled from "styled-components/native";
import { remToDp, useScreenDimensions } from "../../utilities/responsive";

const Container = styled.View`
  background-color: #00000080;
  width: 100%;
  height: ${(props) => props.fullHeight}px;
  position: absolute;
  z-index: 9999;
  overflow: hidden;
`;

Container.Content = styled(Animated.View)`
  flex: 1;
  padding: 20px;
  background-color: #ffffff;
`;

const CloseButton = styled(Row)`
  justify-content: flex-end;
`;

CloseButton.Text = styled(Text)`
  font-size: ${remToDp(1.5)}px;
`;

function Drawer({ children, show, onClose, ...props }) {
  const { vhToDp } = useScreenDimensions();
  const drawerWidth = useRef(new Animated.Value(0)).current;

  function closeDrawer() {
    Animated.timing(drawerWidth, {
      toValue: 0,
      duration: 100,
      useNativeDriver: false,
    }).start(() => {
      onClose();
    });
  }

  useEffect(() => {
    if (show) {
      Animated.timing(drawerWidth, {
        toValue: 1,
        duration: 100,
        useNativeDriver: false,
      }).start();
    } else {
      closeDrawer();
    }
  }, [show]);

  return (
    show && (
      <Container fullHeight={vhToDp(100)}>
        <Container.Content
          style={{
            width: drawerWidth.interpolate({
              inputRange: [0, 1],
              outputRange: ["0%", "75%"],
            }),
          }}
          {...props}
        >
          <CloseButton>
            <Pressable onPress={closeDrawer}>
              <CloseButton.Text>X</CloseButton.Text>
            </Pressable>
          </CloseButton>
          {children}
        </Container.Content>
      </Container>
    )
  );
}

export default Drawer;
