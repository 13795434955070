import Table from "./Table";

function List({
  tableHead = [],
  tableRows = [],
  striped = true,
  hover = true,
  border = true,
  ...props
}) {
  return (
    <Table {...props}>
      <Table.tr>
        {tableHead.map((children, index) => (
          <Table.th key={index} border={border} index={index}>
            {children}
          </Table.th>
        ))}
      </Table.tr>

      {tableRows.map((row, index) => (
        <Table.tr
          key={index}
          striped={striped && index % 2 === 0}
          hover={hover}
        >
          {row.map((children, index) => (
            <Table.td key={index} border={border} index={index}>
              {children}
            </Table.td>
          ))}
        </Table.tr>
      ))}
    </Table>
  );
}

export default List;
