import { useStoreActions, useStoreState } from "easy-peasy";
import Option from "../../presentational/Option";
import SearchBar from "../SearchBar";
import Input from "../../presentational/Input";
import { caseInsensitiveFilter } from "../../../utilities/string";

function LaborItemInput(props) {
  const {
    laborItems,
    laborItemSearchInput,
    nonroutineInput: { laborItemID },
  } = useStoreState((state) => state.nonRoutine);

  const { setNonroutineInputAction } = useStoreActions((actions) => actions);

  return (
    <Input.Select
      label="Labor Item"
      value={laborItemID || ""}
      placeholder="Labor Item"
      inputContainerStyle={{ backgroundColor: "#ffffff" }}
      header="Select Labor Item"
      SearchBar={<SearchBar.LaborItem />}
      options={(laborItems || [])
        .filter((l) =>
          caseInsensitiveFilter(
            [l.InventoryID, l.Description],
            laborItemSearchInput
          )
        )
        .map((l) => ({
          ...l,
          children: <Option.Nonroutine ID={l.InventoryID} {...l} />,
        }))}
      onChange={(option) =>
        setNonroutineInputAction({
          laborItemID: option.InventoryID.trim(),
        })
      }
      {...props}
    />
  );
}

export default LaborItemInput;
