import { View } from "react-native";
import EnvironmentItem from "./EnvironmentItem";

function EnvironmentList({ list, ...props }) {
  return (
    <View {...props}>
      {(list || []).map((environment, index) => (
        <EnvironmentItem key={index} {...environment} />
      ))}
    </View>
  );
}

export default EnvironmentList;
