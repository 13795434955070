import React, { useState } from "react";
import Input from "../Input/Input";
import Calendar from "../Calendar";
import Modal from "../Modal";

function DatePickerMobile({ value, onChange, ...props }) {
  const [showCalendar, setShowCalendar] = useState(false);
  return (
    <>
      <Input
        onFocus={() => setShowCalendar(true)}
        onBlur={() => setShowCalendar(false)}
        value={value}
        {...props}
      />

      <Modal.Calendar
        visible={showCalendar}
        onRequestClose={() => setShowCalendar(false)}
      >
        <Calendar
          onDayPress={(day) => {
            onChange(day.dateString);
            setShowCalendar(false);
          }}
          value={value}
          {...props}
        />
      </Modal.Calendar>
    </>
  );
}

export default DatePickerMobile;
