import { Platform } from "react-native";

const testSites = ["test", "dev", "localhost", "3000"];

const defaultState = {
  badgeIdInput: "",
  scannedEmployee: {
    EmployeeID: "",
    customLabels: {},
  },
  usingCentrik: true,
  isTest:
    Platform.OS === "web"
      ? testSites.some((site) => window.location.href.includes(site))
      : false,
  qualifications: {
    personal: [],
    type: [],
    customer: [],
    mechanicQualified: false,
    inspectorQualified: {},
    boroscopeQualified: false,
    failedQualification: null,
    validities: null,
  },
  showScanBadgeBarcodeModal: false,
};

const state = {
  employee: defaultState,
};

export { state as default, defaultState };
