import Modal from "../../presentational/Modal";
import { useStoreActions, useStoreState } from "easy-peasy";

function useOnClose() {
  const { showScanTaskBarcodeModalAction } = useStoreActions(
    (actions) => actions
  );

  return () => showScanTaskBarcodeModalAction(false);
}

function useOnScanBarcode() {
  const { scanTaskThunk, showScanTaskBarcodeModalAction } = useStoreActions(
    (actions) => actions
  );

  return ({ data }) => {
    scanTaskThunk(data);
    showScanTaskBarcodeModalAction(false);
  };
}

function ScanBadgeBarcodeModal(props) {
  const { showScanTaskBarcodeModal } = useStoreState((state) => state.task);

  const onClose = useOnClose();

  const onScanBarcode = useOnScanBarcode();

  return (
    <Modal.Camera
      visible={showScanTaskBarcodeModal}
      onRequestClose={onClose}
      header="Scan Task Barcode"
      onBarCodeScanned={showScanTaskBarcodeModal ? onScanBarcode : undefined}
      {...props}
    />
  );
}

export default ScanBadgeBarcodeModal;
