import Row from "../Row";
import styled from "styled-components/native";
import Col from "../Col";

const Container = styled(Row)`
  z-index: 999;
  background-color: #ffffff;
`;

function Navbar({ buttons, children, ...props }) {
  const buttonsToShow = (buttons || []).filter(({ show }) => show);
  return (
    <Container {...props}>
      {buttonsToShow.map((button, index) => (
        <Col xs={12} md={6} lg={12 / buttonsToShow.length} key={index}>
          {button.children}
        </Col>
      ))}
      {children}
    </Container>
  );
}

export default Navbar;
