import { useStoreActions, useStoreState } from "easy-peasy";
import Modal from "../../presentational/Modal";
import Text from "../../presentational/Text";
import List from "../List";
import Center from "../../presentational/Center";
import Gap from "../../presentational/Gap";
import Button from "../Button";
import { useScreenDimensions } from "../../../utilities/responsive";
import styled from "styled-components/native";

const ScrollView = styled.ScrollView`
  height: ${(props) => props.height}px;
`;

function useOnClose() {
  const { showPartSearchResultsModalAction } = useStoreActions(
    (actions) => actions
  );
  return () => showPartSearchResultsModalAction(false);
}

function StockItemSearchResultsModal(props) {
  const { vhToDp } = useScreenDimensions();

  const {
    part: { showPartSearchResultsModal, stockItems },
    task: { currentTask },
    employee: { scannedEmployee },
  } = useStoreState((state) => state);

  const onClose = useOnClose();

  const showAddStockItemButton =
    !stockItems.length || !stockItems[0].SimpleItemRequest;

  const showAddUnserviceableStockItemButton =
    scannedEmployee.PartNotFoundEnable && currentTask.unserviceableParts;

  return (
    <Modal
      visible={showPartSearchResultsModal}
      onRequestClose={onClose}
      {...props}
    >
      <Modal.Header onRequestClose={onClose}>
        {scannedEmployee.customLabels.partRequest.SearchTitle} Search Results
      </Modal.Header>

      <Modal.Body>
        <Center>
          <Text.Primary>
            Select the part to add to the Parts Request List
          </Text.Primary>
        </Center>

        <Gap />

        <ScrollView height={vhToDp(60)}>
          <List.StockItemSearchResults />
        </ScrollView>
      </Modal.Body>

      <Modal.Footer>
        {showAddStockItemButton && <Button.ShowAddStockItemRequestModal />}

        {showAddStockItemButton && showAddUnserviceableStockItemButton && (
          <Gap />
        )}

        {showAddUnserviceableStockItemButton && (
          <Button.ShowAddUnserviceableStockItemModal />
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default StockItemSearchResultsModal;
