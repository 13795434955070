import { useStoreActions } from "easy-peasy";
import Icon from "../../presentational/Icon";
import { Camera } from "expo-camera";

function ShowScanTaskBarcodeModalIcon(props) {
  const { showScanTaskBarcodeModalAction } = useStoreActions(
    (actions) => actions
  );
  return (
    <Icon.Barcode
      onPress={() => showScanTaskBarcodeModalAction(true)}
      {...props}
    />
  );
}

export default ShowScanTaskBarcodeModalIcon;
