function handleAcumaticaError(err) {
  let msg = err;

  typeof (msg === "object") && (msg = msg.message);

  if (err.response && err.response.data && err.response.data.message) {
    msg = err.response.data.message;
  } else if (err.response && err.response.data) {
    msg = err.response.data;
    if (typeof err.response.data === "string") {
      const localError = err.response.data;

      const htmlpre = localError.substr(
        localError.indexOf("<pre>") + 5,
        localError.indexOf("</pre>")
      );

      if (htmlpre.length) {
        msg = htmlpre.substring(0, htmlpre.indexOf("<br>"));
      }
    }
  } else if (typeof err === "string") {
    msg = err;
  }

  return msg;
}

export { handleAcumaticaError };
