import { useStoreState } from "easy-peasy";
import Page from "../containers/Page";
import List from "../containers/List";
import PartRequest from "../presentational/PartRequest";
import Button from "../containers/Button";
import Center from "../presentational/Center";
import SearchBar from "../containers/SearchBar";
import Gap from "../presentational/Gap";
import Nav from "../containers/Nav";
import Modal from "../containers/Modal";

function PartsRequestPage(props) {
  const {
    part: { partRequestList },
    task: { currentTask },
  } = useStoreState((state) => state);

  const SimpleItemRequest = partRequestList.length
    ? partRequestList[0].SimpleItemRequest
    : false;

  return currentTask ? (
    <Page.Task {...props}>
      {currentTask.printItemReqManifest && (
        <Center>
          <Button.PrintPartRequestManifest />
        </Center>
      )}

      <Gap />

      <SearchBar.Part />

      {partRequestList.length > 0 && (
        <>
          <Gap />
          <PartRequest.Header
            headers={[
              "Inventory CD",
              SimpleItemRequest ? "" : "Priority Lvl.",
              SimpleItemRequest ? "" : "Need By",
              "Quantity",
              SimpleItemRequest ? "" : "Status",
            ]}
          />
        </>
      )}

      <Gap />

      <List.PartRequest />

      <Nav.PartsRequest />

      <Modal.StockItemSearchResults />
      <Modal.AddUnserviceablePartRequest />
      <Modal.AddPartRequest />
      <Modal.AddUnserviceableStockItem />
      <Modal.AddStockItemRequest />
    </Page.Task>
  ) : (
    ""
  );
}

export default PartsRequestPage;
