import { useStoreActions } from "easy-peasy";
import Icon from "../../presentational/Icon";
import { useNavigation } from "@react-navigation/native";

function StopWorkIcon(props) {
  const navigation = useNavigation();
  const { stopTaskThunk } = useStoreActions((actions) => actions);
  return (
    <Icon.WithText
      icon={<Icon.Stop />}
      text="Stop Work"
      onPress={() => stopTaskThunk(navigation)}
      {...props}
    />
  );
}

export default StopWorkIcon;
