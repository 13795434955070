import { useStoreActions, useStoreState } from "easy-peasy";
import Button from "../../presentational/Button";

function AddPartRequestButton({ children, ...props }) {
  const { Qty, UsrPriorityLevel, UsrNeedBy } = useStoreState(
    (state) => state.part.partQuantityInput
  );

  const { addPartRequestThunk } = useStoreActions((actions) => actions);

  return (
    <Button
      disabled={Qty <= 0 || !UsrPriorityLevel.trim() || !UsrNeedBy.trim()}
      color="#198754"
      onPress={() => addPartRequestThunk()}
      {...props}
    >
      {children || "Set Quantity"}
    </Button>
  );
}

export default AddPartRequestButton;
