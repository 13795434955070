const defaultState = {
  base64: "",
  showFileModal: false,
};

const state = {
  file: defaultState,
};

export { state as default, defaultState };
