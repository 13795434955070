import React from "react";
import styled from "styled-components/native";
import { Pressable } from "react-native";
import EnvironmentText from "./EnvironmentText";
import Row from "../Row";
import { remToDp } from "../../../utilities/responsive";

const SRow = styled(Row)`
  justify-content: space-between;
  margin: ${remToDp(0.5)}px 0;
`;
const EnvironmentName = styled(EnvironmentText)`
  color: #dc3544;
`;
const GeographyName = styled(EnvironmentText)`
  color: #0658b6;
`;

function EnvironmentItem({ environment, geography, ...props }) {
  return (
    <Pressable {...props}>
      <SRow>
        <EnvironmentName>{environment}</EnvironmentName>
        <GeographyName>{geography}</GeographyName>
      </SRow>
    </Pressable>
  );
}

export default EnvironmentItem;
