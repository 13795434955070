import NonTaskPage from "./NonTaskPage";
import Page from "./Page";
import ProtectedPage from "./ProtectedPage";
import TaskPage from "./TaskPage";

Page.Protected = ProtectedPage;
Page.Task = TaskPage;
Page.NonTask = NonTaskPage;

export default Page;
