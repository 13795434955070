import { Button as RNButton } from "@rneui/themed";
import styled from "styled-components/native";

const Container = styled.View`
  flex-direction: ${(props) => (props.fullWidth ? "" : "row")};
  width: ${(props) => (props.fullWidth ? "100%" : "auto")};
`;

function Button({ children, fullWidth = false, ...props }) {
  return (
    <Container fullWidth={fullWidth}>
      <RNButton size="lg" title={children} color="#0088CE" {...props} />
    </Container>
  );
}

export default Button;
