import React from 'react';
import styled from 'styled-components';
import Text from '../Text/Text';
import { Input as RNInput } from '@rneui/themed';

const Label = styled(Text)``;

Label.Required = styled(Label)`
  color: #ab2328;
`;

const SInput = styled(RNInput)`
  padding: 0 10px;
  width: 100%;
  color: #00205b;
`;

function Input({
  label,
  required = false,
  inputStyle,
  editable = true,
  ...props
}) {
  return (
    <>
      {label && (
        <Label style={{ color: '#00205b' }}>
          {label} {required && <Label.Required>*</Label.Required>}
        </Label>
      )}
      <SInput
        placeholderTextColor='#76787A'
        editable={true}
        inputStyle={{
          backgroundColor: editable ? 'rgba(0, 0, 0, 0)' : '#E1E1E1',
          ...inputStyle,
        }}
        {...props}
      />
    </>
  );
}

export default Input;
