import styled from "styled-components/native";
import Input from "../../presentational/Input";
import Center from "../../presentational/Center";
import Card from "../../presentational/Card";
import Button from "../Button";
import Gap from "../../presentational/Gap";
import {
  MaterialCommunityIcons,
  FontAwesome5,
} from "react-native-vector-icons";
import Text from "../../presentational/Text/Text";
import Icon from "../../presentational/Icon";
import React from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import { remToDp, useScreenDimensions } from "../../../utilities/responsive";
import Logo from "../Logo";
import FadeInAnimation from "../../presentational/FadeInAnimation";

const SCard = styled(Card)`
  max-width: ${(props) => props.maxWidth}px;
  padding: ${remToDp(3)}px;
`;

const Header = styled(Text)`
  font-size: ${remToDp(2.5)}px;
  text-align: center;
  font-weight: 500;
`;

const SLogo = styled(Logo)`
  height: 100px;
`;

function LoginPage(props) {
  const { vwToDp, vhToDp } = useScreenDimensions();

  const {
    auth: { loginInput },
    layout: { topNavbarHeight },
  } = useStoreState((state) => state);

  const { setLoginInputAction, loginThunk } = useStoreActions(
    (actions) => actions
  );

  return (
    <FadeInAnimation>
      <Center style={{ height: vhToDp(100) - topNavbarHeight }} {...props}>
        <SCard maxWidth={vwToDp(35)}>
          <SLogo />

          <Gap />

          <Header>Login</Header>

          <Gap />

          <Input
            onChangeText={(email) => setLoginInputAction({ email })}
            value={loginInput.email}
            placeholder="Enter email"
            inputMode="email"
            leftIcon={<Icon Component={MaterialCommunityIcons} name="email" />}
            onSubmitEditing={() => loginThunk()}
          />

          <Gap />

          <Input
            onChangeText={(password) => setLoginInputAction({ password })}
            value={loginInput.password}
            placeholder="Enter password"
            secureTextEntry
            leftIcon={<Icon Component={FontAwesome5} name="lock" />}
            onSubmitEditing={() => loginThunk()}
          />

          <Gap />

          <Button.Login />
        </SCard>
      </Center>
    </FadeInAnimation>
  );
}

export default LoginPage;
