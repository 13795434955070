import styled from "styled-components/native";
import EnvironmentText from "./EnvironmentText";
import { useScreenDimensions } from "../../../utilities/responsive";

const Container = styled(EnvironmentText)`
  margin-bottom: ${(props) => props.marginBottom}px;
`;

function EnvironmentHeader({ children, ...props }) {
  const { vhToDp } = useScreenDimensions();
  return (
    <Container marginBottom={vhToDp(2.5)} {...props}>
      {children}
    </Container>
  );
}

export default EnvironmentHeader;
