import { Storage } from "../utilities/Storage";
import { createStore, persist } from "easy-peasy";
import statusStore from "./status";
import authStore from "./auth";
import employeeStore from "./employee";
import facialRecogStore from "./facialRecog";
import taskStore from "./task";
import partStore from "./part";
import nonRoutineStore from "./nonroutine";
import projectStore from "./project";
import fileStore from "./file";
import weatherStore from "./weather";
import layoutStore from "./layout";
import boatStore from "./boat";
import panelStore from "./panel";

const store = createStore(
  persist(
    {
      ...statusStore,
      ...authStore,
      ...employeeStore,
      ...facialRecogStore,
      ...taskStore,
      ...partStore,
      ...nonRoutineStore,
      ...projectStore,
      ...fileStore,
      ...weatherStore,
      ...layoutStore,
      ...boatStore,
      ...panelStore,
    },
    {
      storage: Storage,
      version: 1,
    }
  )
);

export default store;
