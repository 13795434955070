import styled from "styled-components/native";
import Text from "../../presentational/Text";
import { useProjectTaskList } from "../../../hooks/task";
import Task from "../Task";

const SProjectTask = styled(Task.Project)`
  margin: 6px 0;
`;

const Container = styled.View`
  padding: 8px;
`;

function ProjectTaskList(props) {
  const { list, message } = useProjectTaskList();
  return (
    <Container {...props}>
      {(list || []).length ? (
        list.map((task, index) => <SProjectTask key={index} {...task} />)
      ) : (
        <Text.NotFound>{message}</Text.NotFound>
      )}
    </Container>
  );
}

export default ProjectTaskList;
