import { useStoreState } from "easy-peasy";
import Col from "../../presentational/Col";
import Row from "../../presentational/Row";
import Icon from "../Icon";

function Nav({
  buttons,
  isOnTaskHomepage,
  showMarkCompleteOnWorkInstructionsTab,
  ...props
}) {
  const {
    task: {
      currentTask: { TaskStatus, hideInspectionReject },
    },
    employee: {
      scannedEmployee: { BypassQuals, hideMarkComplete, Inspector },
    },
  } = useStoreState((state) => state);

  const navButtonsToShow = [
    ...(buttons || []),
    {
      children: <Icon.MarkComplete isOnTaskHomepage={isOnTaskHomepage} />,
      show:
        showMarkCompleteOnWorkInstructionsTab &&
        TaskStatus.value !== "Inspection" &&
        (BypassQuals && hideMarkComplete ? false : true),
    },
    {
      children: <Icon.StopWork />,
      show: true,
    },
    {
      children: <Icon.InspectionComplete />,
      show: Inspector === "YES" && TaskStatus.value === "Inspection",
    },
    {
      children: <Icon.InspectionReject />,
      show:
        Inspector === "YES" &&
        TaskStatus.value === "Inspection" &&
        !hideInspectionReject,
    },
  ].filter(({ show }) => show);

  return (
    <Row {...props}>
      {navButtonsToShow.map((button, index) => (
        <Col xs={12} md={6} lg={12 / navButtonsToShow.length} key={index}>
          {button.children}
        </Col>
      ))}
    </Row>
  );
}

export default Nav;
