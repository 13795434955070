import Content from "../Content";
import styled from "styled-components/native";
import List from "../List";
import Form from "../Form";
import SearchBar from "../SearchBar";
import Gap from "../../presentational/Gap";
import { useSubtypeSearchBar, useSubtypesTab } from "../../../hooks/boat";
import { useScreenDimensions } from "../../../utilities/responsive";

const ScrollView = styled.ScrollView`
  max-height: ${(props) => props.maxHeight}px;
  width: 100%;
`;

function SubtypesTab() {
  const { loading, chosenSubtype } = useSubtypesTab();
  const searchBarProps = useSubtypeSearchBar();
  const { vhToDp } = useScreenDimensions();

  return (
    <Content loading={loading}>
      {chosenSubtype ? (
        <Form.EditSubtype />
      ) : (
        <>
          <SearchBar {...searchBarProps} />

          <Gap />

          <ScrollView maxHeight={vhToDp(30)}>
            <List.Subtype />
          </ScrollView>
        </>
      )}
    </Content>
  );
}

export default SubtypesTab;
