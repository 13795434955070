import Modal from "../../presentational/Modal";
import { useStoreActions, useStoreState } from "easy-peasy";
import Text from "../../presentational/Text/Text";
import styled from "styled-components/native";

const ModalHeader = styled(Modal.Header)`
  background-color: ${(props) => props.backgroundColor};
`;
const ModalBody = styled(Modal.Body)`
  text-align: center;
`;

const Header = styled(Text)`
  color: #ffffff;
`;

function useOnClose() {
  const { showFaceScanAlternativeModalAction } = useStoreActions(
    (actions) => actions
  );

  return () => showFaceScanAlternativeModalAction(false);
}

function MessageModal(props) {
  const {
    modalMessage,
    modalMessageHeader,
    showModalMessage,
    isRecognized,
    isNotRecognized,
  } = useStoreState((state) => state.facialRecog);

  const onClose = useOnClose();

  let backgroundColor = "#ffffff";
  if (isRecognized && !isNotRecognized) {
    backgroundColor = "#198754";
  } else if (!isRecognized && isNotRecognized) {
    backgroundColor = "#dc3545";
  }

  return (
    <Modal visible={showModalMessage} onRequestClose={onClose} {...props}>
      <ModalHeader onRequestClose={onClose} backgroundColor={backgroundColor}>
        <Header>{modalMessageHeader}</Header>
      </ModalHeader>

      <ModalBody>
        <Text>{modalMessage}</Text>
      </ModalBody>
    </Modal>
  );
}

export default MessageModal;
