import styled from "styled-components/native";
import Row from "../Row";
import Col from "../Col";
import Text from "../Text";

const Container = styled(Row)`
  background-color: #f4f4f4;
  margin-top: 8px;
  border-width: 1px;
  border-color: #ccc;
`;

function NonroutineOption({ ID, Description, ...props }) {
  return (
    <Container {...props}>
      <Col xs={1.5} sm={1.5}>
        <Text.Primary>{ID}</Text.Primary>
      </Col>
      <Col xs={10.5} sm={10.5}>
        <Text.Success>{Description}</Text.Success>
      </Col>
    </Container>
  );
}

export default NonroutineOption;
