import { useStoreActions, useStoreState } from "easy-peasy";
import { manipulateAsync, SaveFormat } from "expo-image-manipulator";
import * as ImagePicker from "expo-image-picker";

function useProjectSearchBar() {
  const {
    project: { searchInput },
    employee: { scannedEmployee },
  } = useStoreState((state) => state);

  const { setProjectSearchInputAction, searchProjectsThunk } = useStoreActions(
    (actions) => actions
  );

  return {
    value: searchInput,
    onChangeText: (input) => setProjectSearchInputAction(input),
    onClearInput: () => setProjectSearchInputAction(""),
    placeholder: scannedEmployee.customLabels.project.Search,
    onSubmitEditing: () => searchProjectsThunk(),
  };
}

function useProjectList() {
  const {
    project: { projectList },
    employee: { scannedEmployee },
  } = useStoreState((state) => state);

  const {
    getProjectTasksThunk,
    setChosenProjectAction,
    showProjectTaskServerSearchModalAction,
  } = useStoreActions((actions) => actions);

  return projectList.map((project) => ({
    ...(scannedEmployee.equipmentSearch
      ? {
          Status: project.Status.value,
          Customer: project.Customer.value,
          EquipmentID: project.EquipmentID.value,
          Description: project.Description.value,
          ProjectID: project.ProjectID.value,
        }
      : project),
    onPress: () => {
      setChosenProjectAction(project);
      if (scannedEmployee.searchTasksServerSide)
        showProjectTaskServerSearchModalAction(true);
      else
        getProjectTasksThunk(
          scannedEmployee.equipmentSearch
            ? project.ProjectID.value
            : project.ProjectID
        );
    },
  }));
}

function useChosenProject() {
  const {
    project: { chosenProject },
    employee: { scannedEmployee },
  } = useStoreState((state) => state);

  const {
    getProjectTasksThunk,
    setChosenProjectAction,
    showProjectTaskServerSearchModalAction,
    showAddProjectImagesModalAction,
    getEquipmentThunk,
  } = useStoreActions((actions) => actions);

  function formatChosenProject() {
    const projectDisplayData = scannedEmployee.equipmentSearch
      ? {
          Status: chosenProject.Status.value,
          Customer: chosenProject.Customer.value,
          EquipmentID: chosenProject.EquipmentID.value,
          Description: chosenProject.Description.value,
          ProjectID: chosenProject.ProjectID.value,
        }
      : chosenProject;

    return {
      ...projectDisplayData,
      chosen: true,
      onPress: () => {
        setChosenProjectAction(chosenProject);
        if (scannedEmployee.searchTasksServerSide)
          showProjectTaskServerSearchModalAction(true);
        else
          getProjectTasksThunk(
            scannedEmployee.equipmentSearch
              ? chosenProject.ProjectID.value
              : chosenProject.ProjectID
          );
      },
      onPressAddProject: scannedEmployee.equipmentSearch
        ? () => showAddProjectImagesModalAction(true)
        : null,
      onPressProjectName: scannedEmployee.equipmentSearch
        ? () => getEquipmentThunk(chosenProject.EquipmentID.value)
        : null,
    };
  }

  return chosenProject ? formatChosenProject() : null;
}

function useAddProjectImagesModal() {
  const {
    project: { imageUrlList, showAddProjectImagesModal },
    employee: { scannedEmployee },
  } = useStoreState((state) => state);

  const {
    setProjectImageUrlListAction,
    showAddProjectImagesModalAction,
    uploadProjectImagesThunk,
  } = useStoreActions((actions) => actions);

  async function uploadFile(result) {
    if (result && !result.canceled) {
      let newImageList = [];

      const images = [...imageUrlList, ...result.assets];
      const imageMap = images.reduce((map, image) => {
        const fileName = image.fileName || image.uri.split("/").pop();
        !map[fileName] &&
          (map[fileName] = { fileName: fileName, uri: image.uri });
        return map;
      }, {});

      const list = Object.values(imageMap);
      for (const uploadedImage of list) {
        const maxImageSize = 1024;

        const newImage = await manipulateAsync(
          uploadedImage.uri,
          [
            {
              resize: {
                width: Math.min(
                  scannedEmployee?.defaultImageWidth || maxImageSize,
                  maxImageSize
                ),
                height: Math.min(
                  scannedEmployee?.defaultImageHeight || maxImageSize,
                  maxImageSize
                ),
              },
            },
          ],
          { compress: 1, format: SaveFormat.PNG }
        );

        newImageList.push({
          fileName: uploadedImage.fileName,
          uri: newImage.uri,
        });
      }

      setProjectImageUrlListAction([...newImageList]);
    }
  }

  return {
    imageToUploadList: imageUrlList,
    onPressImageFile: async () => {
      const result = await ImagePicker.launchImageLibraryAsync({
        allowsMultipleSelection: true,
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: false,
        aspect: [4, 3],
        quality: 1,
        selectionLimit: scannedEmployee?.imageUploadLimit || 0,
      });
      await uploadFile(result);
    },
    onPressCamera: async () => {
      const result = await ImagePicker.launchCameraAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        aspect: [4, 3],
      });
      await uploadFile(result);
    },
    visible: showAddProjectImagesModal,
    onRequestClose: () => showAddProjectImagesModalAction(false),
    onPressDeleteImage: (imageToDelete) =>
      setProjectImageUrlListAction(
        imageUrlList.filter(
          (image) => image.fileName !== imageToDelete.fileName
        )
      ),
    onPressUploadImages: () => uploadProjectImagesThunk(),
  };
}

function useProjectSearchPage() {
  const {
    employee: { usingCentrik, qualifications, scannedEmployee },
    project: { projectTaskList },
  } = useStoreState((state) => state);

  return {
    canSearchProjects:
      (usingCentrik && qualifications.mechanicQualified) || !usingCentrik,
    showTaskSearchBar: projectTaskList.length > 0,
    equipmentSearch: scannedEmployee.equipmentSearch,
  };
}

export {
  useProjectSearchBar,
  useProjectList,
  useChosenProject,
  useAddProjectImagesModal,
  useProjectSearchPage,
};
