import { useStoreState } from "easy-peasy";
import Modal from "../../presentational/Modal";
import Button from "../Button";
import styled from "styled-components/native";
import Text from "../../presentational/Text/Text";
import { remToDp } from "../../../utilities/responsive";
import Center from "../../presentational/Center";

const SText = styled(Text)`
  color: #dc3545;
  font-size: ${remToDp(1.5)}px;
`;

function ForcedLoggedOutModal(props) {
  const { employee, auth } = useStoreState((state) => state);

  return (
    <Modal visible={auth.showForcedLogoutModal} {...props}>
      <Modal.Header>Forced Logout</Modal.Header>

      <Modal.Body>
        <Center>
          <SText>
            {employee.scannedEmployee.closeTasksOnForcedLogout
              ? "All task cards will be clocked out and you will be logged out."
              : "You will be logged out."}
          </SText>
        </Center>
      </Modal.Body>

      <Modal.Footer>
        <Button.ForcedLogout />
      </Modal.Footer>
    </Modal>
  );
}

export default ForcedLoggedOutModal;
