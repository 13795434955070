import { Button } from "@cfbs/cfbsstrap-native";
import { useStoreActions } from "easy-peasy";

function SearchTasksServerSideButton({ children, ...props }) {
  const { getProjectTasksThunk } = useStoreActions((actions) => actions);
  return (
    <Button onPress={() => getProjectTasksThunk()} {...props}>
      {children || "Search"}
    </Button>
  );
}

export default SearchTasksServerSideButton;
