import { useStoreActions } from "easy-peasy";
import Button from "../../presentational/Button";

function PrintPartRequestManifestButton({ children, ...props }) {
  const { printPartRequestManifestPdfThunk } = useStoreActions(
    (actions) => actions
  );

  return (
    <Button
      color="#198754"
      size="md"
      onPress={() => printPartRequestManifestPdfThunk()}
      {...props}
    >
      {children || "Print Part Request Manifest"}
    </Button>
  );
}

export default PrintPartRequestManifestButton;
