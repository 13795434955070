import Modal from "./Modal";
import styled from "styled-components/native";
import ModalBody from "./ModalBody";
import ModalHeader from "./ModalHeader";
import { WebView } from "react-native-webview";
import { useEffect } from "react";
import * as FileSystem from "expo-file-system";
import * as Sharing from "expo-sharing";
import Button from "../Button";
import Gap from "../Gap";
import { Platform } from "react-native";
import { useScreenDimensions } from "../../../utilities/responsive";

const SModalBody = styled(ModalBody)`
  height: ${(props) => props.height}px;
`;

const PdfDisplay = styled(WebView)`
  width: 100%;
  height: 100%;
`;

function ModalPdf({ header, style, base64, children, filename, ...props }) {
  const { vhToDp } = useScreenDimensions();
  const fileUri = `${FileSystem.cacheDirectory}${filename}`;

  useEffect(() => {
    // Write the base64 PDF string to the temporary file.
    // This must be done in order for the PDF to be displayed, shared, or downloaded.
    Platform.OS !== "web" &&
      base64 &&
      FileSystem.writeAsStringAsync(fileUri, base64, {
        encoding: FileSystem.EncodingType.Base64,
      });
  }, []);

  return (
    <Modal {...props}>
      <ModalHeader onRequestClose={props.onRequestClose}>{header}</ModalHeader>

      <SModalBody height={vhToDp(50)} style={style}>
        {children || (
          <>
            <PdfDisplay
              useWebKit={true}
              originWhitelist={["*"]}
              scrollEnabled={true}
              mediaPlaybackRequiresUserAction={true}
              source={{
                uri: fileUri,
              }}
            />

            <Gap />

            <Button
              fullWidth
              onPress={() =>
                Sharing.shareAsync(fileUri, {
                  mimeType: "application/pdf",
                  dialogTitle: "Share PDF",
                  UTI: "com.adobe.pdf",
                })
              }
            >
              Download / Share
            </Button>
          </>
        )}
      </SModalBody>
    </Modal>
  );
}

export default ModalPdf;
