import { useStoreActions, useStoreState } from "easy-peasy";
import { useEffect, useState } from "react";
import { caseInsensitiveComparisonString } from "../utilities/string";

function useBoatDetailsModal() {
  const {
    project: { chosenProject },
    boat: { equipment },
  } = useStoreState((state) => state);

  const { setEquipmentAction } = useStoreActions((actions) => actions);

  return {
    visible: chosenProject && equipment,
    onRequestClose: () => setEquipmentAction(null),
    boatDetails: {
      project: chosenProject,
      equipment,
    },
  };
}

function useSubtypeSearchBar() {
  const { searchInput } = useStoreState((state) => state.boat);

  const { setBoatSearchInputAction } = useStoreActions((actions) => actions);

  return {
    value: searchInput,
    onChangeText: (equipmentCode) => setBoatSearchInputAction(equipmentCode),
    onClearInput: () => setBoatSearchInputAction(""),
    placeholder:
      "Search by equipment code / Filter by SubTypeID, Attribute, or Value",
  };
}

function useSubtypeList() {
  const { subtypeSearchResults, searchInput } = useStoreState(
    (state) => state.boat
  );

  const { setChosenSubtypeAction } = useStoreActions((actions) => actions);

  return {
    tableHead: ["SubTypeID", "Attribute", "Value", "Edit"],
    tableRows: subtypeSearchResults.filter((subtype) => {
      const formattedSearchInput = caseInsensitiveComparisonString(searchInput);

      return (
        caseInsensitiveComparisonString(subtype.SubTypeID || "").includes(
          formattedSearchInput
        ) ||
        caseInsensitiveComparisonString(subtype.AttributeDesc || "").includes(
          formattedSearchInput
        )
      );
    }),
    onChooseSubtype: (subtype) => () => setChosenSubtypeAction(subtype),
  };
}

function useEditSubtypeForm() {
  const { subtypeAttributeInput, chosenSubtype } = useStoreState(
    (state) => state.boat
  );

  const [loading, setLoading] = useState(false);

  const {
    setSubtypeAttributeInputAction,
    editSubtypeThunk,
    setChosenSubtypeAction,
  } = useStoreActions((actions) => actions);

  return {
    isLoading: loading,
    value: subtypeAttributeInput,
    onChangeText: (subtypeAttributeInput) =>
      setSubtypeAttributeInputAction(subtypeAttributeInput),
    placeholder: "Edit / Add subtype attribute value",
    onSubmitEditing: async () => {
      setLoading(true);
      await editSubtypeThunk();
      setLoading(false);
    },
    chosenSubtype,
    onBackToSubtypeList: () => setChosenSubtypeAction(null),
  };
}

function useBoatItemRequirementList() {
  const { boatItemRequirements } = useStoreState((state) => state.boat);
  return {
    headers: [
      "Item Req ID",
      "Note",
      "SO Order #",
      "Inventory ID",
      "Requested Quantity",
      "SO Line #",
      "SO Line Quantity",
      "Open Quantity",
      "Task CD",
      "Task ID",
      "Issued Quantity",
    ],
    list: boatItemRequirements,
  };
}

function useWorkInstructionsTab() {
  const {
    project: { chosenProjectTask },
    employee: { scannedEmployee },
    task: { currentTask, correctiveActionInput },
  } = useStoreState((state) => state);

  const { setCorrectiveActionInputAction } = useStoreActions(
    (actions) => actions
  );

  return {
    instructions: chosenProjectTask?.Instructions,
    correctiveActionLabel: scannedEmployee.customLabels.task.CorrectiveAction,
    correctiveActionValue: currentTask?.CorrectiveAction?.value
      ? `Existing ${scannedEmployee.customLabels.task.CorrectiveAction}: ${currentTask.CorrectiveAction.value}`
      : `No Exising ${scannedEmployee.customLabels.task.CorrectiveAction}`,
    input: {
      placeholder: `${scannedEmployee.customLabels.task.CorrectiveAction} here`,
      value: correctiveActionInput,
      onChangeText: (text) => setCorrectiveActionInputAction(text),
    },
  };
}

function useItemRequirementsTab() {
  const { hasWifi } = useStoreState((state) => state.status);

  const [loading, setLoading] = useState(false);

  const { getBoatItemRequirementsThunk } = useStoreActions(
    (actions) => actions
  );

  async function loadItemRequirements() {
    setLoading(true);
    await getBoatItemRequirementsThunk();
    setLoading(false);
  }

  useEffect(() => {
    hasWifi && loadItemRequirements();
  }, [hasWifi]);

  return loading;
}

function useSubtypesTab() {
  const {
    boat: { chosenSubtype },
    status: { hasWifi },
  } = useStoreState((state) => state);

  const [loading, setLoading] = useState(false);

  const { searchSubtypesThunk } = useStoreActions((actions) => actions);

  async function loadSubtypes() {
    setLoading(true);
    await searchSubtypesThunk();
    setLoading(false);
  }

  useEffect(() => {
    hasWifi && !chosenSubtype && loadSubtypes();
  }, [hasWifi, chosenSubtype]);

  return {
    loading,
    chosenSubtype,
  };
}

export {
  useWorkInstructionsTab,
  useItemRequirementsTab,
  useSubtypesTab,
  useBoatDetailsModal,
  useSubtypeSearchBar,
  useSubtypeList,
  useEditSubtypeForm,
  useBoatItemRequirementList,
};
