import { FontAwesome } from "@expo/vector-icons";
import Icon from "../../presentational/Icon";
import { useNavigation } from "@react-navigation/native";
import { useStoreActions } from "easy-peasy";

function GoToPartsRequestPageIcon(props) {
  const { getPartRequestsThunk } = useStoreActions((actions) => actions);
  const navigation = useNavigation();

  return (
    <Icon.WithText
      icon={<Icon.Primary Component={FontAwesome} name="gears" />}
      text="Parts Request"
      onPress={() => getPartRequestsThunk(navigation)}
      {...props}
    />
  );
}

export default GoToPartsRequestPageIcon;
