import { useBoatDetailsModal } from "../../../hooks/boat";
import Gap from "../../presentational/Gap";
import Text from "../../presentational/Text";
import Modal from "./Modal";
import styled from "styled-components/native";
import { useScreenDimensions } from "../../../utilities/responsive";
import Row from "../../presentational/Row";
import Col from "../../presentational/Col";

const ScrollView = styled.ScrollView`
  max-height: ${(props) => props.maxHeight}px;
  width: 100%;
`;

function Detail({ label, value, ...props }) {
  return value ? (
    <Text {...props}>
      {label && <Text bold>{label}: </Text>}
      {value}
    </Text>
  ) : (
    ""
  );
}

function BoatDetailsModal(props) {
  const { boatDetails, ...modalProps } = useBoatDetailsModal();

  const { vhToDp, vwToDp } = useScreenDimensions();

  return (
    <Modal maxWidth={vwToDp(65)} {...modalProps} {...props}>
      <ScrollView maxHeight={vhToDp(85)}>
        <Modal.Header onRequestClose={modalProps.onRequestClose}>
          Boat Details For {boatDetails.project?.ProjectID.value}
        </Modal.Header>

        <Modal.Body>
          <Row>
            {[
              { label: "Project", value: boatDetails.equipment?.ProjectCD },
              {
                label: "Boat Name",
                value: boatDetails.equipment?.BoatName.value,
              },
              {
                label: "Boat Type",
                value: boatDetails.equipment?.BoatTypeDescription.value,
              },
              {
                label: "Vessel ID",
                value: boatDetails.equipment?.EquipmentID.value,
              },
              {
                label: "Vessel Name",
                value: boatDetails.equipment?.VesselName.value,
              },
              {
                label: "Vessel Year",
                value: boatDetails.equipment?.VesselYear.value,
              },
              {
                label: "Vessel Summer Location",
                value: boatDetails.equipment?.VesselSummerLocation.value,
              },
              {
                label: "Vessel Winter Location",
                value: boatDetails.equipment?.VesselWinterLocation.value,
              },
              {
                label: "Vessel Notes",
                value: boatDetails.equipment?.VesselNotes.value,
              },
              {
                label: "Equipment Type ID",
                value: boatDetails.equipment?.EquipmentTypeID.value,
              },
              {
                label: "Business Account",
                value: boatDetails.equipment?.BusinessAccount.value,
              },
              {
                label: "Key Location",
                value: boatDetails.equipment?.KeyLocation.value,
              },
              {
                label: "Location",
                value: boatDetails.equipment?.Location.value,
              },
            ]
              .filter((detail) => detail.value)
              .map((props) => (
                <Col key={props.label} xs={12} md={6} lg={4}>
                  <Detail {...props} />
                  {props.value && <Gap />}
                </Col>
              ))}
          </Row>
        </Modal.Body>
      </ScrollView>
    </Modal>
  );
}

export default BoatDetailsModal;
