import { useStoreActions } from "easy-peasy";
import Button from "../../presentational/Button";

function ClockInAlternativeButton({ children = "Submit", ...props }) {
  const { clockInAlternativeThunk } = useStoreActions((actions) => actions);
  return (
    <Button onPress={() => clockInAlternativeThunk()} {...props}>
      {children}
    </Button>
  );
}

export default ClockInAlternativeButton;
