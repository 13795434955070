import Text from "./Text";

function Quantity({ quantity, ...props }) {
  const qty = parseInt(quantity, 10);
  return qty ? (
    <Text.Success {...props}>{qty}</Text.Success>
  ) : (
    <Text.Error {...props}>{qty}</Text.Error>
  );
}

export default Quantity;
