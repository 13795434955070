import { useStoreActions, useStoreState } from "easy-peasy";
import Modal from "../../presentational/Modal";
import Text from "../../presentational/Text";
import Button from "../Button";
import Row from "../../presentational/Row";
import Col from "../../presentational/Col";
import styled from "styled-components/native";
import { ScrollView } from "react-native";
import { useScreenDimensions } from "../../../utilities/responsive";
import Message from "../../presentational/Message";

const SyncMessageFail = styled(Message.Error)`
  margin-bottom: 12px;
`;

const Instructions = styled(Text)`
  margin-bottom: 16px;
`;

const PendingOperation = styled(ScrollView)`
  max-height: ${(props) => props.maxHeight}px;
`;
PendingOperation.Item = styled(Row)`
  background-color: #eaeaea;
  margin: 8px 0px;
  align-items: center;
`;

function SyncAppModal(props) {
  const { vhToDp } = useScreenDimensions();

  const { showSyncAppModal, pendingOperations, syncFailed } = useStoreState(
    (state) => state.status
  );

  const { showSyncAppModalAction } = useStoreActions((actions) => actions);

  return (
    <Modal
      visible={showSyncAppModal}
      onRequestClose={() => showSyncAppModalAction(false)}
      {...props}
    >
      <Modal.Header>Sync App</Modal.Header>
      <Modal.Body>
        {syncFailed && (
          <SyncMessageFail>
            App could not be fully synced. You can fix these errors yourself now
            that you are online. Button for syncing app will stay until app is
            fully synced.
          </SyncMessageFail>
        )}

        <Instructions>
          {pendingOperations.length > 0
            ? "This app has below pending operations. Sync your app now."
            : "Updating queue..."}
        </Instructions>

        <PendingOperation maxHeight={vhToDp(60)}>
          {pendingOperations.map((operation) => (
            <PendingOperation.Item>
              <Col xs={12} md={6}>
                <Text>{operation.operationLabel}</Text>
              </Col>
              <Col xs={12} md={6}>
                <Text>{operation.operationDescription}</Text>
              </Col>
            </PendingOperation.Item>
          ))}
        </PendingOperation>
      </Modal.Body>

      <Modal.Footer>
        <Button.SyncApp />
        {syncFailed && <Button.SyncFailureUnderstood />}
      </Modal.Footer>
    </Modal>
  );
}

export default SyncAppModal;
