import axios from "axios";

const facialRecogAxios = axios.create({
  baseURL: `${process.env.EXPO_PUBLIC_FACIALRECOG_BACKEND}/cognitive`,
});

function clockInApiCall(data) {
  return facialRecogAxios({
    method: "post",
    url: "/identify",
    data,
  });
}

async function uploadFaceImageToAzureApiCall(url, photoImageBuffer) {
  return axios({
    method: "put",
    data: photoImageBuffer,
    url,
    headers: {
      "Content-Type": "image/png",
      "Content-Encoding": "base64",
      "x-ms-blob-type": "BlockBlob",
    },
  });
}

function clockInAlternativeApiCall(data) {
  return facialRecogAxios({
    method: "post",
    url: "/biometricalternative",
    data,
  });
}

export {
  facialRecogAxios,
  uploadFaceImageToAzureApiCall,
  clockInApiCall,
  clockInAlternativeApiCall,
};
