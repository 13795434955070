import { useStoreActions, useStoreState } from "easy-peasy";
import Page from "../containers/Page";
import styled from "styled-components/native";
import Text from "../presentational/Text";
import Input from "../presentational/Input";
import { remToDp, useScreenDimensions } from "../../utilities/responsive";
import Nav from "../containers/Nav";
import Tab from "../functional/Tab";
import Tabs from "../functional/Tabs";
import { useState } from "react";

const Subheader = styled(Text.Primary)`
  font-size: ${remToDp(1.5)}px;
  margin: 8px 0px;
  font-weight: 500;
`;
Subheader.InspectionRejected = styled(Text.TaskStatus)`
  font-size: ${remToDp(1.5)}px;
  font-weight: 500;
`;

const InspectionRejectedReason = styled(Text)`
  margin: ${remToDp(0.5)}px;
`;

function TaskPage(props) {
  const { vhToDp } = useScreenDimensions();

  const {
    task: { currentTask, correctiveActionInput },
    employee: { scannedEmployee },
  } = useStoreState((state) => state);

  const [activeTabKey, setActiveTabKey] = useState(
    scannedEmployee.taskTabs ? scannedEmployee.taskTabs[0] : []
  );

  const { setCorrectiveActionInputAction } = useStoreActions(
    (actions) => actions
  );

  return currentTask ? (
    <Page.Task {...props}>
      {!scannedEmployee.equipmentDetailOnTaskQueue && (
        <>
          <Subheader>{scannedEmployee.customLabels.task.Squawk}</Subheader>
          <Text>{currentTask?.Instructions?.value}</Text>

          {currentTask &&
            currentTask.RejectedReason &&
            currentTask.RejectedReason.value && (
              <>
                <Subheader.InspectionRejected
                  status={currentTask.TaskStatus.value}
                >
                  Inspection Rejected:
                </Subheader.InspectionRejected>

                <InspectionRejectedReason>
                  {currentTask.RejectedReason.value}
                </InspectionRejectedReason>
              </>
            )}

          <Subheader>
            {scannedEmployee.customLabels.task.CorrectiveAction}
          </Subheader>
          <Text numberOfLines={1}>
            {currentTask?.CorrectiveAction?.value
              ? `Existing ${scannedEmployee.customLabels.task.CorrectiveAction}: ${currentTask?.CorrectiveAction.value}`
              : `No Exising ${scannedEmployee.customLabels.task.CorrectiveAction}`}
          </Text>

          <Input
            placeholder={`${scannedEmployee.customLabels.task.CorrectiveAction} here`}
            multiline={true}
            value={correctiveActionInput}
            onChangeText={(text) => setCorrectiveActionInputAction(text)}
            style={{
              marginTop: 16,
              backgroundColor: "#ffffff",
              height: vhToDp(10),
            }}
          />
        </>
      )}

      {scannedEmployee.equipmentDetailOnTaskQueue && (
        <Tabs activeKey={activeTabKey} onSelect={(key) => setActiveTabKey(key)}>
          {scannedEmployee.taskTabs.map((tab) => {
            const tabs = {
              "Work Instructions": (
                <Tab.WorkInstructions showCorrectiveAction />
              ),
              "Item Requirements": <Tab.ItemRequirements />,
              SubTypes: <Tab.Subtypes />,
            };

            return (
              <Tab
                key={tab}
                eventKey={tab}
                title={tab}
                containerStyle={{ width: "33.33%" }}
              >
                {tabs[tab] || tab}
              </Tab>
            );
          })}
        </Tabs>
      )}

      <Nav.Task
        showMarkCompleteOnWorkInstructionsTab={
          scannedEmployee.equipmentDetailOnTaskQueue
            ? activeTabKey === "Work Instructions"
            : true
        }
      />
    </Page.Task>
  ) : (
    ""
  );
}

export default TaskPage;
