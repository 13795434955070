import { action } from "easy-peasy";
import { defaultState } from "./state";

const actions = {
  setCurrentWeatherAction: action((state, weather) => {
    state.weather.current = weather;
  }),
  setWeatherLocationAction: action((state, location) => {
    state.weather.location = location;
  }),
  resetWeatherStateAction: action((state) => {
    state.weather = defaultState;
  }),
};

export default actions;
