import ErrorMessage from "./ErrorMessage";
import SuccessMessage from "./SuccessMessage";
import Message from "./Message";
import WarningMessage from "./WarningMessage";
import AllowCameraUseMessage from "./AllowCameraUseMessage";

Message.Error = ErrorMessage;
Message.Success = SuccessMessage;
Message.Warning = WarningMessage;
Message.AllowCameraUse = AllowCameraUseMessage;

export default Message;
