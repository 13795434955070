import { createRef, useCallback, useEffect, useState } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import CameraPhoto from "jslib-html5-camera-photo";
import Input from "../../presentational/Input";
import Option from "../../presentational/Option";
import Center from "../../presentational/Center";
import Button from "../../presentational/Button";

function WebFacialScan() {
  let cameraPhoto = null;
  const cameraRef = createRef();
  const [cameraId, setCameraId] = useState();
  const [devices, setDevices] = useState([]);
  const { KronosClockIn, customLabels } = useStoreState(
    (state) => state.employee.scannedEmployee
  );

  const { scanFaceThunk, showFaceScanModalAction } = useStoreActions(
    (actions) => actions
  );

  const startCamera = useCallback(async () => {
    cameraPhoto = new CameraPhoto(cameraRef.current);
    try {
      await cameraPhoto.startCamera(cameraId, {
        width: 320,
        height: 240,
      });
      console.log("Camera is started!");
    } catch (error) {
      console.error("Camera not started!", error);
    }
  }, [cameraId]);

  const handleDevices = useCallback(
    (mediaDevices) => {
      const deviceList = mediaDevices.filter(
        ({ kind }) => kind === "videoinput"
      );
      setDevices(deviceList);
      if (deviceList.length === 1) {
        setCameraId(deviceList[0].deviceId);
      }
    },
    [setDevices]
  );

  const getValue = useCallback(() => {
    const device = devices.find((device) => device.deviceId === cameraId);
    return device?.label || "";
  }, [devices, cameraId]);

  useEffect(() => {
    async function getDevices() {
      await navigator.mediaDevices.getUserMedia({ video: true });
      await navigator.mediaDevices.enumerateDevices().then(handleDevices);
    }
    getDevices();
  }, [handleDevices]);

  useEffect(() => {
    if (cameraId) {
      localStorage.setItem("cameraId", cameraId);
      startCamera();
    }
  }, [cameraId, startCamera]);

  useEffect(() => {
    const cameraId = localStorage.getItem("cameraId");
    if (cameraId) {
      setCameraId(cameraId);
    }
  }, []);

  async function takePhoto() {
    cameraPhoto = new CameraPhoto(cameraRef.current);
    const cameraDataUri = cameraPhoto.getDataUri({
      sizeFactor: 1,
      imageCompression: 0.95,
    });

    await scanFaceThunk(cameraDataUri);

    showFaceScanModalAction(false);
  }

  return (
    <Center>
      <div>
        <Input.Select
          header="Select Camera"
          value={getValue()}
          placeholder="Select Camera"
          options={devices.map((device) => ({
            device,
            children: <Option>{device.label}</Option>,
          }))}
          onChange={({ device }) => {
            setCameraId(device.deviceId);
          }}
        />
      </div>
      <div>
        <video width="320" height="240" ref={cameraRef} autoPlay playsInline />
      </div>
      <Button
        disabled={!cameraId}
        onPress={() => {
          takePhoto();
        }}
        color="#198754"
        fullWidth
      >
        {KronosClockIn
          ? customLabels.facialRecognition.ClockIn
          : customLabels.facialRecognition.ClockOut}
      </Button>
    </Center>
  );
}

export default WebFacialScan;
