import Navbar from "./Navbar";
import HomeNavbar from "./HomeNavbar";
import TaskNavbar from "./TaskNavbar";
import ProtectedTopNavbar from "./ProtectedTopNavbar";

Navbar.Home = HomeNavbar;
Navbar.Task = TaskNavbar;
Navbar.ProtectedTop = ProtectedTopNavbar;

export default Navbar;
