import { useStoreActions, useStoreRehydrated, useStoreState } from "easy-peasy";
import Center from "../../presentational/Center";
import { StatusBar } from "expo-status-bar";
import Alerts from "../Alerts";
import Loading from "../Loading";
import styled from "styled-components/native";
import Modal from "../Modal";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { useEffect } from "react";
import { useNetInfo } from "@react-native-community/netinfo";
import { remToDp, useScreenDimensions } from "../../../utilities/responsive";
import FadeInAnimation from "../../presentational/FadeInAnimation";
import { Platform } from "react-native";
import Message from "../../presentational/Message";
import * as ScreenOrientation from "expo-screen-orientation";
import Navbar from "../Navbar";

const Container = styled(KeyboardAwareScrollView)`
  background-color: #ffffff;
`;
Container.Loading = styled(Center.Screen)`
  height: ${(props) => props.fullHeight - props.navbarHeight}px;
`;

const DeviceRestriction = styled(Center.Screen)`
  max-width: 40%;
  width: 100%;
  margin: auto;
`;
DeviceRestriction.Message = styled(Message.Warning)`
  text-align: center;
  font-size: ${remToDp(1.5)}px;
`;

function Page({ children, ...props }) {
  const { vhToDp } = useScreenDimensions();

  const {
    status: { loading },
    layout: { topNavbarHeight, allowPortrait },
    employee: { scannedEmployee },
  } = useStoreState((state) => state);

  const isRehydrated = useStoreRehydrated();

  const netInfo = useNetInfo();

  const { setHasWifiAction } = useStoreActions((actions) => actions);

  useEffect(() => {
    setHasWifiAction(netInfo.isConnected);
  }, [netInfo.isConnected]);

  useEffect(() => {
    async function updateOrientation() {
      if (scannedEmployee.allowPortrait || allowPortrait) {
        await ScreenOrientation.unlockAsync();
      } else {
        await ScreenOrientation.lockAsync(
          ScreenOrientation.OrientationLock.LANDSCAPE
        );
      }
    }

    Platform.OS !== "web" && updateOrientation();
  }, [scannedEmployee.allowPortrait]);

  return Platform.isPad || Platform.OS === "web" ? (
    <Container extraScrollHeight={vhToDp(10)} {...props}>
      <StatusBar style="auto" />
      <Navbar.ProtectedTop />
      <Alerts />
      {loading && isRehydrated ? (
        <Container.Loading
          navbarHeight={topNavbarHeight}
          fullHeight={vhToDp(100)}
        >
          <Loading />
        </Container.Loading>
      ) : (
        <FadeInAnimation>{children}</FadeInAnimation>
      )}
      <Modal.SyncApp />
    </Container>
  ) : (
    <DeviceRestriction>
      <DeviceRestriction.Message>
        This app is designed to run on iPad and web only
      </DeviceRestriction.Message>
    </DeviceRestriction>
  );
}

export default Page;
