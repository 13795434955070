import Modal from "../../presentational/Modal";
import { useAddProjectImagesModal } from "../../../hooks/project";
import Center from "../../presentational/Center";
import Col from "../../presentational/Col";
import Gallery from "../../presentational/Gallery";
import Gap from "../../presentational/Gap";
import styled from "styled-components/native";
import { useScreenDimensions } from "../../../utilities/responsive";
import Row from "../../presentational/Row";
import Button from "../../presentational/Button";
import { Platform } from "react-native";

const ScrollView = styled.ScrollView`
  max-height: ${(props) => props.maxHeight}px;
  width: 100%;
`;

function AddProjectImagesModal(props) {
  const {
    imageToUploadList,
    onPressImageFile,
    onPressCamera,
    onPressDeleteImage,
    onPressUploadImages,
    ...modalProps
  } = useAddProjectImagesModal();
  const { vwToDp, vhToDp } = useScreenDimensions();

  return (
    <Modal maxWidth={vwToDp(65)} {...modalProps} {...props}>
      <ScrollView maxHeight={vhToDp(85)}>
        <Modal.Header onRequestClose={modalProps.onRequestClose}>
          Add Images
        </Modal.Header>

        <Modal.Body>
          {imageToUploadList.map((image, index) => (
            <Col key={index} xs={6} md={3} lg={2}>
              <Gallery image={image} onDelete={onPressDeleteImage}></Gallery>
            </Col>
          ))}

          <Center>
            <Row>
              {Platform.OS !== "web" && (
                <>
                  <Button onPress={onPressCamera}>Select Camera Image</Button>
                  <Gap />
                </>
              )}

              <Button onPress={onPressImageFile}>Select Image</Button>

              {imageToUploadList.length > 0 && (
                <>
                  <Gap />
                  <Button onPress={onPressUploadImages}>Upload Images</Button>
                </>
              )}
            </Row>
          </Center>
        </Modal.Body>
      </ScrollView>
    </Modal>
  );
}

export default AddProjectImagesModal;
