import { View } from "react-native";
import AddUnserviceableStockItemForm from "./AddUnserviceableStockItemForm";
import AddPartRequestForm from "./AddPartRequestForm";

function AddStockItemRequestForm(props) {
  return (
    <View {...props}>
      <AddUnserviceableStockItemForm />
      <AddPartRequestForm />
    </View>
  );
}

export default AddStockItemRequestForm;
