import { remToDp } from "../../../utilities/responsive";
import Table from "../Table";
import styled from "styled-components/native";

const Header = styled(Table)`
  background-color: #014daf;
`;
Header.Item = styled(Table.th)`
  color: #ffffff;
  font-weight: normal;
  font-size: ${remToDp(1)}px;
`;

function PartRequestHeader({ headers, ...props }) {
  return (
    <Header {...props}>
      <Header.tr>
        {(headers || []).map((header, index) => (
          <Header.Item key={index}>{header}</Header.Item>
        ))}
      </Header.tr>
    </Header>
  );
}

export default PartRequestHeader;
