import { axios } from ".";

const authAxios = axios.create({
  baseURL: `${process.env.EXPO_PUBLIC_BACKEND_BASE_URL}/api/v1/auth`,
});

const authLoginAxios = axios.create({
  baseURL: `${process.env.EXPO_PUBLIC_BACKEND_BASE_URL}/api/v1/auth/login`,
});

function loginApiCall(data) {
  return authLoginAxios({ method: "post", data });
}

function loginAcmApiCall() {
  return authLoginAxios({ method: "post", url: "/acumatica" });
}

function refreshTokenApiCall() {
  return authAxios({ method: "post", url: "/token/refresh" });
}

function getAuthenticatedUserApiCall() {
  return authAxios({ method: "get", url: "/user" });
}

export {
  authAxios,
  authLoginAxios,
  loginApiCall,
  loginAcmApiCall,
  refreshTokenApiCall,
  getAuthenticatedUserApiCall,
};
