import { action } from "easy-peasy";
import { defaultState } from "./state";

const actions = {
  setChaptersAction: action((state, chapters) => {
    state.nonRoutine.chapters = chapters;
  }),
  setLaborItemsAction: action((state, laborItems) => {
    state.nonRoutine.laborItems = laborItems;
  }),
  setPriorityLevelsAction: action((state, priorityLevels) => {
    state.nonRoutine.priorityLevels = priorityLevels;
  }),
  setSubChaptersAction: action((state, subChapters) => {
    state.nonRoutine.subChapters = subChapters;
  }),
  setZonesAction: action((state, zones) => {
    state.nonRoutine.zones = zones;
  }),
  setStsZonesAction: action((state, zones) => {
    state.nonRoutine.stsZones = zones;
  }),
  setNonroutineInputAction: action((state, nonroutineInput) => {
    state.nonRoutine.nonroutineInput = {
      ...state.nonRoutine.nonroutineInput,
      ...nonroutineInput,
    };
  }),
  resetNonroutineInputAction: action((state) => {
    state.nonRoutine.nonroutineInput = {
      ...defaultState.nonroutineInput,
      None: true,
    };
  }),
  resetNonroutineStateAction: action((state) => {
    state.nonRoutine = defaultState;
  }),
  setChapterSearchInputAction: action((state, input) => {
    state.nonRoutine.chapterSearchInput = input;
  }),
  setSubchapterSearchInputAction: action((state, input) => {
    state.nonRoutine.subchapterSearchInput = input;
  }),
  setZoneSearchInputAction: action((state, input) => {
    state.nonRoutine.zoneSearchInput = input;
  }),
  setStsZoneSearchInputAction: action((state, input) => {
    state.nonRoutine.stsZoneSearchInput = input;
  }),
  setLaborItemSearchInputAction: action((state, input) => {
    state.nonRoutine.laborItemSearchInput = input;
  }),
  setNonRoutineMXTypesAction: action((state) => {
    state.task.currentTask.MXIsNone.value &&
      (state.nonRoutine.nonroutineInput.None =
        state.task.currentTask.MXIsNone.value);

    state.task.currentTask.MXIsEWIS.value &&
      (state.nonRoutine.nonroutineInput.EWIS =
        state.task.currentTask.MXIsEWIS.value);

    state.task.currentTask.MXIsRII.value &&
      (state.nonRoutine.nonroutineInput.RII =
        state.task.currentTask.MXIsRII.value);

    state.task.currentTask.MXIsCriticalTask.value &&
      (state.nonRoutine.nonroutineInput.CriticalTask =
        state.task.currentTask.MXIsCriticalTask.value);

    state.task.currentTask.MXIsCDCCL.value &&
      (state.nonRoutine.nonroutineInput.CDCCL =
        state.task.currentTask.MXIsCDCCL.value);

    state.task.currentTask.MXIsAWLI.value &&
      (state.nonRoutine.nonroutineInput.AirworthinessLimitationsItems =
        state.task.currentTask.MXIsAWLI.value);
  }),
  setServiceTypeAction: action((state, type) => {
    state.nonRoutine.serviceType = type;
    state.nonRoutine.servicePackageList = [];
    state.nonRoutine.chosenRootServiceProfileOption = {
      ComponentDescription: { value: "" },
      RMPTreeID: { value: "" },
    };
    state.nonRoutine.childServiceProfileOptions = [];
    state.nonRoutine.treeChildServiceProfileDropdowns = [];
    state.nonRoutine.chosenTreeChildServiceProfileOptions = [];
    state.nonRoutine.serviceProfileList = [];
    state.nonRoutine.chosenService = null;
  }),
  setChosenServiceAction: action((state, service) => {
    state.nonRoutine.chosenService = service;
  }),
  setServicePackageListAction: action((state, list) => {
    state.nonRoutine.servicePackageList = list;
  }),
  setRootServiceProfileOptionsAction: action((state, list) => {
    state.nonRoutine.rootServiceProfileOptions = list;
  }),
  setChosenRootServiceProfileOptionAction: action((state, option) => {
    state.nonRoutine.chosenRootServiceProfileOption = option;
    state.nonRoutine.treeChildServiceProfileDropdowns = [];
    state.nonRoutine.chosenTreeChildServiceProfileOptions = [];
  }),
  setChildServiceProfileOptionsAction: action((state, list) => {
    state.nonRoutine.childServiceProfileOptions = list;
  }),
  setTreeChildServiceProfileDropdownsAction: action(
    (state, { index, options }) => {
      state.nonRoutine.treeChildServiceProfileDropdowns =
        options && options.length
          ? [
              ...state.nonRoutine.treeChildServiceProfileDropdowns.slice(
                0,
                index
              ),
              options,
            ]
          : state.nonRoutine.treeChildServiceProfileDropdowns.slice(0, index);
    }
  ),
  setChosenTreeChildServiceProfileOptionsAction: action(
    (state, { index, option }) => {
      state.nonRoutine.chosenTreeChildServiceProfileOptions = [
        ...state.nonRoutine.chosenTreeChildServiceProfileOptions.slice(
          0,
          index
        ),
        option,
      ];
    }
  ),
  setServiceProfileListAction: action((state, list) => {
    state.nonRoutine.serviceProfileList = list;
  }),
  setShowDuplicateInspectionConfirmationModalAction: action((state, show) => {
    state.nonRoutine.showDuplicateInspectionConfirmationModal = show;
  }),
};

export default actions;
