import { useStoreActions, useStoreState } from "easy-peasy";
import Button from "../../presentational/Button";

function ScanFaceButton({ children, cameraRef, ...props }) {
  const { KronosClockIn, customLabels } = useStoreState(
    (state) => state.employee.scannedEmployee
  );

  const { scanFaceThunk } = useStoreActions((actions) => actions);

  return (
    <Button
      onPress={async () => {
        const photo = await cameraRef.current.takePictureAsync({
          quality: 1,
          base64: true,
          fixOrientation: true,
          exif: true,
        });
        scanFaceThunk(photo.base64);
      }}
      color="#198754"
      fullWidth
      {...props}
    >
      {props.disabled
        ? "Must allow camera access. If you have allowed camera access already, open this prompt again."
        : children ||
          (KronosClockIn
            ? customLabels.facialRecognition.ClockIn
            : customLabels.facialRecognition.ClockOut)}
    </Button>
  );
}

export default ScanFaceButton;
