import { useStoreActions } from "easy-peasy";
import Icon from "../../presentational/Icon";
import { FontAwesome } from "@expo/vector-icons";
import { remToDp } from "../../../utilities/responsive";
import { Pressable } from "react-native";

function GetPartRequestsIcon(props) {
  const { getPartRequestsThunk } = useStoreActions((actions) => actions);

  return (
    <Pressable onPress={() => getPartRequestsThunk()}>
      <Icon.Yellow
        Component={FontAwesome}
        name="refresh"
        size={remToDp(1.5)}
        {...props}
      />
    </Pressable>
  );
}

export default GetPartRequestsIcon;
