import { useStoreActions, useStoreState } from "easy-peasy";
import Modal from "../../presentational/Modal";

function ModalPdfDisplay(props) {
  const { base64, showFileModal } = useStoreState((state) => state.file);

  const { setFileBase64Action, showFileModalAction } = useStoreActions(
    (actions) => actions
  );

  return (
    <Modal.Pdf
      base64={base64}
      visible={showFileModal}
      onRequestClose={() => {
        showFileModalAction(false);
        setFileBase64Action("");
      }}
      filename="temp.pdf"
      {...props}
    />
  );
}

export default ModalPdfDisplay;
