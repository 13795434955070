import { useStoreActions, useStoreState } from "easy-peasy";
import Button from "../../presentational/Button";

function AddUnserviceableStockItemButton({ children, ...props }) {
  const {
    part: {
      unserviceableStockItemInput: { InventoryID, Description },
    },
    employee: { scannedEmployee },
  } = useStoreState((state) => state);

  const { addUnserviceableStockItemThunk } = useStoreActions(
    (actions) => actions
  );

  return (
    <Button
      onPress={() => addUnserviceableStockItemThunk()}
      color="#198754"
      disabled={!InventoryID || !Description}
      {...props}
    >
      {children || scannedEmployee.customLabels.addUnserviceablePart.Title}
    </Button>
  );
}

export default AddUnserviceableStockItemButton;
