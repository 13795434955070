import { Pressable } from "react-native";
import Center from "../Center";
import Text from "../Text";
import styled from "styled-components/native";

const SText = styled(Text)`
  margin-top: 12px;
`;

function IconWithText({ icon, text, ...props }) {
  return (
    <Pressable {...props}>
      <Center>
        {icon}
        {text && <SText>{text}</SText>}
      </Center>
    </Pressable>
  );
}

export default IconWithText;
