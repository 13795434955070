import { useStoreActions, useStoreState } from "easy-peasy";
import Icon from "../../presentational/Icon";
import Navbar from "../../presentational/Navbar";
import { Entypo } from "@expo/vector-icons";

function SignOutIcon(props) {
  const { hasWifi } = useStoreState((state) => state.status);
  const { logoutEmployeeThunk } = useStoreActions((actions) => actions);

  return (
    <Navbar.Item
      icon={<Icon.Red Component={Entypo} name="login" />}
      text={hasWifi ? "Sign Out" : "Can Only Sign Out Online"}
      onPress={() => hasWifi && logoutEmployeeThunk()}
      {...props}
    />
  );
}

export default SignOutIcon;
