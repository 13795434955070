import Icon from './Icon';
import IconWithText from './IconWithText';
import RedIcon from './RedIcon';
import YellowIcon from './YellowIcon';
import PrimaryIcon from './PrimaryIcon';
import TaskListIcon from './TaskListIcon';
import GreenIcon from './GreenIcon';
import SearchIcon from './SearchIcon';
import ClockIcon from './ClockIcon';
import StopIcon from './StopIcon';
import ClearIcon from './ClearIcon';
import BarcodeIcon from './BarcodeIcon';
import CreditCardIcon from './CreditCardIcon';
import PlusIcon from './PlusIcon';
import BanIcon from './BanIcon';
import CheckCircleIcon from './CheckCircleIcon';
import EmailIcon from './EmailIcon';
import MenuIcon from './MenuIcon';
import ViewIcon from './ViewIcon';
import EditIcon from './EditIcon';
import ImageIcon from './ImageIcon';
import CameraIcon from './CameraIcon';

Icon.Edit = EditIcon;
Icon.View = ViewIcon;
Icon.Menu = MenuIcon;
Icon.Email = EmailIcon;
Icon.CheckCircle = CheckCircleIcon;
Icon.Ban = BanIcon;
Icon.Plus = PlusIcon;
Icon.CreditCard = CreditCardIcon;
Icon.Barcode = BarcodeIcon;
Icon.TaskList = TaskListIcon;
Icon.WithText = IconWithText;
Icon.Clear = ClearIcon;
Icon.Stop = StopIcon;
Icon.Search = SearchIcon;
Icon.Clock = ClockIcon;
Icon.Red = RedIcon;
Icon.Yellow = YellowIcon;
Icon.Primary = PrimaryIcon;
Icon.Green = GreenIcon;
Icon.Image = ImageIcon;
Icon.Camera = CameraIcon;

export default Icon;
