import Navbar from "../containers/Navbar";
import Page from "../containers/Page";
import SearchBar from "../functional/SearchBar";
import styled from "styled-components/native";
import Center from "../presentational/Center";
import Text from "../presentational/Text";
import { remToDp } from "../../utilities/responsive";
import List from "../functional/List";
import Project from "../functional/Project";
import Gap from "../presentational/Gap";
import Message from "../presentational/Message";
import { QUALIFICATION_ERROR_MESSAGE } from "../../utilities/variables";
import Modal from "../functional/Modal";
import {
  useChosenProject,
  useProjectSearchBar,
  useProjectSearchPage,
} from "../../hooks/project";
import { useTaskSearchBar } from "../../hooks/task";

const Container = styled.View`
  margin-top: 12px;
`;

const SText = styled(Text)`
  font-size: ${remToDp(2)}px;
`;

const SGap = styled(Gap)`
  padding: 4px;
`;

function ProjectSearchPage(props) {
  const chosenProject = useChosenProject();
  const projectSearchBarProps = useProjectSearchBar();
  const taskSearchBarProps = useTaskSearchBar();
  const { canSearchProjects, showTaskSearchBar, equipmentSearch } =
    useProjectSearchPage();

  return (
    <Page.NonTask Navbar={Navbar.Task} {...props}>
      <Container>
        {canSearchProjects ? (
          <>
            <SearchBar {...projectSearchBarProps} />

            <SGap />

            {chosenProject ? (
              <>
                <Project {...chosenProject} />
                <SGap />
                {showTaskSearchBar && <SearchBar {...taskSearchBarProps} />}
                <List.ProjectTask />

                {equipmentSearch && (
                  <>
                    <Modal.BoatDetails />
                    <Modal.AddProjectImages />
                    <Modal.ProjectTaskInformation />
                  </>
                )}
              </>
            ) : (
              <>
                <Center>
                  <SText>
                    To find a <Text.Emphasized>Task</Text.Emphasized>, select a{" "}
                    <Text.Emphasized>Project</Text.Emphasized> below
                  </SText>
                </Center>

                <List.Project />
              </>
            )}

            <Modal.SearchTasksServerSide />
          </>
        ) : (
          <Message.Warning>{QUALIFICATION_ERROR_MESSAGE}</Message.Warning>
        )}
      </Container>
    </Page.NonTask>
  );
}

export default ProjectSearchPage;
