import { thunk } from "easy-peasy";
import {
  addNonroutineTaskApiCall,
  addServicePackageApiCall,
  addServiceProfileApiCall,
  createNonroutineTaskPdfApiCall,
  getServicePackagesApiCall,
  getTreeChildOptionsApiCall,
  getTreeRootOptionsApiCall,
  getTreeServiceProfileOptionsApiCall,
  uploadImageToNonRoutineTask,
} from "../../api/nonroutine";
import { OPERATION_ADD_NONROUTINE_TASK } from "../../utilities/variables";
import { handleAcumaticaError } from "../../utilities/error";
import { openAndDownloadBase64FileOnWeb } from "../../utilities/document";
import { Platform } from "react-native";

const thunks = {
  addNonroutineTaskThunk: thunk(async (actions, payload, helpers) => {
    actions.setLoadingAction(true);

    const {
      task: { currentTask },
      nonRoutine: {
        nonroutineInput: { hours, laborItemID, ...nonroutineInput },
      },
      status: { hasWifi },
      employee: { scannedEmployee },
    } = helpers.getState();

    try {
      const possibleRequiredValues = {
        title: nonroutineInput.Title,
        defect: nonroutineInput.Defect,
        ataChapterID: nonroutineInput.ATAChapterID,
        ataSubChapterID: nonroutineInput.ATASubChapterID,
        zoneID: nonroutineInput.ZoneID,
        STSZone: nonroutineInput.STSZone,
        hours,
        laborItemID,
      };

      let isValid = true;
      if (
        scannedEmployee.requiredNonRoutineItems &&
        scannedEmployee.requiredNonRoutineItems.length
      ) {
        scannedEmployee.requiredNonRoutineItems.forEach((item) => {
          if (!possibleRequiredValues[item]) {
            isValid = false;
            actions.setAlertThunk({
              message: `${item} is required!`,
              type: "error",
            });
          }
        });
      }

      if (isValid) {
        const data = {
          ...nonroutineInput,
          ParentTaskRefID: currentTask.ProjectTaskIDInt.value,
          WorkOrder: currentTask.ProjectIDInt.value,
          Qty: hours,
          InventoryID: laborItemID,
          ProjectTaskID: currentTask.ProjectTaskID.value,
          WorkOrderID: currentTask.WorkOrderID.value,
          Customer: currentTask.Customer.value,
          JobCode: currentTask.JobCode.value,
          RaisedBy: scannedEmployee.AccountID,
          DateRaised: currentTask.Time.value,
          EquipModel: currentTask.EquipModel.value,
          EquipID: currentTask.EquipID.value,
          ServiceProfileCD: currentTask.ServiceProfileCD
            ? currentTask.ServiceProfileCD.value
            : "",
        };
        (scannedEmployee.specialNonRoutineTypes || []).forEach(
          (st) => !nonroutineInput[st.abr] && (data[st.abr] = "")
        );
        currentTask.ReleaseStatement &&
          currentTask.ReleaseStatement.value &&
          (data.ReleaseStatement = currentTask.ReleaseStatement.value);
        scannedEmployee.stsZone &&
          (data.STSZone =
            nonroutineInput.STSZone || currentTask.STSZone.value || "");
        currentTask.UsrZoneID &&
          currentTask.UsrZoneID.value &&
          (data.UsrZoneID = currentTask.UsrZoneID.value);

        if (hasWifi) {
          const nonRoutineTask = await addNonroutineTaskApiCall(data);

          if (
            nonroutineInput.imageUrls &&
            nonroutineInput.imageUrls.length > 0
          ) {
            const requests = nonroutineInput.imageUrls.map((image) =>
              uploadImageToNonRoutineTask({
                id: nonRoutineTask.id,
                imageUrl: image.uri,
              })
            );

            await Promise.all(requests);
          }

          const taskId =
            nonRoutineTask && nonRoutineTask.NonRoutineTaskCD
              ? nonRoutineTask.NonRoutineTaskCD.value
              : "";

          actions.setAlertThunk({
            message: `Non Routine ${taskId} Created Successfully`,
            type: "success",
          });

          if (currentTask.printNonRoutineTaskCard) {
            const base64PdfString = await createNonroutineTaskPdfApiCall({
              ...data,
              Station:
                currentTask.Station && currentTask.Station.value
                  ? currentTask.Station.value
                  : "",
              RaisedBy: scannedEmployee.LastName,
              stsZone: scannedEmployee.stsZone,
              dateFormat: scannedEmployee.dateFormat,
              NonRoutineTaskCD: taskId,
            });

            if (Platform.OS === "web") {
              openAndDownloadBase64FileOnWeb(base64PdfString);
            } else {
              actions.setFileBase64Action(base64PdfString);
              actions.showFileModalAction(true);
            }
          }

          scannedEmployee.doubleInspectionConfirmationWindow &&
            actions.setShowDuplicateInspectionConfirmationModalAction(false);
        } else {
          actions.addPendingOperationAction({
            ...data,
            operationName: OPERATION_ADD_NONROUTINE_TASK,
            operationLabel: `Add Non-Routine Task For Task ${data.ProjectTaskID}`,
            operationDescription: `Save non-routine task for Task ${data.ProjectTaskID}.`,
          });
          actions.setAlertThunk({
            message: `Add non routine task for Task ${data.ProjectTaskID} is now pending. You will need to sync this app once you are online again.`,
            type: "success",
          });
        }

        actions.resetNonroutineInputAction();

        actions.setNonroutineInputAction({
          STSZone: currentTask.STSZone ? currentTask.STSZone.value : "",
        });

        actions.setShowDuplicateInspectionConfirmationModalAction(false);
      }
    } catch (err) {
      console.error(err);
      actions.setAlertThunk({
        message: handleAcumaticaError(err),
        type: "error",
      });
    }

    actions.setLoadingAction(false);
  }),
  addServiceTaskThunk: thunk(async (actions, payload, helpers) => {
    actions.setLoadingAction(true);

    try {
      const serviceType = helpers.getState().nonRoutine.serviceType;
      const ProjectID = helpers.getState().task.currentTask.WorkOrderID.value;
      const chosenService = helpers.getState().nonRoutine.chosenService;

      let data;
      if (serviceType === "Service Packages")
        data = await addServicePackageApiCall({
          ProjectID,
          ServicePackagesToAdd: chosenService.ServicePackageCD,
        });
      else if (serviceType === "Service Profiles")
        data = await addServiceProfileApiCall({
          ProjectID,
          ServiceProfilesToAdd:
            chosenService.ServiceProfileIDsvcprof_serviceProfileCD.value,
        });

      actions.setAlertThunk({
        message: `New task added for Project ID ${data.ProjectID.value}`,
        type: "success",
      });
      actions.setServiceTypeAction(serviceType);
    } catch (err) {
      console.error(err);
      actions.setAlertThunk({
        message: handleAcumaticaError(err),
        type: "error",
      });
    }

    actions.setLoadingAction(false);
  }),
  getServicePackageListThunk: thunk(async (actions, payload, helpers) => {
    actions.setLoadingAction(true);

    try {
      actions.setServiceTypeAction("Service Packages");
      actions.setServicePackageListAction(await getServicePackagesApiCall());
      actions.setChosenServiceAction(null);
    } catch (err) {
      console.error(err);
      actions.setAlertThunk({
        message: handleAcumaticaError(err),
        type: "error",
      });
    }

    actions.setLoadingAction(false);
  }),
  getRootServiceProfileOptionsThunk: thunk(
    async (actions, payload, helpers) => {
      actions.setLoadingAction(true);

      try {
        actions.setServiceTypeAction("Service Profiles");
        actions.setRootServiceProfileOptionsAction(
          await getTreeRootOptionsApiCall(
            helpers.getState().employee.scannedEmployee.SPRootComponentID
          )
        );
        actions.setServiceProfileListAction([]);
        actions.setChosenRootServiceProfileOptionAction({
          ComponentDescription: { value: "" },
          RMPTreeID: { value: "" },
        });
        actions.setChosenServiceAction(null);
      } catch (err) {
        console.error(err);
        actions.setAlertThunk({
          message: handleAcumaticaError(err),
          type: "error",
        });
      }

      actions.setLoadingAction(false);
    }
  ),
  chooseRootServiceProfileOptionThunk: thunk(
    async (actions, chosenOption, helpers) => {
      actions.setLoadingAction(true);

      try {
        const options = await getTreeChildOptionsApiCall(
          chosenOption.RMPTreeID.value
        );
        actions.setChildServiceProfileOptionsAction(options);
        actions.setChosenRootServiceProfileOptionAction(chosenOption);

        const childOptions = options.filter(
          (option) => option.ParentID.value === chosenOption.RMPTreeID.value
        );
        childOptions.length &&
          actions.setTreeChildServiceProfileDropdownsAction({
            index: 0,
            options: childOptions,
          });

        actions.setServiceProfileListAction([]);
        actions.setChosenServiceAction(null);
      } catch (err) {
        console.error(err);
        actions.setAlertThunk({
          message: handleAcumaticaError(err),
          type: "error",
        });
      }

      actions.setLoadingAction(false);
    }
  ),
  chooseChildServiceProfileOptionThunk: thunk(
    async (actions, { index, chosenOption }, helpers) => {
      actions.setLoadingAction(true);

      try {
        const filteredChildDropdownOptions = helpers
          .getState()
          .nonRoutine.childServiceProfileOptions.filter(
            (option) => option.ParentID.value === chosenOption.RMPTreeID.value
          );

        filteredChildDropdownOptions.length
          ? actions.setServiceProfileListAction([])
          : actions.setServiceProfileListAction(
              await getTreeServiceProfileOptionsApiCall(
                chosenOption.RMPTreeID.value
              )
            );

        actions.setTreeChildServiceProfileDropdownsAction({
          index: index + 1,
          options: filteredChildDropdownOptions,
        });
        actions.setChosenTreeChildServiceProfileOptionsAction({
          index,
          option: chosenOption,
        });
        actions.setChosenServiceAction(null);
      } catch (err) {
        console.error(err);
        actions.setAlertThunk({
          message: handleAcumaticaError(err),
          type: "error",
        });
      }

      actions.setLoadingAction(false);
    }
  ),
};

export default thunks;
