import { MaterialCommunityIcons } from '@expo/vector-icons';
import Icon from './Icon';

function CameraIcon(props) {
  return (
    <Icon Component={MaterialCommunityIcons} name='camera-outline' {...props} />
  );
}

export default CameraIcon;
