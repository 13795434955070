import { axios } from ".";

const meAxios = axios.create({
  baseURL: `${process.env.EXPO_PUBLIC_BACKEND_BASE_URL}/api/v1/me`,
});

const projectAxios = axios.create({
  baseURL: `${process.env.EXPO_PUBLIC_BACKEND_BASE_URL}/api/v1/project`,
});

const taskAxios = axios.create({
  baseURL: `${process.env.EXPO_PUBLIC_BACKEND_BASE_URL}/api/v1/task`,
});

function getEmployeeTasksApiCall(employeeId) {
  return meAxios({
    method: "get",
    url: `/${employeeId}`,
  });
}

function addTaskToEmployeeTasksApiCall({ taskId, ...data }) {
  return projectAxios({
    method: "put",
    url: `/task/${taskId}`,
    data,
  });
}

function removeTasksFromEmployeeTasksApiCall(project) {
  return projectAxios({
    method: "delete",
    url: `/${project.EmployeeID.value}`,
    data: project,
  });
}

function addInspectionRejectionApiCall({ ProjectTaskID, ...data }) {
  return projectAxios({
    method: "put",
    url: `/task/inspectionReject/${ProjectTaskID}`,
    data,
  });
}

async function toggleBillableTaskApiCall(data) {
  const response = await taskAxios({
    method: "put",
    url: "/billable/toggle",
    data,
  });
  return response.data;
}

export {
  meAxios,
  projectAxios,
  taskAxios,
  getEmployeeTasksApiCall,
  addTaskToEmployeeTasksApiCall,
  removeTasksFromEmployeeTasksApiCall,
  addInspectionRejectionApiCall,
  toggleBillableTaskApiCall,
};
