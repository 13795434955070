const defaultState = {
  topNavbarHeight: 0,
  showSidebarAsDrawer: false,
  openSidebarDrawer: false,
  allowPortrait: false,
};

const state = {
  layout: defaultState,
};

export { state as default, defaultState };
