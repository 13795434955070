import React, { useState } from "react";
import Input from "../Input/Input";
import Modal from "../Modal";
import { Pressable } from "react-native";
import Calendar from "../Calendar";

function DatePickerWeb({ value, onChange, ...props }) {
  const [showCalendar, setShowCalendar] = useState(false);

  return (
    <>
      <Pressable onPress={() => setShowCalendar(true)}>
        <Input value={value} {...props} />
      </Pressable>

      <Modal.Calendar
        visible={showCalendar}
        onRequestClose={() => setShowCalendar(false)}
      >
        <Calendar
          onDayPress={(day) => {
            onChange(day.dateString);
            setShowCalendar(false);
          }}
          value={value}
          {...props}
        />
      </Modal.Calendar>
    </>
  );
}

export default DatePickerWeb;
