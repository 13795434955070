import { useNavigation } from "@react-navigation/native";
import Icon from "../../presentational/Icon";
import Navbar from "../../presentational/Navbar";
import { useStoreActions, useStoreState } from "easy-peasy";
import {
  PAGE_PROJECT_SEARCH,
  QUALIFICATION_ERROR_MESSAGE,
} from "../../../utilities/variables";

function GoToSearchTasksPageIcon(props) {
  const {
    status: { hasWifi },
    employee: { usingCentrik, qualifications, scannedEmployee },
  } = useStoreState((state) => state);

  const { resetProjectStateAction, setAlertThunk, resetBoatStateAction } =
    useStoreActions((actions) => actions);

  const navigation = useNavigation();

  return (
    <Navbar.Item
      icon={<Icon.Search color="#2c6892" />}
      text={
        hasWifi
          ? scannedEmployee.customLabels.taskList.Search
          : `Can Only ${scannedEmployee.customLabels.taskList.Search} Online`
      }
      onPress={() => {
        if (hasWifi) {
          if (!usingCentrik || qualifications.mechanicQualified) {
            if (scannedEmployee.equipmentSearch) resetBoatStateAction();
            else resetProjectStateAction();
            navigation.navigate(PAGE_PROJECT_SEARCH);
          } else {
            setAlertThunk({
              type: "warning",
              message: QUALIFICATION_ERROR_MESSAGE,
            });
          }
        }
      }}
      {...props}
    />
  );
}

export default GoToSearchTasksPageIcon;
