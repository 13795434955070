import Modal from "../../presentational/Modal";
import { useStoreActions, useStoreState } from "easy-peasy";

function useOnClose() {
  const { showScanBadgeBarcodeModalAction } = useStoreActions(
    (actions) => actions
  );

  return () => showScanBadgeBarcodeModalAction(false);
}

function useOnScanBarcode() {
  const { loginEmployeeThunk, showScanBadgeBarcodeModalAction } =
    useStoreActions((actions) => actions);

  return ({ data }) => {
    loginEmployeeThunk(data);
    showScanBadgeBarcodeModalAction(false);
  };
}

function ScanBadgeBarcodeModal(props) {
  const { showScanBadgeBarcodeModal } = useStoreState(
    (state) => state.employee
  );

  const onClose = useOnClose();

  const onScanBarcode = useOnScanBarcode();

  return (
    <Modal.Camera
      visible={showScanBadgeBarcodeModal}
      onRequestClose={onClose}
      header="Scan Badge Barcode"
      onBarCodeScanned={showScanBadgeBarcodeModal ? onScanBarcode : undefined}
      {...props}
    />
  );
}

export default ScanBadgeBarcodeModal;
