import styled from "styled-components/native";
import Row from "../Row";
import Col from "../Col";
import Text from "../Text";
import Quantity from "../Quantity";
import Button from "../Button";
import { View } from "react-native";

const Container = styled(Row)`
  border-bottom-width: 1px;
`;
Container.Text = styled(Text)`
  margin-bottom: 12px;
`;

const SubstitutePartsLabel = styled(Text)`
  color: #808080;
`;
const SubstitutePartsButton = styled(Button.SearchSubstitutePart)`
  margin-top: 6px;
`;

function StockItemItem({
  CompanyQty,
  CustomerQty,
  Description,
  BaseUnit,
  InventoryCD,
  TotalQty,
  CompanyAbrv,
  unserviceableParts,
  SimpleItemRequest,
  Customer,
  onClickAddPartRequestButton,
  onClickAddUnserviceablePartRequestButton,
  ShowTotalQty,
  substituteParts,
  SubtituteItemCD,
  ...props
}) {
  return (
    <Container {...props}>
      <Col xs={12} md={6}>
        <View>
          <Container.Text>
            <Text.Success>{InventoryCD}</Text.Success> -{" "}
            <Text.Primary>
              {Description} ({BaseUnit})
            </Text.Primary>
          </Container.Text>

          <Container.Text>
            On Hand:{" "}
            {SimpleItemRequest ? (
              <Quantity quantity={CompanyQty} />
            ) : (
              <>
                {CompanyAbrv} (<Quantity quantity={CompanyQty} />){"\n"}
                {Customer} (<Quantity quantity={CustomerQty} />){"\n"}
                {ShowTotalQty && (
                  <>
                    Total Available: (<Quantity quantity={TotalQty} />)
                  </>
                )}
              </>
            )}
          </Container.Text>

          {substituteParts &&
            SubtituteItemCD &&
            typeof SubtituteItemCD === "string" &&
            SubtituteItemCD !== "NoSub" && (
              <View>
                <SubstitutePartsLabel>Substitute Parts:</SubstitutePartsLabel>
                {SubtituteItemCD.split(",").map((item) => (
                  <SubstitutePartsButton value={item}>
                    {item}
                  </SubstitutePartsButton>
                ))}
              </View>
            )}
        </View>
      </Col>

      <Col xs={12} md={3}>
        <Button.ShowAddPartRequestModal onPress={onClickAddPartRequestButton} />
      </Col>

      <Col xs={12} md={3}>
        {unserviceableParts && (
          <Button.ShowAddUnserviceablePartRequestModal
            onPress={onClickAddUnserviceablePartRequestButton}
          />
        )}
      </Col>
    </Container>
  );
}

export default StockItemItem;
