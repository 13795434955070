import styled from "styled-components/native";
import { remToDp } from "../../../utilities/responsive";
import Row from "../Row";

const SRow = styled(Row)`
  padding: ${remToDp(1)}px;
  justify-content: space-evenly;
  width: 100%;
  border-top-width: 1px;
  border-color: #dee2e6;
`;

function ModalFooter({ children, ...props }) {
  return (
    <SRow onRequestClose {...props}>
      {children}
    </SRow>
  );
}

export default ModalFooter;
