import styled from "styled-components/native";
import { remToDp } from "../../../utilities/responsive";
import TextPrimary from "./TextPrimary";

const EmphasizedText = styled(TextPrimary)`
  font-size: ${remToDp(2)}px;
  color: #014daf;
`;

export default EmphasizedText;
