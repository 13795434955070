import { thunk } from "easy-peasy";
import {
  getProjectTasksApiCall,
  searchProjectsApiCall,
  searchTasksServerSideApiCall,
  uploadProjectImagesApiCall,
} from "../../api/project";
import { handleAcumaticaError } from "../../utilities/error";
import { searchBoatsApiCall } from "../../api/boat";

const thunks = {
  searchProjectsThunk: thunk(async (actions, payload, helpers) => {
    actions.setLoadingAction(true);

    const { project, employee } = helpers.getState();

    try {
      const searchApiCall = employee.scannedEmployee.equipmentSearch
        ? searchBoatsApiCall
        : searchProjectsApiCall;
      actions.setProjectListAction(await searchApiCall(project.searchInput));

      actions.setChosenProjectAction(null);
      actions.setProjectSearchInputAction("");
    } catch (err) {
      console.error(err);
      actions.setAlertThunk({
        type: "error",
        message: handleAcumaticaError(err),
      });
    }

    actions.setLoadingAction(false);
  }),
  getProjectTasksThunk: thunk(async (actions, ProjectID, helpers) => {
    actions.setLoadingAction(true);

    try {
      const {
        employee: { scannedEmployee },
        project: { projectTaskServerSearchInput, chosenProject },
      } = helpers.getState();

      const searchApiCall = scannedEmployee.searchTasksServerSide
        ? searchTasksServerSideApiCall({
            SearchProject: chosenProject.ProjectID,
            SearchValue: projectTaskServerSearchInput,
          })
        : getProjectTasksApiCall(ProjectID);
      const projectTasks = await searchApiCall;
      if (projectTasks.length) {
        actions.showProjectTaskServerSearchModalAction(false);
        actions.setProjectTaskServerSearchInputAction("");
      }
      actions.setProjectTaskListAction(projectTasks);
    } catch (err) {
      console.error(err);
      actions.setAlertThunk({
        type: "error",
        message: handleAcumaticaError(err),
      });
    }

    actions.setLoadingAction(false);
  }),
  uploadProjectImagesThunk: thunk(async (actions, payload, helpers) => {
    actions.setLoadingAction(true);

    try {
      const imageList = helpers.getState().project.imageUrlList;

      await Promise.all(
        imageList.map((image) =>
          uploadProjectImagesApiCall({
            projectId: helpers.getState().project.chosenProject.id,
            imageUrl: image.uri,
          })
        )
      );
      actions.setProjectImageUrlListAction([]);
    } catch (err) {
      console.error(err);
      actions.setAlertThunk({
        type: "error",
        message: handleAcumaticaError(err),
      });
    }

    actions.setLoadingAction(false);
  }),
};

export default thunks;
