import { useStoreActions, useStoreState } from "easy-peasy";
import Button from "../../presentational/Button";

function ForcedLogoutButton({ children, ...props }) {
  const { closeTasksOnForcedLogout } = useStoreState(
    (state) => state.employee.scannedEmployee
  );

  const {
    logoutEmployeeThunk,
    showForcedLogoutModalAction,
    setCorrectiveActionInputAction,
    stopAllTasksThunk,
  } = useStoreActions((actions) => actions);

  return (
    <Button
      onPress={() => {
        if (closeTasksOnForcedLogout) {
          setCorrectiveActionInputAction("");
          stopAllTasksThunk({
            process: "incompleteAll",
            signout: true,
          });
        } else {
          logoutEmployeeThunk();
        }
        showForcedLogoutModalAction(false);
      }}
      color="#f8f9fa"
      titleStyle={{ color: "#000000" }}
      {...props}
    >
      {children || "OK"}
    </Button>
  );
}

export default ForcedLogoutButton;
