const defaultState = {
  chapters: [],
  laborItems: [],
  priorityLevels: [],
  subChapters: [],
  zones: [],
  stsZones: [],
  nonroutineInput: {
    Title: "",
    Defect: "",
    ATAChapterID: "",
    ATASubChapterID: "",
    ZoneID: "",
    STSZone: "",
    None: true,
    EWIS: false,
    RII: false,
    CriticalTask: false,
    CDCCL: false,
    AirworthinessLimitationsItems: false,
    hours: "",
    laborItemID: "",
    imageUrls: [],
  },
  chapterSearchInput: "",
  subchapterSearchInput: "",
  zoneSearchInput: "",
  stsZoneSearchInput: "",
  laborItemSearchInput: "",
  showDuplicateInspectionConfirmationModal: false,
  serviceType: "",
  servicePackageList: [],
  rootServiceProfileOptions: [],
  chosenRootServiceProfileOption: {
    ComponentDescription: { value: "" },
    RMPTreeID: { value: "" },
  },
  childServiceProfileOptions: [],
  treeChildServiceProfileDropdowns: [],
  chosenTreeChildServiceProfileOptions: [],
  serviceProfileList: [],
  chosenService: null,
};

const state = {
  nonRoutine: defaultState,
};

export { state as default, defaultState };
