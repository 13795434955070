import { action } from "easy-peasy";
import { defaultState } from "./state";

const actions = {
  setFileBase64Action: action((state, base64) => {
    state.file.base64 = base64;
  }),
  showFileModalAction: action((state, show) => {
    state.file.showFileModal = show;
  }),
  resetFileStateAction: action((state) => {
    state.file = defaultState;
  }),
};

export default actions;
