import Modal from "./Modal";
import ModalBody from "./ModalBody";
import ModalHeader from "./ModalHeader";

function ModalCalendar({ children, ...props }) {
  return (
    <Modal {...props}>
      <ModalHeader onRequestClose={props.onRequestClose}>Pick Date</ModalHeader>
      <ModalBody>{children}</ModalBody>
    </Modal>
  );
}

export default ModalCalendar;
