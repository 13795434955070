import { Platform } from "react-native";
import DatePickerWeb from "./DatePickerWeb";
import DatePickerMobile from "./DatePickerMobile";

function DatePicker(props) {
  return Platform.OS === "web" ? (
    <DatePickerWeb {...props} />
  ) : (
    <DatePickerMobile {...props} />
  );
}

export default DatePicker;
