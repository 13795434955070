import { Linking, View } from "react-native";
import Row from "../presentational/Row";
import Col from "../presentational/Col";
import Calendar from "../presentational/Calendar";
import moment from "moment";
import Text from "../presentational/Text";
import Center from "../presentational/Center";
import styled from "styled-components/native";
import Icon from "../presentational/Icon";
import Button from "../presentational/Button";
import { useStoreState } from "easy-peasy";
import Gap from "../presentational/Gap";

const Container = styled.View`
  justify-content: space-between;
  flex: 1;
`;

const Section = styled(Text)`
  text-decoration-line: underline;
  font-weight: bold;
`;
Section.Subsections = styled.View`
  width: 100%;
`;
Section.Button = styled(Button)`
  margin: 4px;
`;

const CenterCol = styled(Col)`
  justify-content: center;
`;

function Sidebar(props) {
  const {
    weather: { current, location },
    panel: { list },
  } = useStoreState((state) => state);

  const mainSections = list
    .filter((item) => item.Section !== "Email" && item.Active)
    .reduce((resultSections, section) => {
      const lastElement =
        resultSections[resultSections.length ? resultSections.length - 1 : 0];

      if (!lastElement || lastElement.Section !== section.Section) {
        resultSections.push({
          Section: section.Section,
          subsections: [section],
        });
      } else if (lastElement.Section === section.Section) {
        lastElement.subsections.push(section);
      }

      return resultSections;
    }, []);
  const emailSection = list.find(
    (item) => item.Section === "Email" && item.Active
  );

  const colNum = 12 / mainSections.length;

  return (
    <Container {...props}>
      <Row>
        {mainSections.map((mainSection, index) => (
          <Col xs={colNum} sm={colNum} key={index}>
            <Center>
              <Section>{mainSection.Section}</Section>
              <Section.Subsections>
                {mainSection.subsections
                  .sort((section1, section2) => {
                    if (section1.Sort < section2.Sort) return -1;
                    else if (section1.Sort > section2.Sort) return 1;
                    else return 0;
                  })
                  .map((subsection, index) => (
                    <Section.Button
                      fullWidth
                      onPress={() => Linking.openURL(subsection.Link)}
                      color="#dc3545"
                      key={index}
                    >
                      {subsection.Label}
                    </Section.Button>
                  ))}
              </Section.Subsections>
            </Center>
          </Col>
        ))}
      </Row>

      <Row>
        <Col xs={9} sm={9}>
          <Calendar value={moment().format("L")} />
        </Col>

        <CenterCol xs={3} sm={3}>
          <Center>
            {emailSection && (
              <>
                <Icon.WithText
                  icon={<Icon.Email />}
                  text={emailSection.Section}
                  onPress={() => Linking.openURL(emailSection.Link)}
                />
                <Gap />
                <Gap />
              </>
            )}

            <Text>Temp {current.temp_f}°F</Text>
            <Gap />
            <Text>{moment(location.localTime).format("LT")}</Text>
          </Center>
        </CenterCol>
      </Row>
    </Container>
  );
}

export default Sidebar;
