import { FontAwesome } from "@expo/vector-icons";
import { remToDp } from "../../../utilities/responsive";
import Icon from "./Icon";

function CreditCardIcon(props) {
  return (
    <Icon
      Component={FontAwesome}
      name="credit-card-alt"
      size={remToDp(6)}
      color="#6c757d"
      {...props}
    />
  );
}

export default CreditCardIcon;
