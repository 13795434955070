import { useStoreActions } from "easy-peasy";
import Button from "../../presentational/Button";

function SelectEnvironmentButton({
  children = "Select another environment",
  ...props
}) {
  const { selectEnvironmentThunk } = useStoreActions((actions) => actions);
  return (
    <Button
      titleStyle={{ color: "#333" }}
      color="#E49A3A"
      onPress={() => selectEnvironmentThunk()}
      {...props}
    >
      {children}
    </Button>
  );
}

export default SelectEnvironmentButton;
