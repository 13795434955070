import { action } from "easy-peasy";
import { defaultState } from "./state";

const actions = {
  setProjectSearchInputAction: action((state, input) => {
    state.project.searchInput = input;
  }),
  setProjectListAction: action((state, list) => {
    state.project.projectList = list;
  }),
  setChosenProjectAction: action((state, project) => {
    state.project.chosenProject = project;
  }),
  setProjectTaskListAction: action((state, list) => {
    state.project.projectTaskList = list;
  }),
  resetProjectStateAction: action((state) => {
    state.project = defaultState;
  }),
  setProjectTaskServerSearchInputAction: action((state, input) => {
    state.project.projectTaskServerSearchInput = input;
  }),
  showProjectTaskServerSearchModalAction: action((state, show) => {
    state.project.showProjectTaskServerSearchModal = show;
  }),
  setProjectImageUrlListAction: action((state, list) => {
    state.project.imageUrlList = list;
  }),
  showAddProjectImagesModalAction: action((state, show) => {
    state.project.showAddProjectImagesModal = show;
  }),
  setChosenProjectTaskAction: action((state, task) => {
    state.project.chosenProjectTask = task;
  }),
};

export default actions;
