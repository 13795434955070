function dateToString(date) {
  return date.toISOString();
}

function calculateTimeDifferenceInHours(startDate, endDate) {
  // Calculate the time difference in milliseconds
  const timeDifferenceMs = endDate - startDate;

  // Convert milliseconds to minutes
  const timeDifferenceHours = timeDifferenceMs / (1000 * 60 * 60);

  return timeDifferenceHours.toFixed(2);
}

export { dateToString, calculateTimeDifferenceInHours };
