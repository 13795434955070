import Button from "../../presentational/Button";
import ClockInAlternativeButton from "./ClockInAlternativeButton";
import LoginButton from "./LoginButton";
import ForcedLogoutButton from "./ForcedLogoutButton";
import ScanFaceButton from "./ScanFaceButton";
import SelectEnvironmentButton from "./SelectEnvironmentButton";
import ShowClockInModalButton from "./ShowClockInModalButton";
import RejectInspectionButton from "./RejectInspectionButton";
import PrintPartRequestManifestButton from "./PrintPartRequestManifestButton";
import ShowAddPartRequestModalButton from "./ShowAddPartRequestModalButton";
import ShowAddUnserviceablePartRequestModalButton from "./ShowAddUnserviceablePartRequestModalButton";
import ShowAddStockItemRequestModalButton from "./ShowAddStockItemRequestModalButton";
import ShowAddUnserviceableStockItemModalButton from "./ShowAddUnserviceableStockItemModalButton";
import SyncAppButton from "./SyncAppButton";
import ShowSyncAppModalButton from "./ShowSyncAppModalButton";
import AddUnserviceablePartRequestButton from "./AddUnserviceablePartRequestButton";
import AddPartRequestButton from "./AddPartRequestButton";
import SyncFailureUnderstoodButton from "./SyncFailureUnderstoodButton";
import SyncFixConfirmedButton from "./SyncFixConfirmedButton";
import AddNonroutineTaskButton from "./AddNonroutineTaskButton";
import AddServiceTaskButton from "./AddServiceTaskButton";
import AddUnserviceableStockItemButton from "./AddUnserviceableStockItemButton";
import SearchSubstitutePartButton from "./SearchSubstitutePartButton";
import AddStockItemRequestButton from "./AddStockItemRequestButton";
import ToggleBillableTaskButton from "./ToggleBillableTaskButton";
import SearchTasksServerSideButton from "./SearchTasksServerSideButton";
import ShowClockOutModalButton from "./ShowClockOutModalButton";

Button.Login = LoginButton;
Button.ForcedLogout = ForcedLogoutButton;
Button.SelectEnvironment = SelectEnvironmentButton;

Button.ScanFace = ScanFaceButton;
Button.ShowClockInModal = ShowClockInModalButton;
Button.ClockInAlternative = ClockInAlternativeButton;
Button.ShowClockOutModal = ShowClockOutModalButton;

Button.RejectInspection = RejectInspectionButton;

Button.PrintPartRequestManifest = PrintPartRequestManifestButton;
Button.AddPartRequest = AddPartRequestButton;
Button.ShowAddPartRequestModal = ShowAddPartRequestModalButton;
Button.ShowAddStockItemRequestModal = ShowAddStockItemRequestModalButton;
Button.ShowAddUnserviceablePartRequestModal =
  ShowAddUnserviceablePartRequestModalButton;
Button.ShowAddUnserviceableStockItemModal =
  ShowAddUnserviceableStockItemModalButton;
Button.AddUnserviceablePartRequest = AddUnserviceablePartRequestButton;
Button.AddUnserviceableStockItem = AddUnserviceableStockItemButton;
Button.SearchSubstitutePart = SearchSubstitutePartButton;
Button.AddStockItemRequest = AddStockItemRequestButton;

Button.ShowSyncAppModal = ShowSyncAppModalButton;
Button.SyncApp = SyncAppButton;
Button.SyncFailureUnderstood = SyncFailureUnderstoodButton;
Button.SyncFixConfirmed = SyncFixConfirmedButton;

Button.AddNonroutineTask = AddNonroutineTaskButton;
Button.AddServiceTask = AddServiceTaskButton;

Button.ToggleBillableTask = ToggleBillableTaskButton;

Button.SearchTasksServerSide = SearchTasksServerSideButton;

export default Button;
