import List from "./List";
import styled from "styled-components/native";
import { remToDp } from "../../../utilities/responsive";
import Text from "../../presentational/Text";
import Icon from "../../presentational/Icon";
import { Button } from "@cfbs/cfbsstrap-native";
import { useSubtypeList } from "../../../hooks/boat";

const AttributeValue = styled(Text)`
  font-size: ${remToDp(1.25)}px;
`;
AttributeValue.New = styled(Text.Success)`
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 8px;
  font-size: ${remToDp(1)}px;
`;

const AttributeDescription = styled(AttributeValue)`
  text-align: center;
`;

function SubtypeList({ children, ...props }) {
  const { tableRows, onChooseSubtype, ...listProps } = useSubtypeList();

  return (
    <List
      {...listProps}
      tableRows={tableRows.map((subtype) => [
        subtype.SubTypeID,
        <AttributeDescription>{subtype.AttributeDesc}</AttributeDescription>,
        <>
          <AttributeValue>{subtype.Value}</AttributeValue>
          {subtype.NewValue && (
            <AttributeValue.New>{subtype.NewValue}</AttributeValue.New>
          )}
        </>,
        <Button type="transparent" onPress={onChooseSubtype(subtype)}>
          <Icon.Edit {...props} />
        </Button>,
      ])}
      {...props}
    />
  );
}

export default SubtypeList;
