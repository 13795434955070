import React from "react";
import Page from "../containers/Page";
import Button from "../containers/Button";
import Modal from "../containers/Modal";
import Center from "../presentational/Center";
import styled from "styled-components/native";
import { useStoreState } from "easy-peasy";
import List from "../containers/List";
import { useTaskSearchBar } from "../../hooks/task";
import SearchBar from "../functional/SearchBar";

const SCenter = styled(Center)`
  height: 100%;
`;

const Container = styled.View`
  margin-top: 12px;
`;

function HomePage(props) {
  const {
    KronosClockIn,
    facialRecogition,
    SecondaryFacialRecogRequired,
    BiometricAlternative,
    SkipFacialRecogition,
  } = useStoreState((state) => state.employee.scannedEmployee);

  const taskSearchBarProps = useTaskSearchBar();

  return (
    <Page.NonTask {...props}>
      <Container>
        <SearchBar {...taskSearchBarProps} />
        <List.EmployeeTask />
      </Container>

      <Modal.FaceScan />
      <Modal.FaceScanAlternative />
      <Modal.Message />
      <Modal.ForcedLoggedOut />
      <Modal.ScanTaskBarcode />
    </Page.NonTask>
  );
}

export default HomePage;
