import { projectAxios } from "./task";
import { Platform } from "react-native";
import { getAuthToken } from "../utilities/api";
import * as FileSystem from "expo-file-system";

async function searchProjectsApiCall(equipmentId) {
  const response = await projectAxios({
    method: "get",
    url: `/find/${equipmentId}`,
  });
  return response.data;
}

async function getProjectTasksApiCall(ProjectID) {
  const response = await projectAxios({
    method: "get",
    url: `/${ProjectID}`,
  });
  return response.data;
}

async function searchTasksServerSideApiCall({ SearchProject, SearchValue }) {
  const response = await projectAxios({
    method: "get",
    url: `/task/serversearch?SearchProject=${SearchProject}&SearchValue=${SearchValue}`,
  });
  return response.data;
}

async function uploadProjectImagesApiCall({ projectId, imageUrl }) {
  const url = `${process.env.EXPO_PUBLIC_BACKEND_BASE_URL}/api/v1/project/image/upload`;

  async function uploadToWeb() {
    const rest = await fetch(imageUrl);
    const blob = await rest.blob();
    const formData = new FormData();
    formData.append("projectImage", blob);
    formData.append("id", projectId);

    return projectAxios({
      method: "put",
      url,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  let response;
  if (Platform.OS === "web") {
    response = await uploadToWeb();
  } else {
    response = FileSystem.uploadAsync(url, imageUrl, {
      fieldName: "projectImage",
      httpMethod: "PUT",
      uploadType: FileSystem.FileSystemUploadType.MULTIPART,
      headers: { Authorization: `Bearer ${await getAuthToken()}` },
      parameters: { id: projectId },
    });
  }

  return response.data;
}

export {
  searchProjectsApiCall,
  getProjectTasksApiCall,
  searchTasksServerSideApiCall,
  uploadProjectImagesApiCall,
};
