import { useStoreActions, useStoreState } from "easy-peasy";
import StockItem from "../../presentational/StockItem";

function StockItemSearchResultsList(props) {
  const {
    part: { stockItems },
    employee: { scannedEmployee },
    task: { currentTask },
  } = useStoreState((state) => state);

  const { setChosenStockItemAction, setUnserviceablePartInputAction } =
    useStoreActions((actions) => actions);

  return (
    <StockItem.List
      list={stockItems.map((part) => ({
        ...part,
        CompanyAbrv: scannedEmployee.CompanyAbrv,
        Customer: currentTask.Customer.value,
        unserviceableParts: currentTask.unserviceableParts,
        onClickAddPartRequestButton: () => setChosenStockItemAction(part),
        onClickAddUnserviceablePartRequestButton: () => {
          setUnserviceablePartInputAction({ Description: part.Description });
          setChosenStockItemAction(part);
        },
        ShowTotalQty: currentTask.ShowTotalQty
          ? currentTask.ShowTotalQty.value
          : false,
        substituteParts: currentTask.substituteParts,
      }))}
      {...props}
    />
  );
}

export default StockItemSearchResultsList;
