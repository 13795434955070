import Text from "./Text";
import styled from "styled-components/native";
import TextWarning from "./TextWarning";
import TextError from "./TextError";
import TextSuccess from "./TextSuccess";

const InspectionText = styled(TextWarning)`
  color: #ffc107;
`;

function TextTaskStatus({ children, status, ...props }) {
  if (status === "Active") {
    return <TextSuccess {...props}>{children}</TextSuccess>;
  } else if (status === "Inspection") {
    return <InspectionText {...props}>{children}</InspectionText>;
  } else if (status === "Reject") {
    return <TextError {...props}>{children}</TextError>;
  } else {
    return <Text {...props}>{children}</Text>;
  }
}

export default TextTaskStatus;
