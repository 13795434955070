import Icon from "../Icon";
import Navbar from "./Navbar";

function TaskNavbar(props) {
  const buttons = [<Icon.GoToEmployeeTasksPage />, <Icon.SignOut />].map(
    (button) => ({ show: true, children: button })
  );

  return <Navbar buttons={buttons} {...props} />;
}

export default TaskNavbar;
