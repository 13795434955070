import Modal from "./Modal";
import { Camera, CameraType } from "expo-camera";
import styled from "styled-components/native";
import { useScreenDimensions } from "../../../utilities/responsive";
import ModalBody from "./ModalBody";
import ModalHeader from "./ModalHeader";
import Message from "../Message";
import { useState } from "react";

const SModalBody = styled(ModalBody)`
  height: ${(props) => props.height}px;
`;

const SCamera = styled(Camera)`
  flex: 1;
  margin-bottom: 10px;
`;

function ModalCamera({ header, style, children, ...props }) {
  const { vhToDp } = useScreenDimensions();
  const [cameraIsReady, setCameraIsReady] = useState(false);

  return (
    <Modal style={style} {...props}>
      <ModalHeader onRequestClose={props.onRequestClose}>{header}</ModalHeader>

      <SModalBody height={vhToDp(50)}>
        {!cameraIsReady && <Message.AllowCameraUse />}
        {children || (
          <SCamera
            type={CameraType.back}
            onCameraReady={async () => {
              const { granted } = await Camera.getCameraPermissionsAsync();
              setCameraIsReady(granted);
            }}
            {...props}
          />
        )}
      </SModalBody>
    </Modal>
  );
}

export default ModalCamera;
